import {Component, OnInit} from '@angular/core';
import {RiskOptionService} from '../shared/risk/risk-option.service';
import {RoutingService} from '../shared/services/routing.service';
import {MSTIService} from '../shared/services/msti.service';
import {UrlService} from '../shared/services/url.service';
import {LoaderService} from '../shared/screen-loader/loader.service';
import {MstiErrorService} from '../shared/msti-error-handler/msti-error.service';
import {BrokerDetailsService} from '../shared/broker-common/broker-details.service';
import {AvailableSectionsModel} from './models/available-sections.model';
import {BrokerUrlService} from '../shared/services/broker-url.service';
import {NeedHelpPageTags} from '../footer/need-help/need-help-page-tags';
import {NeedHelpService} from '../footer/need-help/services/need-help.service';


@Component({
  selector: 'app-select-cover',
  templateUrl: './select-cover.component.html'
})
export class SelectCoverComponent implements OnInit {
  showError: boolean;
  enableGetAQuoteButton = false;

  constructor(private mstiService: MSTIService, private routingService: RoutingService,
              private riskOptionService: RiskOptionService, private urlService: UrlService,
              private loaderService: LoaderService, private brokerUrlService: BrokerUrlService,
              private mstiErrorService: MstiErrorService, private userService: BrokerDetailsService,
              private needHelpService: NeedHelpService) {
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_MARKETING);
  }

  ngOnInit() {
    this.initialiseApp();

  }

  onRiskSelected() {
    console.log('risk selected');
    this.showError = this.riskOptionService.isNoRiskSelected();
    this.enableGetAQuoteButton = !this.showError;
  }

  getQuote() {

    this.goToPersonalContact();
  }


  private goToPersonalContact() {
    if (this.riskOptionService.isSingleRisk()) {
      this.riskOptionService.selectOnlyAvailableRiskOption();
      this.routingService.route('/app-personal'); // app-disclosure
    } else {
      this.showError = this.riskOptionService.isNoRiskSelected();
      if (!this.showError) {
        this.routingService.route('/app-personal'); // app-personal
      }
    }
  }

  public initialiseApp(): void {
  }


  private getAvailableRisks() {
    this.loaderService.show();
    this.mstiService.getWithAuth(this.urlService.getAvailableSectionsUrl()).subscribe(response => {
      this.setRisks(response);
      // this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_MARKETING);
      // this.routingService.route('/app-select-cover');
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    });
    this.loaderService.hide();
  }

  private setRisks(result) {
    const risks: string[] = [];
    const availableSectionsModel: AvailableSectionsModel[] = Object.assign(new Array<AvailableSectionsModel>(), result);
    for (let i = 0; i < availableSectionsModel.length; i++) {
      risks.push(availableSectionsModel[i].sectionName);
    }
    this.riskOptionService.setRisks(risks);
    this.riskOptionService.setMaxNumberOfRisks(availableSectionsModel[0].maximumNumberOfRisksAllowed);
  }


}
