import {Injectable, Injector} from '@angular/core';
import {BaseRiskService} from '../../base/service/base-risk.service';
import {SaveClientResponseModel} from '../../../../personal/model/SaveClientModels/save-client-response.model';
import {BaseCifRiskModel} from '../../base/model/base-cif-risk.model';
import {CifContentsModel} from '../model/cif/cif-contents.model';
import {LookupService} from '../../../../shared/lookup/lookup.service';
import {ContentsDetailsModel} from '../model/contents-details.model';
import {AddressTypeModel} from '../../../../shared/msti-agm/model/address-type.model';
import {LookupFilteredDropDownModel} from '../../../../shared/lookup/lookup.filtered.drop.down.model';
import {AddressLookupModel} from '../../../../shared/lookup/address-lookup.model';
import {ContentsStructureDetailsModel} from '../model/contents-structure-details.model';
import {ContentsUrlService} from './contents-url.service';
import {CifClientInfoModel} from '../../../model/cif/cif-client-info.model';
import {CifQuoteModel} from '../../../model/cif/cif-quote.model';
import {ContentsModel} from '../model/contents.model';
import {Logger} from '../../../../shared/utilities/logger';
import {Level} from '../../../../shared/utilities/logger-level';
import {merge, Subject} from 'rxjs';
import {PersonalBelongingsService} from '../../personal-belongings/service/personal-belongings.service';
import {CifSecurityFeatureModel} from '../../vehicle/model/cif/cif-security-feature.model';
import {PifRiskAddressModel} from '../../../model/pif/pif-risk-address.model';

@Injectable()
export class ContentsService extends BaseRiskService {

  public static readonly VALUE_ADD_NEW_ADDRESS = 'Add new address';
  private static readonly TAB_INDEX_CONTENT_DETAILS = 0;
  private static readonly TAB_INDEX_STRUCTURE_DETAILS = 1;
  private static readonly TAB_INDEX_PERSONAL_BELONGINGS = 2;
  private contentsAddressMap: Map<string, AddressTypeModel>;

  constructor(
    injector: Injector,
    private lookupService: LookupService,
    private contentUrlService: ContentsUrlService,
    private personalBelongingsService: PersonalBelongingsService
  ) {
    super(injector);
    this.contentsAddressMap = new Map<string, AddressTypeModel>();
  }

  doDestroy(): void {
    this.contentsAddressMap = new Map<string, AddressTypeModel>();
  }

  instantiateRiskModel(client: SaveClientResponseModel): BaseCifRiskModel {
    return new CifContentsModel();
  }

  getAddRiskToQuoteURL(quoteId): string {
    return this.contentUrlService.getSaveContentURL(quoteId);
  }

  getDeleteRiskURL(quoteId: string, riskId: string): string {
    return this.contentUrlService.getDeleteContentURL(quoteId, riskId);
  }


  private initContentsDetailsModel(cardId: string) {
    if (!this.cardModels[cardId].contentsDetailsModel) {
      this.cardModels[cardId].contentsDetailsModel = new ContentsDetailsModel();
    }
  }

  private initStructuresDetailsModel(cardId: string) {
    if (!this.cardModels[cardId].structureDetailsModel) {
      this.cardModels[cardId].structureDetailsModel = new ContentsStructureDetailsModel();
    }
  }

  public getContentsDetailsModel(cardId: string): ContentsDetailsModel {
    if (!this.cardModels[cardId]) {
      this.initCardModel(cardId);
    }

    if (!this.cardModels[cardId].contentsDetailsModel) {
      this.initContentsDetailsModel(cardId);
    }

    return this.cardModels[cardId].contentsDetailsModel;
  }

  public getRiskID(cardId: string): string {
    if (this.cardModels[cardId]) {

      if (this.cardModels[cardId].cifRiskModel.riskResourceRef) {
        return this.cardModels[cardId].cifRiskModel.riskResourceRef;
      } else {
        return this.cardModels[cardId].cifRiskModel.riskId;
      }

      return this.cardModels[cardId].cifRiskModel.riskResourceRef;
    } else {
      return null;
    }
  }

  public getStructureDetailsModel(cardId: string): ContentsStructureDetailsModel {
    if (!this.cardModels[cardId]) {
      this.initCardModel(cardId);
    }

    if (!this.cardModels[cardId].structureDetailsModel) {
      this.initStructuresDetailsModel(cardId);
    }

    return this.cardModels[cardId].structureDetailsModel;
  }

  public addAddress(cardId: string, address: AddressTypeModel) {
    this.contentsAddressMap.set(cardId, address);
  }

  public removeAddress(cardId: string) {
    this.contentsAddressMap.delete(cardId);
  }

  public getAddresses(): AddressTypeModel[] {
    return Array.from(this.contentsAddressMap.values());
  }

  public getOtherAddresses(cardId: string): AddressTypeModel[] {

    const cardIds = Array.from(this.contentsAddressMap.keys()).filter(key => {
      return key !== cardId;
    });

    const otherAddresses = cardIds.map(otherCardId => {
      return this.contentsAddressMap.get(otherCardId);
    });

    return otherAddresses;
  }

  private doSameAsPhysicalCardsHavePersonalBelongings(cardId: string): boolean {
    let doSameAsPhysicalCardsHavePersonalBelongings = false;

    this.getOtherSameAsPhyisicalCardIds(cardId).forEach(otherCardId => {
      doSameAsPhysicalCardsHavePersonalBelongings = doSameAsPhysicalCardsHavePersonalBelongings ||
        this.personalBelongingsService.doesPersonalBelongingsModelHaveData(otherCardId);
    });

    return doSameAsPhysicalCardsHavePersonalBelongings;
  }

  private doSameAddressCardsHavePersonalBelongings(cardId: string): boolean {
    let doSameAddressCardsHavePersonalBelongings = false;

    this.getSameAddressCardIds(cardId).forEach(otherCardId => {
      doSameAddressCardsHavePersonalBelongings = doSameAddressCardsHavePersonalBelongings ||
        this.personalBelongingsService.doesPersonalBelongingsModelHaveData(otherCardId);
    });

    return doSameAddressCardsHavePersonalBelongings;
  }

  private getSameAddressCardIds(cardId: string): string[] {

    const cardIds = Array.from(this.contentsAddressMap.keys()).filter(key => {
      return key !== cardId;
    });

    const otherAddresses = cardIds.map(otherCardId => {
      return {'cardId': otherCardId, 'address': this.contentsAddressMap.get(otherCardId)};
    });

    const sameAddressCardIds = otherAddresses.filter(value => {
      return value.address === this.contentsAddressMap.get(cardId);
    }).map(value => {
      return value.cardId;
    });

    return sameAddressCardIds;
  }

  public getAddressLookups(): LookupFilteredDropDownModel {
    const addressLookups = [];
    const dummyAddressValue = new AddressTypeModel('DUMMY_VALUE');
    dummyAddressValue.formattedAddressLine = ContentsService.VALUE_ADD_NEW_ADDRESS;
    const dummyAddressLookupValue = new AddressLookupModel('0', dummyAddressValue);
    addressLookups.push(dummyAddressLookupValue);

    Array.from(this.contentsAddressMap.entries()).forEach(entry => {
      const addressLookupModel = new AddressLookupModel(entry[0], entry[1]);
      addressLookups.push(addressLookupModel);
    });

    return new LookupFilteredDropDownModel(addressLookups);
  }

  async getAreaTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_CONTENTS_AREA_TYPE);
  }

  async getNeighbourSetups() {
    return await this.lookupService.getLookup(LookupService.GROUP_CONTENTS_NEIGHBOUR);
  }

  async getOccupancies() {
    return await this.lookupService.getLookup(LookupService.GROUP_CONTENTS_OCCUPANCY);
  }

  async getOccupantTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_CONTENTS_OCCUPIED_BY);
  }

  async getStructureTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_CONTENTS_STRUCTURE_TYPE);
  }

  async getWallMaterials() {
    return await this.lookupService.getLookup(LookupService.GROUP_CONTENTS_STRUCTURE_WALL_MATERIAL);
  }

  async getRoofMaterials() {
    return await this.lookupService.getLookup(LookupService.GROUP_CONTENTS_STRUCTURE_ROOF_MATERIAL);
  }

  async getSecurityFeatures() {
    return await this.lookupService.getLookup(LookupService.GROUP_CONTENTS_STRUCTURE_SECURITY_FEATURE);
  }

  async getLapaDistances() {
    return await this.lookupService.getLookup(LookupService.GROUP_CONTENTS_STRUCTURE_LAPA_DISTANCE);
  }

  doFormDataPersist(data: any) {
    if (data.tabIndex === ContentsService.TAB_INDEX_CONTENT_DETAILS) {
      this.initContentsDetailsModel(data.cardId);
      this.cardModels[data.cardId].contentsDetailsModel.populateFromFormData(data.formData);
    } else if (data.tabIndex === ContentsService.TAB_INDEX_STRUCTURE_DETAILS) {
      this.initStructuresDetailsModel(data.cardId);
      this.cardModels[data.cardId].structureDetailsModel.populateFromFormData(data.formData);
      this.setAllRiskMessage(data);
      this.saveOrUpdateContents(data);
    } else if (data.tabIndex === ContentsService.TAB_INDEX_PERSONAL_BELONGINGS) {
      this.personalBelongingsService.initPersonalBelongingsModel(data.cardId);
      this.personalBelongingsService.getPersonalBelongingsModel(data.cardId).populateFromFormData(data.formData);
      this.personalBelongingsService.saveOrUpdatePersonalBelongings(this.getRiskID(data.cardId), this.quoteService.quoteId, this.getAddressForSave(data.cardId),
        this.getSecurityFeaturesForSave(data.cardId), data);
    }
  }

  saveOrUpdateContents(data: any) {
    const quoteId = this.quoteService.quoteId;
    const contentModel = this.getContentsDetailsModel(data.cardId);
    const structureModel = this.getStructureDetailsModel(data.cardId);
    const riskId = this.getRiskID(data.cardId);

    const clientInfo = CifClientInfoModel.create({
      clientResourceRef: this.userService.getUser().clientResourceRef,
      clientName: this.userService.getUser().firstName + ' ' + this.userService.getUser().firstName,
      clientNumber: this.userService.getUser().clientNumber
    });

    const quoteModel = CifQuoteModel.getMappedContentModel(clientInfo, ContentsModel.create(riskId, contentModel, structureModel));
    this.cardModels[data.cardId].cifContentsDetailsModel = quoteModel.contentRisks[0];
    if (riskId) { // Update content
      this.updateContents(quoteModel, data.cardId, quoteId, riskId);
    } else { // Save content
      if (quoteId) {
        this.saveContents(quoteModel, data.cardId, quoteId);
      } else {
        this.saveContentsAndQuote(quoteModel, data.cardId);
      }
    }
  }

  private setAllRiskMessage(data: any) {
    const currentAddress = this.cardModels[data.cardId].contentsDetailsModel.contentsAddress;

    if (this.cardHasSameAsPhysicalChecked(data.cardId) && this.isAddSameAsPhysicalAlreadyDefined(data.cardId)) {
      if (this.doSameAsPhysicalCardsHavePersonalBelongings(data.cardId)) {
        this.personalBelongingsService.showAddressExistsMessage(true);
      } else {
        this.personalBelongingsService.showAddressExistsMessage(false);
      }
    } else if (currentAddress && this.getOtherAddresses(data.cardId).indexOf(currentAddress) > -1) {
      if (this.doSameAddressCardsHavePersonalBelongings(data.cardId)) {
        this.personalBelongingsService.showAddressExistsMessage(true);
      } else {
        this.personalBelongingsService.showAddressExistsMessage(false);
      }
    } else {
      this.personalBelongingsService.showAddressExistsMessage(false);
    }
  }

  private saveContentsAndQuote(quoteModel: CifQuoteModel, cardId: string) {
    this.quoteService.saveQuote(quoteModel).then(response => {
      this.processRiskIdsInQuoteSaveResponse(response.riskReferences, cardId);
    });
  }

  private saveContents(quoteModel: CifQuoteModel, cardId: string, quoteId: string) {
    if (quoteModel.contentRisks.length === 1) {
      this.saveContentToCif(quoteId, quoteModel.contentRisks[0]).then(response => {
        this.processRiskIdInSaveResponse(response, cardId);
      });
    }
  }

  private updateContents(quoteModel: CifQuoteModel, cardId: string, quoteId: string, riskId: string | any) {
    if (quoteModel.contentRisks.length === 1) {
      this.updateContentToCif(quoteId, quoteModel.contentRisks[0], riskId).then(response => {

        this.processRiskIdInSaveResponse(response.riskResourceRef, cardId);
        this.cardModels[cardId].structureDetailsModel.populateStructureFromCifModel(response.structure, this.lookupService);
      });
    }
  }

  public async saveContentToCif(quoteId: string, contentModel: CifContentsModel): Promise<string> {
    this.loaderService.show();
    let savedRiskId = '';
    await this.mstiService.postWithAbAuth(this.contentUrlService.getSaveContentURL(quoteId), contentModel
    ).toPromise().then((response: any) => {
      Logger.log(Level.LOG, 'Save content response:', response);
      savedRiskId = response.riskResourceRef;
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while saving content', error);
      this.mstiErrorService.handleError(error);
    }).then(() => {
      this.loaderService.hide();
    });

    return savedRiskId;
  }

  public async updateContentToCif(quoteId: string, contentModel: CifContentsModel, riskId: string): Promise<CifContentsModel> {
    this.loaderService.show();
    let content: CifContentsModel;
    await this.mstiService.putWithAbAuth(this.contentUrlService.getUpdateContentURL(quoteId, riskId), contentModel
    ).toPromise().then((response: any) => {
      Logger.log(Level.LOG, 'update content response:', response);

      content = response;
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while update content', error);
      this.mstiErrorService.handleError(error);
    }).then(() => {
      this.loaderService.hide();
    });

    return content;
  }

  public async retrieveRiskDataFromCif(riskId: string, cardId: string): Promise<any> {
    this.loaderService.show();
    this.initCardModel(cardId);
    const contentCompleteSubject = new Subject<any>();

    this.mstiService.getWithAbAuth(this.contentUrlService.getRetrieveContentURL(this.quoteService.quoteId, riskId
    )).subscribe(async (response: CifContentsModel) => {
      this.cardModels[cardId].cifContentsDetailsModel = Object.assign(new CifContentsModel(), response);

      // We need to set this for the delete to work or just pass riskResourceRef from params
      this.cardModels[cardId].cifRiskModel.riskResourceRef = response.riskResourceRef;

      await this.populateContentsFromCifModel(cardId);
      this.loaderService.hide();
      contentCompleteSubject.complete();
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while retrieving content details', error);
      this.loaderService.hide();
      contentCompleteSubject.error(error);
      this.mstiErrorService.handleError(error);
    });

    const personaBelongingsCompleteSubject =
      this.personalBelongingsService.retrievePersonalBelongingsDataFromCif(cardId, this.quoteService.quoteId, riskId);

    return merge(contentCompleteSubject, personaBelongingsCompleteSubject).toPromise();
  }

  private async populateContentsFromCifModel(cardId: string) {
    this.initContentsDetailsModel(cardId);
    this.initStructuresDetailsModel(cardId);
    await this.cardModels[cardId].contentsDetailsModel.populateContentFromCifModel(this.cardModels[cardId].cifContentsDetailsModel,
      this.lookupService);
    await this.cardModels[cardId].structureDetailsModel.populateStructureFromCifModel
    (this.cardModels[cardId].cifContentsDetailsModel.structure,
      this.lookupService);
  }

  getExistingCardHeading(cardId: string): string {
    const contentsDetailsModel = this.cardModels[cardId].contentsDetailsModel;

    if (contentsDetailsModel) {
      return contentsDetailsModel.contentsAddress ? contentsDetailsModel.contentsAddress.formattedAddressLine : '';
    } else {
      return '';
    }
  }

  private getAddressForSave(cardId: string): PifRiskAddressModel {
    return this.cardModels[cardId].cifContentsDetailsModel.riskAddress;
  }

  private getSecurityFeaturesForSave(cardId: string): Array<CifSecurityFeatureModel> {
    return this.cardModels[cardId].cifContentsDetailsModel.structure.securityFeatures;
  }

  private isAddSameAsPhysicalAlreadyDefined(cardId: string): boolean {
    const otherSameAsPhyisicalCardIds = this.getOtherSameAsPhyisicalCardIds(cardId);

    return otherSameAsPhyisicalCardIds && otherSameAsPhyisicalCardIds.length > 0;
  }

  private getOtherSameAsPhyisicalCardIds(cardId: string): string[] {
    const otherSameAsPhyisicalCardIds = Object.keys(this.cardModels).filter(otherCardId => {
      return (otherCardId !== cardId && this.cardHasSameAsPhysicalChecked(otherCardId));
    });

    return otherSameAsPhyisicalCardIds;
  }

  private cardHasSameAsPhysicalChecked(cardId: string): boolean {
    return this.cardModels[cardId].contentsDetailsModel.contentsAddressSameAsPhysical === '1';
  }

}
