export class BrokerDetailsModel {
  public username: string;
  public firstName: string;
  public lastName: string;
  public emailAddress: string;
  public cellNumber: string;
  public ldapGuid: string;
  public cifToken: string;
  public partyId: string;
  public idNumber: string;
  public clientNumber: string; // this can be a PL or a CL
  public clientResourceRef: string; // client reference number

}
