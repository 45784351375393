import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {CurrencyDynamicFormControlComponent} from './currency-dynamic-form-control.component';

export class CurrencyDynamicControlModel extends BaseDynamicControlModel<number> {

    constructor(controlModel?: Partial<CurrencyDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return CurrencyDynamicFormControlComponent;
    }

}
