import {BrokerDetailsModel} from './broker-details.model';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {BrokerContextModel} from './broker-context.model';

@Injectable()
export class BrokerDetailsService {

  private user: BrokerDetailsModel;

  public userChanged = new Subject<BrokerDetailsModel>();

  private brokerContext: BrokerContextModel;

  setUser(newUser: BrokerDetailsModel) {
    this.user = newUser;
    this.userChanged.next(newUser);
  }

  getUser(): BrokerDetailsModel {
    return this.user;
  }

  getBrokerContext(): BrokerContextModel {
    return this.brokerContext;
  }

  setBrokerContext(brokerContextModel: BrokerContextModel) {
    this.brokerContext = brokerContextModel;
    this.brokerContext.mandateReference = brokerContextModel.mandateRef;
  }
}
