import {FilteredDropDownModel} from '../../../../shared/filtered-dropdown/filtered-drop-down.model';
import {VehicleModelModel} from './vehicle-model.model';

export class VehicleModelFilteredDropDownModel extends FilteredDropDownModel<VehicleModelModel> {

    constructor(values: VehicleModelModel[]) {
        super();
        this.setValues(values);
    }

    doFilter(filterValue: string) {
        this.filteredValues = this.values.filter(option =>
            option.modelDescription.toLowerCase().startsWith(filterValue)
        );
    }

}
