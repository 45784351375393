// Components
import {VehicleDescriptionComponent} from '../vehicle/component/vehicle-description/vehicle-description.component';
import {VehicleDetailsComponent} from '../vehicle/component/vehicle-details/vehicle-details.component';
import {VehicleDriverDetailsComponent} from '../vehicle/component/vehicle-driver-details/vehicle-driver-details.component';

export class VehicleConfig {
    // General config
    public static readonly CONFIG_NAME = 'Personal Motor';
    public static readonly DEFAULT_STATUS = 'Not yet completed';
    public static readonly DEFAULT_DESCRIPTION = '';
    public static readonly DIALOG_CONFIRMATION_CLOSE_MESSAGE = 'Are you sure you want to remove this vehicle cover?';
    public static readonly DIALOG_CONFIRMATION_CLOSE_TITLE = '';
    public static readonly DIALOG_CONFIRMATION_WIDTH = '45%';
    // Vehicle tab components config
    public static readonly TAB_COMPONENT_CONFIG = {
        'configTab1': VehicleDescriptionComponent,
        'configTab2': VehicleDetailsComponent,
        'configTab3': VehicleDriverDetailsComponent
    };

    // Vehicle tab headings config
    public static readonly TAB_COMPONENT_HEADING_CONFIG = {
        'configTab1': 'Vehicle description',
        'configTab2': 'Vehicle details',
        'configTab3': 'Driver details'
    };

    // Vehicle tab sub-headings config
    public static readonly TAB_COMPONENT_SUBHEADING_CONFIG = {
        'configTab1': '', // Please provide us with a description of your vehicle
        'configTab2': '', // Please provide us with a description of your vehicle
        'configTab3': '' // Please provide us with a description of your vehicle
    };

    // Vehicle tab active status config
    public static readonly TAB_COMPONENT_ACTIVE_STATUS_CONFIG = {
        'configTab1': true,
        'configTab2': false,
        'configTab3': false
    };

    // Vehicle tab metadata config
    public static readonly TAB_COMPONENT_METADATA_CONFIG = {
        'configTab1': {},
        'configTab2': {},
        'configTab3': {}
    };

 }
