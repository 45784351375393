export class GoogleAnalyticsEventModel {

    public static CATEGORY_MSTI = 'MSTI Online Quote';
    public static CATEGORY_MSTI_PRE_LOGIN = 'MSTI Quote: Pre-Login';
    // public static CATEGORY_MSTI_POST_LOGIN = 'MSTI Quote: Post-Login';
    public static CATEGORY_MSTI_BROKER = 'MSTI Quote: Broker';
    public static GOOGLE_EVENT_TRACK_METHOD = '_trackEvent';

    public static EVENT_RETURN_TO_PORTFOLIO_FROM_MARKETING_PAGE = 'Return to portfolio from Marketing Page';
    public static EVENT_START_WITH_THE_QUOTE_ON_THE_MARKETING_PAGE = 'Start with the quote on the Marketing Page';
    public static EVENT_WHY_CHOOSE_CAR_AND_HOME_INSURANCE_ON_THE_MARKETING_PAGE = 'Why choose car and home insurance on the Marketing Page';
    public static EVENT_SELECT_COVER_FROM_MARKETING_PAGE = 'Select cover from Marketing Page';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_MARKETING_PAGE = 'Submit Do you need help from Marketing Page';
    public static EVENT_COMPLETE_PERSONAL_INFORMATION = 'Complete personal information';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_PERSONAL_INFORMATION_PAGE = 'Return to portfolio from Personal Information Page';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_PERSONAL_INFORMATION_PAGE = 'Submit Do you need help from Personal Information Page';
    public static EVENT_VIEW_INFORMATION_REGARDING_EMAIL_ADDRESS = 'View information regarding email address';
    public static EVENT_GIVE_ITC_CONSENT = 'Give ITC consent';
    public static EVENT_I_WANT_TO_KNOW_MORE_ABOUT_ITC = 'I want to know more about ITC';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_ITC_CONSENT_PAGE = 'Return to portfolio from ITC Consent Page';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_ITC_CONSENT_PAGE = 'Submit Do you need help from ITC Consent Page';
    public static EVENT_CURRENTLY_INSURED_WITH_ANOTHER_COMPANY = 'Currently currentlyInsured with another company';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_CURRENTLY_INSURED_WITH_PAGE = 'Submit Do you need help from Currently Insured With Page';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_CURRENTLY_INSURED_WITH_PAGE = 'Return to Portfolio from Currently Insured With Page';
    public static EVENT_COMPANY_INSURED_WITH = 'Company currentlyInsured with';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_COMPANY_INSURED_WITH_PAGE = 'Return to portfolio from Company Insured With Page';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_COMPANY_INSURED_WITH_PAGE = 'Submit Do you need help from Company Insured With Page';
    public static EVENT_PERIOD_OF_BREAK_IN_COVER = 'Period of break in cover';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_BREAK_IN_COVER_PAGE = 'Return to portfolio from Break In Cover Page';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_BREAK_IN_COVER_PAGE = 'Submit Do you need help from Break in Cover Page';
    public static EVENT_PERIOD_OF_CONTINUOUS_COVER_WITHOUT_BREAKS = 'Period of continuous cover, without breaks';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_CONTINUOUS_COVER_PAGE = 'Return to portfolio from Continuous Cover Page';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_CONTINUOUS_COVER_PAGE = 'Submit Do you need help from Continuous Cover Page';
    public static EVENT_CLAIMS_IN_THE_LAST_THREE_YEARS = 'Claims in the last three years';
    public static EVENT_CONSENTED_ADMIN_FEE = 'Consented to be charged an admin fee';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_CLAIMS_DECLARATION_PAGE = 'Return to portfolio from Claims Declaration Page';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_CLAIMS_DECLARATION_PAGE = 'Submit Do you need help from Claims Declaration Page';
    public static EVENT_COMPLETE_PREVIOUS_CLAIMS_DETAILS = 'Complete previous claims details';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_CLAIMS_CAPTURE_PAGE = 'Return to portfolio from Claims Capture Page';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_CLAIMS_CAPTURE_PAGE = 'Submit Do you need help from Claims Capture Page';
    public static EVENT_INSURANCE_CANCELLED_OR_DECLINED = 'Insurance cancelled/declined';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_INSURANCE_CANCELLED_OR_DECLINED_PAGE = 'Return to portfolio from Insurance Cancelled/Declined Page';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_INSURANCE_CANCELLED_OR_DECLINED_PAGE = 'Submit Do you need help from Insurance Cancelled/Declined Page';
    public static EVENT_PROCEED_TO_COVER_DETAILS = 'Proceed to cover details';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_PROCEED_TO_COVER_DETAILS_PAGE = 'Return to portfolio from Proceed to Cover Details Page';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_PROCEED_TO_COVER_DETAILS_PAGE = 'Submit Do you need help from Proceed to Cover Details Page';
    public static EVENT_ADD_ANOTHER_VEHICLE_ON_VEHICLE_COVER = 'Add another vehicle on vehicle cover';
    public static EVENT_REMOVE_VEHICLE_COVER = 'Remove vehicle cover';
    public static EVENT_COMPLETE_VEHICLE_DESCRIPTION_ON_VEHICLE_COVER = 'Complete vehicle description on vehicle cover';
    public static EVENT_CANNOT_FIND_VEHICLE_MAKE_OR_MODEL = 'Cannot find vehicle make or model';
    public static EVENT_COMPLETE_VEHICLE_DETAILS_ON_VEHICLE_COVER = 'Complete vehicle details on vehicle cover';
    public static EVENT_COMPLETE_DRIVER_DETAILS_ON_VEHICLE_COVER = 'Complete driver details on vehicle cover';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_COVER_DETAILS_PAGE = 'Submit Do you need help from Cover Details Page';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_COVER_DETAILS_PAGE = 'Return to Portfolio from Cover Details Page';
    public static EVENT_VIEW_TERMS_AND_CONDITIONS = 'View terms and conditions';
    public static EVENT_RETURN_TO_PORTFOLIO_FROM_QUOTE_PAGE = 'Return to Portfolio from Quote Page';
    public static EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_QUOTE_PAGE = 'Submit Do you need help from Quote Page';
    public static EVENT_VIEW_INFORMATION_REGARDING_EXCESS = 'View information regarding excess';
    public static EVENT_VIEW_INFORMATION_REGARDING_MOMENTUM_ASSIST = 'View information regarding Momentum Assist';
    public static EVENT_VIEW_INFORMATION_REGARDING_SASRIA = 'View information regarding SASRIA';
    public static EVENT_SUBMIT_QUOTE = 'Submit quote ';

    public static EVENT_ADD_CONTENTS_INSURANCE_FOR_ANOTHER_PROPERTY = 'Add content risks insurance for another property';
    public static EVENT_SELECT_NEW_COVER = 'Select new cover';
    public static EVENT_COMPLETE_HOME_CONTENTS_DETAILS_ON_HOME_CONTENTS_INSURANCE = 'Complete home content risks details on Home Contents insurance';
    public static EVENT_COMPLETE_STRUCTURE_DETAILS_ON_HOME_CONTENTS_INSURANCE = 'Complete structure details on Home Contents insurance';
    public static EVENT_VIEW_INFORMATION_REGARDING_SIZE_OF_STRUCTURE = 'View information regarding size of structure';
    public static EVENT_VIEW_INFORMATION_REGARDING_COMMUNE = 'View information regarding commune';
    public static EVENT_VIEW_INFORMATION_REGARDING_STRUCTURE_APPROVAL = 'View information regarding structure approval';
    public static EVENT_VIEW_INFORMATION_REGARDING_LAPA = 'View information regarding lapa';
    public static EVENT_VIEW_INFORMATION_REGARDING_LIGHTNING_CONDUCTOR = 'View information regarding lightning conductor';
    public static EVENT_VIEW_INFORMATION_REGARDING_THATCHED_ROOF = 'View information regarding thatched roof';
    public static EVENT_SELECT_UNSPECIFIED_PERSONAL_BELONGINGS = 'Select unspecified personal belongings';
    public static EVENT_SELECT_SPECIFIED_PERSONAL_BELONGINGS = 'Select specified personal belongings';
    public static EVENT_SELECT_UNSPECIFIED_AND_SPECIFIED_PERSONAL_BELONGINGS = 'Select unspecified and specified personal belongings';
    public static EVENT_SELECT_NO_THANKS_I_DONT_NEED_ANY_PERSONAL_BELONGINGS_COVER = 'Select, no thanks I don\'t need any personal belongings cover';
    public static EVENT_COMPLETED_PERSONAL_BELONGINGS_COVER = 'Completed personal belongings cover';
    public static EVENT_ADD_SPECIFIED_PERSONAL_BELONGINGS = 'Add specified personal belongings';
    public static EVENT_REMOVE_HOME_CONTENTS_AND_PERSONAL_BELONGINGS = 'Remove home content risks and personal belongings';

    public static EVENT_ADD_ANOTHER_BUILDING = 'Add another building to insure';
    public static EVENT_COMPLETE_BUILDING_DETAILS_ON_MY_BUILDING = 'Complete building details on My Building';
    public static EVENT_COMPLETE_STRUCTURE_DETAILS_ON_MY_BUILDING = 'Complete structure details on My Building';
    public static EVENT_VIEW_INFORMATION_REGARDING_COMMUNE_ON_BUILDING = 'View information regarding commune on Building';
    public static EVENT_VIEW_INFORMATION_REGARDING_STRUCTURE_APPROVAL_ON_BUILDING = 'View information regarding structure approval on Building';
    public static EVENT_VIEW_INFORMATION_REGARDING_LAPA_ON_BUILDING = 'View information regarding lapa on Building';
    public static EVENT_VIEW_INFORMATION_REGARDING_LIGHTNING_CONDUCTOR_ON_BUILDING = 'View information regarding lightning conductor on Building';
    public static EVENT_VIEW_INFORMATION_REGARDING_THATCHED_ROOF_ON_BUILDING = 'View information regarding thatched roof on Building';
    public static EVENT_REMOVE_BUILDING = 'Remove building';

    public static EVENT_PROCEED_TO_PREMIUM_SUMMARY = 'Proceed to Premium Summary';

    public category: string;
    public eventAction: string;
    public eventLabel: string;
    public trackMethod: string;

}
