import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {LookupService} from '../../../../shared/lookup/lookup.service';
import {CifBuildingModel} from './cif/cif-building.model';
import {NumberLookupModel} from '../../../../shared/lookup/number-lookup.model';

export class BuildingStructureDetailsModel {

    constructor() { }

    structureSize: number;
    structureBedRooms: number;
    structureWallMaterial: LookupModel;
    structureType: LookupModel;
    structureCommune: string;
    structureApproved: string;
    structureLighting: string;
    structureHasThatch: string;
    structureIsTreated: string;
    structureDistance: LookupModel;
    structureSecurity: LookupModel[] = new Array();
    structureRoofMaterial: LookupModel;
    structureHasLapa: string;
    structureRoofPitch: LookupModel;
    structureNumberOfStoreys: LookupModel;
    structureOccupiedStoreys: LookupModel;
    structureNumberOfGeysers: LookupModel;
    structureHasSwimmingPool: string;

    populateFromFormData(formData) {
        this.structureSize = formData.structureSize;
        this.structureWallMaterial = formData.structureWallMaterial;
        this.structureType = formData.structureType;
        this.structureBedRooms = formData.structureBedRooms;
        this.structureSecurity = formData.structureSecurity;
        this.structureCommune = formData.structureCommune;
        this.structureApproved = formData.structureApproved;
        this.structureHasThatch = formData.structureHasThatch;
        this.structureRoofMaterial = formData.structureRoofMaterial;

        if (formData.structureLighting === null) {
            this.structureLighting = null;
        } else {
            this.structureLighting = formData.structureLighting;
        }

        this.structureIsTreated = formData.structureIsTreated;
        this.structureDistance = formData.structureDistance;
        this.structureHasLapa = formData.structureHasLapa;
        this.structureRoofPitch = formData.structureRoofPitch;
        this.structureNumberOfStoreys = formData.structureNumberOfStoreys;
        this.structureOccupiedStoreys = formData.structureOccupiedStoreys;
        this.structureNumberOfGeysers = formData.structureNumberOfGeysers;
        this.structureHasSwimmingPool = formData.structureHasSwimmingPool;
    }

    async populateStructureFromCifModel(cifBuildingModel: CifBuildingModel, lookupServiceInstance: LookupService) {
        const cifStructureModel = cifBuildingModel.structure;

        this.structureSize = cifStructureModel.sizeOfDwelling;
        this.structureBedRooms = cifStructureModel.numberOfBedrooms;
        this.structureCommune = (cifStructureModel.commune) ? '1' : '0';
        this.structureApproved = (cifStructureModel.constructionApproved) ? '1' : '0';

        if (cifStructureModel.lightingConductor === null) {
            this.structureLighting = null;
        } else {
            this.structureLighting = (cifStructureModel.lightingConductor) ? '1' : '0';
        }

        this.structureType = await lookupServiceInstance.getLookUpObjFromCode(
            cifBuildingModel.buildingTypeCode, LookupService.GROUP_BUILDING_STRUCTURE_TYPE
        );

        this.structureWallMaterial = await lookupServiceInstance.getLookUpObjFromCode(
            cifStructureModel.wallConstructionTypeCode, LookupService.GROUP_BUILDING_STRUCTURE_WALL_MATERIAL
        );

        this.structureRoofMaterial = await lookupServiceInstance.getLookUpObjFromCode(
            cifStructureModel.roofConstructionTypeCode, LookupService.GROUP_BUILDING_STRUCTURE_ROOF_MATERIAL
        );

        for (const securityFeature of cifStructureModel.securityFeatures) {
            const value = await lookupServiceInstance.getLookUpObjFromCode(securityFeature.securityFeatureCode,
                LookupService.GROUP_BUILDING_STRUCTURE_SECURITY_FEATURE);
            this.structureSecurity.push(value);
        }

        this.structureHasLapa = (cifStructureModel.lapaOnPremises) ? '1' : '0';

        if (cifStructureModel.lapaOnPremises) {
            this.structureHasThatch = (cifStructureModel.thatchLapa) ? '1' : '0';
            this.structureIsTreated = (cifStructureModel.thatchTreated) ? '1' : '0';
            this.structureDistance = await lookupServiceInstance.getLookUpObjFromCode(
                cifStructureModel.thatchDistanceTypeCode, LookupService.GROUP_BUILDING_STRUCTURE_LAPA_DISTANCE
            );
        } else {
            this.structureHasThatch = null;
            this.structureIsTreated = null;
            this.structureDistance = null;
        }

        this.structureRoofPitch = await lookupServiceInstance.getLookUpObjFromCode(
            cifStructureModel.roofPitchTypeCode, LookupService.GROUP_BUILDING_STRUCTURE_ROOF_PITCH_TYPE
        );

        this.structureNumberOfStoreys = await lookupServiceInstance.getLookUpObjFromCode(
            cifStructureModel.numberOfStoreysTypeCode, LookupService.GROUP_BUILDING_STRUCTURE_NUMBER_OF_STOREYS_TYPE
        );

        this.structureOccupiedStoreys = await lookupServiceInstance.getLookUpObjFromCode(
            cifStructureModel.numberOfStoreysOccupiedCode, LookupService.GROUP_BUILDING_STRUCTURE_NUMBER_OF_STOREYS_OCCUPIED
        );

        this.structureNumberOfGeysers = new NumberLookupModel(cifStructureModel.numberOfWaterHeatingSystems);

        this.structureHasSwimmingPool = (cifStructureModel.swimmingPool) ? '1' : '0';
    }

}
