import {Injectable} from '@angular/core';
import {UrlService} from '../../shared/services/url.service';
import {MSTIConfiguration} from '../../shared/msti.config';

@Injectable()
export class DisclosureUrlService extends UrlService {

  constructor() {
    super();
  }

  // baseURl = MSTIConfiguration.constants.urlPrefix;

  getSaveOrUpdateDisclosureURL(clientId: string): string {
    // return this.baseURl + this.FULFILLMENT_API_V2 + partyKey + '/clients/' + clientResourceRef + '/disclosures';
    return MSTIConfiguration.constants.brokerBffUrlPrefix + this.BROKERBFF_API + 'brokerClientsApi/clients/' + clientId + '/disclosures';

  }

  getDisclosureURL(clientId: string): string {
    return MSTIConfiguration.constants.brokerBffUrlPrefix + this.BROKERBFF_API + 'brokerClientsApi/clients/' + clientId + '/disclosures';

  }

}
