export abstract class BaseCifRiskModel {

  // clientId: string; // Mandatory
   riskResourceRef: string;

  // protected _clientModel: SaveClientResponseModel;

  constructor() {
    /* this._clientModel = clientModel;
     this.clientId = this._clientModel.clientResourceRef;*/
  }

  // NOTE: the dateString format used is yyyy-mm-dd
  protected getAgeFromDateString(dateString): number {
    const today = new Date();
    const startDate = new Date(dateString);
    let age = today.getFullYear() - startDate.getFullYear();
    const month = today.getMonth() - startDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < startDate.getDate())) {
      age--;
    }

    return age;
  }

}
