import {Injectable} from '@angular/core';
import {LookupService} from '../../shared/lookup/lookup.service';
import {LookupFilteredDropDownModel} from '../../shared/lookup/lookup.filtered.drop.down.model';
import {DisclosureQuestionsModel} from '../models/disclosure.questions.model';
import {MSTIService} from '../../shared/services/msti.service';
import {BrokerDetailsService} from '../../shared/broker-common/broker-details.service';
import {CifDisclosureQuestionsModel} from '../models/CifDisclosureQuestions.model';
import {PersonalService} from '../../personal/service/personal.service';
import {DisclosureUrlService} from './disclosure-url.service';
import {RoutingService} from '../../shared/services/routing.service';
import {Logger} from '../../shared/utilities/logger';
import {Level} from '../../shared/utilities/logger-level';

@Injectable()
export class DisclosureService {

  disclosureQuestionsModel: DisclosureQuestionsModel = new DisclosureQuestionsModel();
  periodWithoutCoverOptions: LookupFilteredDropDownModel = new LookupFilteredDropDownModel([]);
  insuranceCompanyOptions: LookupFilteredDropDownModel = new LookupFilteredDropDownModel([]);
  previousCancellationOptions: LookupFilteredDropDownModel = new LookupFilteredDropDownModel([]);
  riskTypeOptions: LookupFilteredDropDownModel = new LookupFilteredDropDownModel([]);
  claimTypeOptions: LookupFilteredDropDownModel = new LookupFilteredDropDownModel([]);
  public routingStack: RoutingStack = new RoutingStack();

  constructor(private personalService: PersonalService, private userService: BrokerDetailsService,
              private lookupService: LookupService, private mstiService: MSTIService,
              private disclosureUrlService: DisclosureUrlService, private routingService: RoutingService,
  ) {
  }

  async getPeriodWithoutCover() {
    return await this.lookupService.getLookup(LookupService.GROUP_PERIOD_WITHOUT_CVR);
  }

  async getInsuranceCompanyList() {
    return await this.lookupService.getLookup(LookupService.GROUP_INS_NAME_LIST);
  }

  async getPreviousCancellationOptions() {
    return await this.lookupService.getLookup(LookupService.GROUP_PREV_CANCELLATION);
  }

  async getRiskTypeOptions() {
    return await this.lookupService.getLookup(LookupService.GROUP_RISK_TYPE);
  }

  async getClaimTypeOptions() {
    return await this.lookupService.getLookup(LookupService.GROUP_CLAIM_TYPE);
  }

  initializeDisclosureLookups() {
    //  this.loaderService.show();
    this.getPeriodWithoutCover().then(data => {
      this.periodWithoutCoverOptions.setValues(data);
    }).then(() => Logger.log(Level.LOG, 'completed'));

    this.getInsuranceCompanyList().then(data => {
      this.insuranceCompanyOptions.setValues(data);
    }).then(() => Logger.log(Level.LOG, 'completed'));

    this.getPreviousCancellationOptions().then(data => {
      this.previousCancellationOptions.setValues(data);
    }).then(() => Logger.log(Level.LOG, 'completed'));
    this.getRiskTypeOptions().then(data => {
      this.riskTypeOptions.setValues(data);
    }).then(() => Logger.log(Level.LOG, 'completed risk', this.riskTypeOptions));
    this.getClaimTypeOptions().then(data => {
      this.claimTypeOptions.setValues(data);
    }).then(() => Logger.log(Level.LOG, 'completed claim', this.claimTypeOptions));
  }

  getDisclosureQuestionsModel() {
    return this.disclosureQuestionsModel;
  }

  addToStackAndRoute(path: string, addToStack: string) {
    this.routingStack.getStack().push(addToStack);
    this.routingService.route(path);
  }

  goBack() {
    this.routingService.route(this.routingStack.getStack().pop());
  }

  saveOrUpdateDisclosure(disclosureQuestionsModel: DisclosureQuestionsModel) {
    let cifDisclosureQuestionsModel: CifDisclosureQuestionsModel;
    if (disclosureQuestionsModel.recordKey) {
      cifDisclosureQuestionsModel = this.convertToCifModel(disclosureQuestionsModel, DisclosureTags.UPDATE);
    } else {
      cifDisclosureQuestionsModel = this.convertToCifModel(disclosureQuestionsModel, DisclosureTags.CREATE);
    }
    const url = this.disclosureUrlService.getSaveOrUpdateDisclosureURL(this.personalService.clientDetails.clientResourceRef);

    return this.mstiService.putWithAbAuth(url, cifDisclosureQuestionsModel);
  }

  getDisclosure() {
    const url = this.disclosureUrlService.getDisclosureURL(this.personalService.clientDetails.clientResourceRef);
    return this.mstiService.getWithAbAuth(url);
  }

  convertToCifModel(disclosureQuestionsModel: DisclosureQuestionsModel, eventType: string): CifDisclosureQuestionsModel {
    const cifDisclosureQuestionsModel: CifDisclosureQuestionsModel = new CifDisclosureQuestionsModel();
    // previous insurance section
    cifDisclosureQuestionsModel.insuranceHistory.currentlyInsured = disclosureQuestionsModel.currentlyInsured === 'Yes' ? true : false;
    cifDisclosureQuestionsModel.insuranceHistory.currentInsurerCode = disclosureQuestionsModel.currentInsurerInfo ? disclosureQuestionsModel.currentInsurerInfo.code : null;
    cifDisclosureQuestionsModel.insuranceHistory.uninsuredPeriodCode = disclosureQuestionsModel.uninsuredPeriodInfo ? disclosureQuestionsModel.uninsuredPeriodInfo.code : null;
    cifDisclosureQuestionsModel.insuranceHistory.currentInsuredPeriodCode = disclosureQuestionsModel.insuredPeriodInfo ? disclosureQuestionsModel.insuredPeriodInfo.code : null;
    cifDisclosureQuestionsModel.insuranceHistory.previousInsuranceCancelledCode = disclosureQuestionsModel.cancellationReasonInfo.code;
    cifDisclosureQuestionsModel.insuranceHistory.event = eventType;
    // Previous claim section
   if (disclosureQuestionsModel.hasPreviousClaim === 'Yes') {
      for (let i = 0; i < disclosureQuestionsModel.previousClaims.length; i++) {
        const claim = disclosureQuestionsModel.previousClaims[i];
        if (claim.previousClaimHistoryId && claim.event) {
          if (claim.event === DisclosureTags.DELETE) {
            disclosureQuestionsModel.previousClaims[i].event = DisclosureTags.DELETE; // 'DELETE';
          } else {
            disclosureQuestionsModel.previousClaims[i].event = DisclosureTags.UPDATE; // 'UPDATE';
          }
        } else {
          disclosureQuestionsModel.previousClaims[i].event = DisclosureTags.CREATE; // 'CREATE';
        }
      }
      Object.assign(cifDisclosureQuestionsModel.previousClaims, disclosureQuestionsModel.previousClaims);
      cifDisclosureQuestionsModel.previousClaims.forEach(claim => {
        // delete claim['previousClaimHistoryId'];claimId
        delete claim['claimId'];
      });

    } else {
      for (let i = 0; i < disclosureQuestionsModel.previousClaims.length; i++) {
        if (disclosureQuestionsModel.previousClaims[i].previousClaimHistoryId) {
          disclosureQuestionsModel.previousClaims[i].event = DisclosureTags.DELETE;
        } else {
          delete cifDisclosureQuestionsModel.previousClaims[i];
        }
      }
    }

    if (cifDisclosureQuestionsModel.insuranceHistory.uninsuredPeriodCode === '09') { // MQF-663
      cifDisclosureQuestionsModel.insuranceHistory.currentInsuredPeriodCode = '09';
    }
    return cifDisclosureQuestionsModel;
  }

  async convertCifModelToDisclosureQuestion(cifDisclosureQuestionsModel: CifDisclosureQuestionsModel): Promise<DisclosureQuestionsModel> {
    this.disclosureQuestionsModel.recordKey = cifDisclosureQuestionsModel.insuranceHistory.recordKey;
    this.disclosureQuestionsModel.currentlyInsured = cifDisclosureQuestionsModel.insuranceHistory.currentlyInsured ? 'Yes' : 'No';
    this.disclosureQuestionsModel.currentInsurerInfo = await this.lookupService.getLookUpObjFromCode(
      cifDisclosureQuestionsModel.insuranceHistory.currentInsurerCode, LookupService.GROUP_INS_NAME_LIST);
    this.disclosureQuestionsModel.uninsuredPeriodInfo = await this.lookupService.getLookUpObjFromCode(
      cifDisclosureQuestionsModel.insuranceHistory.uninsuredPeriodCode, LookupService.GROUP_PERIOD_WITHOUT_CVR);
    this.disclosureQuestionsModel.insuredPeriodInfo = await this.lookupService.getLookUpObjFromCode(
      cifDisclosureQuestionsModel.insuranceHistory.currentInsuredPeriodCode, LookupService.GROUP_PERIOD_WITHOUT_CVR);
    this.disclosureQuestionsModel.cancellationReasonInfo = await this.lookupService.getLookUpObjFromCode(
      cifDisclosureQuestionsModel.insuranceHistory.previousInsuranceCancelledCode, LookupService.GROUP_PREV_CANCELLATION);
    if (cifDisclosureQuestionsModel.previousClaims && cifDisclosureQuestionsModel.previousClaims.length !== 0) {
      this.disclosureQuestionsModel.previousClaims = Object.assign([], cifDisclosureQuestionsModel.previousClaims);
      /* console.log('b4 remove= ', this.disclosureQuestionsModel.previousClaims);
       for (const val of this.disclosureQuestionsModel.previousClaims) {
         delete val['claimId'];
       }
       console.log('after remove= ', this.disclosureQuestionsModel.previousClaims);*/

      for (let i = 0; i < this.disclosureQuestionsModel.previousClaims.length; i++) {
        this.disclosureQuestionsModel.previousClaims[i].claimId = i;
      }


      this.disclosureQuestionsModel.hasPreviousClaim = 'Yes';
    } else {
      this.disclosureQuestionsModel.previousClaims = [];
      this.disclosureQuestionsModel.hasPreviousClaim = 'No';
    }
    console.log('ab n', this.disclosureQuestionsModel);
    return this.disclosureQuestionsModel;
  }

}

export class RoutingStack {
  _store: string[] = [];


  public getStack() {
    return this._store;
  }

  push(val: string) {
    this._store.push(val);
  }

  pop(): string | undefined {
    return this._store.pop();
  }
}

export enum DisclosureTags {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}
