import {Component, OnInit} from '@angular/core';
import {BrokerDetailsService} from '../shared/broker-common/broker-details.service';
import {BrokerDetailsModel} from '../shared/broker-common/broker-details.model';
import {LogoutService} from '../shared/services/logout.service';
import {ImageService} from '../shared/services/image.service';
import {NeedHelpPageTags} from '../footer/need-help/need-help-page-tags';
import {NeedHelpService} from '../footer/need-help/services/need-help.service';

@Component({
  selector: 'app-manager',
  templateUrl: './app_manager.component.html'
})
export class AppManagerComponent implements OnInit {

  robonIcon: string;
  user: BrokerDetailsModel;
  isPreLoginUser: boolean;
phoneNumber: string;
  constructor(
    private userService: BrokerDetailsService, private logoutService: LogoutService, private imageService: ImageService,
    private needHelpService: NeedHelpService) {
    this.robonIcon = imageService.getImage('ERROR-ROBOT');
    this.phoneNumber = this.userService.getBrokerContext().callCenterNumber;
  }

  ngOnInit() {
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_MAINTENANCE);
  }

  logout() {
    this.logoutService.backToPortfolio();
  }

  goBack() {
    this.logoutService.backToPreLoginMarketing();

  }
}
