import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatRadioModule,
  MatSelectModule
} from '@angular/material';
import {DynamicFormComponent} from './component/dynamic-form.component';
import {DynamicComponentContainerDirective} from './helper/DynamicComponentContainerDirective';
import {AddressDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/address/address-dynamic-control.component';
import {AutocompleteDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/autocomplete/autocomplete-dynamic-form-control.component';
import {AutocompleteMultiSelectDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/autocomplete-multiselect/autocomplete-multiselect-dynamic-form-control.component';
import {ButtonDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/button/button-dynamic-form-control.component';
import {DatePickerDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/datepicker/datepicker-dynamic-form-control.component';
import {EmailDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/email/email-dynamic-form-control.component';
import {IdNumberDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/id-number/idnumber-dynamic-form-control.component';
import {RadioButtonYesNoDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/radiobutton-yesno/radiobutton-yesno-dynamic-form-control.component';
import {TextboxDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/textbox/textbox-dynamic-form-control.component';
import {DynamicComponentService} from './helper/DynamicComponentService';
import {DynamicFormControlService} from './dynamic-form-control/service/dynamic-form-control-service';
import {CurrencyDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/currency/currency-dynamic-form-control.component';
import {ValidationModule} from '../validators/validation.module';
import {RadioButtonGroupDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/radiobutton-group/radiobutton-group-dynamic-form-control.component';
import {PersonalBelongingsSpecifiedDynamicFormControlComponent} from './dynamic-form-control/control-types/risk-specific/personal-belongings-specified/personal-belongings-specified-dynamic-form-control.component';
import {TitleDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/title/title-dynamic-form-control.component';
import {TextboxNumericDynamicFormControlComponent} from './dynamic-form-control/control-types/generic/textbox-numeric/textbox-numeric-dynamic-form-control.component';

@NgModule({
    declarations: [
        DynamicFormComponent,
        DynamicComponentContainerDirective,
        AddressDynamicFormControlComponent,
        AutocompleteDynamicFormControlComponent,
        AutocompleteMultiSelectDynamicFormControlComponent,
        ButtonDynamicFormControlComponent,
        DatePickerDynamicFormControlComponent,
        EmailDynamicFormControlComponent,
        IdNumberDynamicFormControlComponent,
        RadioButtonYesNoDynamicFormControlComponent,
        TextboxDynamicFormControlComponent,
        CurrencyDynamicFormControlComponent,
        RadioButtonGroupDynamicFormControlComponent,
        PersonalBelongingsSpecifiedDynamicFormControlComponent,
        TitleDynamicFormControlComponent,
        TextboxNumericDynamicFormControlComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatInputModule,
        MatChipsModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDialogModule,
        MatNativeDateModule,
        MatRadioModule,
        MatDatepickerModule,
        ValidationModule
    ],
    exports: [
        DynamicFormComponent
    ],
    providers: [DynamicComponentService, DynamicFormControlService],
    bootstrap: [
        AddressDynamicFormControlComponent,
        AutocompleteDynamicFormControlComponent,
        AutocompleteMultiSelectDynamicFormControlComponent,
        ButtonDynamicFormControlComponent,
        DatePickerDynamicFormControlComponent,
        EmailDynamicFormControlComponent,
        IdNumberDynamicFormControlComponent,
        RadioButtonYesNoDynamicFormControlComponent,
        TextboxDynamicFormControlComponent,
        CurrencyDynamicFormControlComponent,
        RadioButtonGroupDynamicFormControlComponent,
        PersonalBelongingsSpecifiedDynamicFormControlComponent,
        TitleDynamicFormControlComponent,
        TextboxNumericDynamicFormControlComponent
    ]
})
export class DynamicFormModule {
}
