import {PifVehicleAddressDetailsModel} from './pif-vehicle-address-details.model';

export class PifVehicleAddressTypeModel {

  public storageTypeCode: string;
  public areaTypeCode: string;
  public defaultToPhysicalAddress: boolean;
  public addressDetails: PifVehicleAddressDetailsModel = new PifVehicleAddressDetailsModel();

}
