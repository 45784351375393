import {OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

export class AutoUnsubscribe implements OnDestroy {

    subscriptions: Subscription[];

    constructor() {
        this.subscriptions = [];
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }
    }

}
