import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {DatePickerDynamicFormControlComponent} from './datepicker-dynamic-form-control.component';

export class DatePickerDynamicControlModel extends BaseDynamicControlModel<string> {

    constructor(controlModel?: Partial<DatePickerDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return DatePickerDynamicFormControlComponent;
    }

}
