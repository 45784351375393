import {ContactInfo} from './contact-info.model';
import {CifRegularDriverModel} from '../cif-regular-driver.model';
import {CifLicenseDetailsModel} from '../cif-license-details.model';
import {CifDrivingConvictionModel} from '../cif-driving-conviction.model';
import {CifPartyInfoModel} from '../../../../../../personal/model/SaveClientModels/cif-party-info.model';
import {PifVehicleRegularDriverAddressDetailsModel} from '../../pif/pif-vehicle-regular-driver-address-details.model';

export class VehicleRegularDriverUpdateModel {
  constructor(public clientNumber: string, public personal: CifPartyInfoModel, public contact: ContactInfo,
              public addressDetails: PifVehicleRegularDriverAddressDetailsModel, public reason: string, public relationshipToInsuredCode: string,
              public isPolicyHolder: boolean, public hasDrivingConviction: boolean,
              public driversLicense: CifLicenseDetailsModel, public drivingConvictions: CifDrivingConvictionModel[]
  ) {
  }

  static getMappedModel(cifRegularDriverModel: CifRegularDriverModel): VehicleRegularDriverUpdateModel {

    console.log('Ab1', cifRegularDriverModel);
    let address: PifVehicleRegularDriverAddressDetailsModel;
    if (cifRegularDriverModel.addressList.length > 0) {
      address = PifVehicleRegularDriverAddressDetailsModel.cifToPif(cifRegularDriverModel.addressList[0].addressDetails);
    } else {
      address = null;
    }

    let personal: CifPartyInfoModel;
    let contact: ContactInfo;
    if (!cifRegularDriverModel.isPolicyHolder) {
      personal = cifRegularDriverModel.personal;
      contact = cifRegularDriverModel.contact;
    } else {
      personal = null;
      contact = null;
    }
    const vehicleRegularDriverUpdateModel = new VehicleRegularDriverUpdateModel(
      cifRegularDriverModel.clientResourceRef,
      personal, contact, address,
      cifRegularDriverModel.reason,
      cifRegularDriverModel.relationshipToInsuredCode,
      cifRegularDriverModel.isPolicyHolder,
      cifRegularDriverModel.hasDrivingConviction,
      cifRegularDriverModel.driversLicense,
      cifRegularDriverModel.drivingConvictions
    );
    console.log('Ab2', vehicleRegularDriverUpdateModel);
    return vehicleRegularDriverUpdateModel;
  }
}



