import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ImageService} from '../../../shared/services/image.service';
import {LogoutService} from '../../../shared/services/logout.service';
import {NeedHelpService} from '../services/need-help.service';
import {NeedHelpPageTags} from '../need-help-page-tags';
import {DigitalAnalyticsService} from '../../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsPageModel} from '../../../shared/digital-analytics/model/digital-analytics-page.model';
import {SmoothScroll} from '../../../shared/utilities/smooth-scroll';

@Component({
    selector: 'app-need-help-thankyou',
    templateUrl: './thank-you-message.component.html'
})
export class NeedHelpThankYouMessageComponent implements OnInit, AfterViewInit {

    @ViewChild('thankyouDOM') suggestion: ElementRef;
    wellDoneIcon: any;
    isPreLoginUser: boolean;

    constructor(
        private imageService: ImageService,
        private logoutService: LogoutService,
        private needHelpService: NeedHelpService,
        private digitalAnalyticsService: DigitalAnalyticsService
    ) {
    }

    ngOnInit() {
        const attributes: Map<number, string> = new Map<number, string>();
        this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_HELP_ACKNOWLEDGEMENT_PAGE_ID, attributes);

        this.initialiseIcons(this.imageService);
        this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_QUOTE_THANK_YOU_FOR_YOUR_APPLICATION);
    }

    ngAfterViewInit() {
        this.waitForThisDocumentToLoad().then(this.scrollTop);
    }

    private initialiseIcons(imageService: ImageService) {
        this.wellDoneIcon = imageService.getImage('WELL-DONE-ICON');
    }

    private scrollTop() {
        SmoothScroll.smoothScrollToTop();
    }

    private async waitForThisDocumentToLoad() {
        const promise = new Promise((resolve) => { setTimeout(() => resolve(this.suggestion.nativeElement), 200); });
        return await promise;
    }

    logout() {
        this.logoutService.backToPortfolio();
    }
    goBack() {
        this.logoutService.backToPreLoginMarketing();

    }
}
