import {CifSecurityFeatureModel} from '../../../vehicle/model/cif/cif-security-feature.model';
import {LookupModel} from '../../../../../shared/lookup/lookup.model';

export class CifContentStructureModel {

    dwellingTypeCode: string;
    sizeOfDwelling: number;
    numberOfBedrooms: number;
    wallConstructionTypeCode: string;
    roofConstructionTypeCode: string;
    commune: boolean;
    constructionApproved: boolean;
    lightingConductor: boolean;
    thatchLapa: boolean;
    thatchTreated: boolean;
    thatchDistanceTypeCode: string;
    lapaOnPremises: boolean;
    roofPitchTypeCode: LookupModel;
    numberOfStoreysTypeCode: LookupModel;
    numberOfStoreysOccupiedCode: LookupModel;
    numberOfWaterHeatingSystems: LookupModel;
    swimmingPool: boolean;
    securityFeatures: CifSecurityFeatureModel[] = new Array<CifSecurityFeatureModel>();

}
