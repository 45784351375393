/// <reference types="@types/googlemaps" />

import {Injectable, NgZone} from '@angular/core';
import {Observable} from 'rxjs';
import {MSTIService} from '../services/msti.service';
import {UrlService} from '../services/url.service';
import {Logger} from '../utilities/logger';
import {Level} from '../utilities/logger-level';

@Injectable()
export class MstiAgmService {

    private static readonly DEFAULT_LATITUDE = -26.195246;
    private static readonly DEFAULT_LONGITUDE = 28.034088;

    private geocoder: any;

    public autoComplete: google.maps.places.Autocomplete;
    public place: google.maps.places.PlaceResult;

    constructor(
        private mstiService: MSTIService,
        private urlService: UrlService,
        private ngZone: NgZone
    ) {
    }

    public initMapsComponents(autoCompleteElement) {
        this.geocoder = new google.maps.Geocoder();
        this.autoComplete = new google.maps.places.Autocomplete(autoCompleteElement, {
            types: ['address']
        });
    }

    getCurrentPosition(): Observable<any> {
        console.log('getCurrentPosition');

        return new Observable(observer => {
            const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
            console.log('isIEOrEdge', isIEOrEdge);
            if (isIEOrEdge) { // In IE and edge the current location is nevere returned even if the allow loc is set to true
                observer.next({
                    longitude: MstiAgmService.DEFAULT_LONGITUDE,
                    latitude: MstiAgmService.DEFAULT_LATITUDE
                });
            } else {
                this.ngZone.runOutsideAngular(() => {
                    if ('geolocation' in navigator) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            observer.next({
                                longitude: isNaN(position.coords.longitude) ? MstiAgmService.DEFAULT_LONGITUDE : position.coords.longitude,
                                latitude: isNaN(position.coords.latitude) ? MstiAgmService.DEFAULT_LATITUDE : position.coords.latitude
                            });
                        }, (error) => {
                            Logger.log(Level.ERROR, 'Error getting current position from Google, reverting to default co-ordinates', error);
                            observer.next({
                                longitude: MstiAgmService.DEFAULT_LONGITUDE,
                                latitude: MstiAgmService.DEFAULT_LATITUDE
                            });
                        });
                    } else {
                        observer.next({
                            longitude: MstiAgmService.DEFAULT_LONGITUDE,
                            latitude: MstiAgmService.DEFAULT_LATITUDE
                        });
                    }
                });
            }


        });
    }

    getLightStoneAddress(latitude: number, longitude: number) {
        return this.mstiService.get(this.urlService.getBffLocationUrl() + this.getCoOrdinates(latitude, longitude));
    }

    private getCoOrdinates(latitude: number, longitude: number) {
        return 'longitude=' + longitude + '&latitude=' + latitude;
    }

    getGoogleAddress(latitude: number, longitude: number) {
        return this.geocodeCoOrdinates(latitude, longitude);
    }

    private geocodeCoOrdinates(latitude: number, longitude: number): Observable<any> {
        Logger.log(Level.LOG, 'Begin geocoding');
        return new Observable(observer => {
            this.ngZone.runOutsideAngular(() => {
                const latLng = new google.maps.LatLng(latitude, longitude);
                this.geocoder.geocode({'latLng': latLng}, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        Logger.log(Level.LOG, 'Geocoding complete');
                        observer.next({
                            formattedAddress: results[0].formatted_address
                        });
                    } else {
                        Logger.log(Level.ERROR, 'Error - ', results, ' & Status - ', status);
                        observer.next({});
                    }
                    observer.complete();
                });
            });
        });
    }

}
