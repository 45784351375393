import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {AddressTypeModel} from '../../../../../msti-agm/model/address-type.model';
import {AddressDynamicFormControlComponent} from './address-dynamic-control.component';

export class AddressDynamicControlModel extends BaseDynamicControlModel<AddressTypeModel> {

    constructor(controlModel?: Partial<AddressDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return AddressDynamicFormControlComponent;
    }

}
