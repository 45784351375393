import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {TitleDynamicFormControlComponent} from './title-dynamic-form-control.component';

export class TitleDynamicControlModel extends BaseDynamicControlModel<string> {

    style: string;
    pullRight: boolean;
    center: boolean;

    constructor(controlModel?: Partial<TitleDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return TitleDynamicFormControlComponent;
    }

}
