import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PersonalDetailModel} from '../model/personal-detail.model';
import {BrokerDetailsService} from '../../shared/broker-common/broker-details.service';
import {LookupService} from '../../shared/lookup/lookup.service';
import {SaveClientModel} from '../model/SaveClientModels/save-client.model';
import {CifAddressInfoModel} from '../model/SaveClientModels/cif-address-info.model';
import {SaveClientResponseModel} from '../model/SaveClientModels/save-client-response.model';
import {PersonalService} from '../service/personal.service';
import {LoaderService} from '../../shared/screen-loader/loader.service';
import {AddressTypeModel} from '../../shared/msti-agm/model/address-type.model';
import {Level} from '../../shared/utilities/logger-level';
import {PersonalDetailsHandlerService} from '../service/personal-details-handler.service';
import {DigitalAnalyticsService} from '../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsPageModel} from '../../shared/digital-analytics/model/digital-analytics-page.model';
import {DigitalAnalyticsConversionModel} from '../../shared/digital-analytics/model/digital-analytics-conversion.model';
import {GoogleAnalyticsService} from '../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../shared/google-analytics/model/google-analytics-action.model';
import {MstiErrorService} from '../../shared/msti-error-handler/msti-error.service';
import {NeedHelpService} from '../../footer/need-help/services/need-help.service';
import {NeedHelpPageTags} from '../../footer/need-help/need-help-page-tags';
import {SmoothScroll} from '../../shared/utilities/smooth-scroll';
import {RoutingService} from '../../shared/services/routing.service';
import {Logger} from '../../shared/utilities/logger';
import {QuoteService} from '../../quote/service/quote.service';
import {UrlService} from '../../shared/services/url.service';
import {MSTIService} from '../../shared/services/msti.service';
import {QuoteUrlService} from '../../quote/service/quote-url.service';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonalComponent implements OnInit {

  @ViewChild('personalForm') personalForm: NgForm;

  copyWorkAddress = false;
  personalContactModel: PersonalDetailModel = new PersonalDetailModel();
  home: AddressTypeModel;
  work: AddressTypeModel;
  disableGender = false;

  constructor(
    private brokerDetailsService: BrokerDetailsService, private lookupService: LookupService,
    private personalService: PersonalService, private loaderService: LoaderService,
    private personalDetailsHandlerService: PersonalDetailsHandlerService,
    private digitalAnalyticsService: DigitalAnalyticsService, private googleAnalyticsService: GoogleAnalyticsService,
    private mstiErrorService: MstiErrorService, private needHelpService: NeedHelpService,
    private routingService: RoutingService, private quoteService: QuoteService,
    private urlService: UrlService, private mstiService: MSTIService, private quoteUrlService: QuoteUrlService, public dialog: MatDialog) {
  }

  ngOnInit() {
    SmoothScroll.smoothScrollToTop();
    this.loaderService.show();
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_PERSONAL_INFORMATION);
    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_PERSONAL_INFO_PAGE_ID, attributes);
    this.home = new AddressTypeModel('HOME');
    this.work = new AddressTypeModel('WORK');
    this.personalContactModel.personal.idDetails.identityType = 'RSA_ID';
    this.personalContactModel.personal.idDetails.identityValue = this.brokerDetailsService.getUser().idNumber;

    if (this.brokerDetailsService.getUser().clientResourceRef) {
      this.getClientDetails(this.brokerDetailsService.getUser().clientResourceRef);
      this.loaderService.hide();
    } else {
      this.prefillFieldsFromVIA();
      this.loaderService.hide();
    }


  }

  private prefillFieldsFromVIA() {
    /*this.personalContactModel.personal.firstName = this.userService.getUser().firstName;
    this.personalContactModel.personal.lastName = this.userService.getUser().lastName;

    this.personalContactModel.contact.email = this.userService.getUser().emailAddress;
    this.personalContactModel.contact.mobileNumber = this.userService.getUser().cellNumber;*/
    /* this.personalContactModel.personal.personIdentity.identityType = 'RSA_ID';
        this.personalContactModel.personal.personIdentity.identityValue = this.userService.getUser().identityValue;*/
  }


  async onSubmit(ngForm: NgForm) {
    Logger.log(Level.LOG_OBJECT, 'user =' + this.brokerDetailsService.getUser());
    Logger.log(Level.LOG_OBJECT, 'user2 =' + this.personalService.getClient());
    if (ngForm.status === 'VALID') {
      this.setAnalytics();
      if (this.personalService.getClient()) {
        this.updateClientDetails(await this.createClientModel());
      } else {
        this.saveClientDetails(await this.createClientModel());
      }
    } else {
      SmoothScroll.smoothScrollToTop('.mat-form-field-invalid');
    }
  }

  private setAnalytics() {
    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFireConversionEventTag(
      DigitalAnalyticsConversionModel.PAGE_CONVERSION_COMPLETE_PERSONAL_INFO,
      DigitalAnalyticsConversionModel.PAGE_CONVERSION_COMPLETE,
      attributes);

    this.googleAnalyticsService.fireGoogleAnalytics(
      GoogleAnalyticsActionModel.ACTION_COMPLETED,
      GoogleAnalyticsEventModel.EVENT_COMPLETE_PERSONAL_INFORMATION
    );

  }

  private async createClientModel() {
    const saveClientModel: SaveClientModel = new SaveClientModel();
    await this.createPersonalInfo(saveClientModel);
    this.personalService.setClientPersonalDetailModel(this.personalContactModel);
    saveClientModel.contact = this.personalContactModel.contact;
    this.createAddressInfo(saveClientModel);
    return saveClientModel;
  }

  private async createPersonalInfo(saveClientModel: SaveClientModel) {
    const personalInfo = this.personalContactModel.personal;
    saveClientModel.personal.personIdentity.identityType = personalInfo.idDetails.identityType;
    saveClientModel.personal.personIdentity.identityValue = personalInfo.idDetails.identityValue;
    personalInfo.titleCode = await this.lookupService.getCodeFromDescription(personalInfo.titleDescription, LookupService.GROUP_TITLE);
    personalInfo.maritalStatusCode =
      await this.lookupService.getCodeFromDescription(personalInfo.maritalStatusDescription, LookupService.GROUP_MARITAL_STATUS);
    personalInfo.occupationCode =
      await this.lookupService.getCodeFromDescription(personalInfo.occupationDescription, LookupService.GROUP_OCCUPATION);
    personalInfo.genderCode =
      await this.lookupService.getCodeFromDescription(personalInfo.genderDescription, LookupService.GROUP_GENDER);
    saveClientModel.personal.genderCode = personalInfo.genderCode;
    saveClientModel.personal.titleCode = personalInfo.titleCode;
    saveClientModel.personal.occupationCode = personalInfo.occupationCode;
    saveClientModel.personal.maritalStatusCode = personalInfo.maritalStatusCode;
    saveClientModel.personal.firstName = personalInfo.firstName.toUpperCase(); // As per MQF-661
    saveClientModel.personal.lastName = personalInfo.lastName.toUpperCase(); // As per MQF-661
  }

  private createAddressInfo(saveClientModel: SaveClientModel) {
    saveClientModel.addressList.push(CifAddressInfoModel.getCifAddressInfoModel(this.home));

    if (this.copyWorkAddress) {
      Object.assign(this.work, this.home);
      this.work.addressType = 'WORK';
    }

    saveClientModel.addressList.push(CifAddressInfoModel.getCifAddressInfoModel(this.work));
  }

  private saveClientDetails(clientModel: SaveClientModel) {
    this.loaderService.show();
    this.personalService.saveClient(clientModel).subscribe((response: any) => {
      Logger.log(Level.LOG, 'Save client response= ' + response);
      if (response) { // No response will come if there is an error
        this.routeAfterSaveOrUpdate(response);
      }
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    });

  }

  private updateClientDetails(clientModel: SaveClientModel) {
    this.loaderService.show();
    const updateClientModel: SaveClientResponseModel = new SaveClientResponseModel();
    updateClientModel.clientResourceRef = this.personalService.getClient().clientResourceRef;
    updateClientModel.clientNumber = this.personalService.getClient().clientNumber; //  PIF needs this as well
    updateClientModel.personal = clientModel.personal;
    updateClientModel.contact = clientModel.contact;
    updateClientModel.addressList = clientModel.addressList;
    Logger.log(Level.LOG, 'on update', updateClientModel);
    this.personalService.updateClient(updateClientModel).subscribe((response: any) => {
      this.routeAfterSaveOrUpdate(response);
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    });
  }

  private routeAfterSaveOrUpdate(response: any) {
    delete response['responseStatus'];
    delete response['reason'];
    this.personalService.clientDetails = Object.assign(new SaveClientResponseModel(), response);
    Logger.log(Level.LOG, ' this.personalService.clientDetails= ' + this.personalService.clientDetails);
    this.brokerDetailsService.getUser().clientResourceRef = this.personalService.clientDetails.clientResourceRef;
    this.brokerDetailsService.getUser().clientNumber = this.personalService.clientDetails.clientNumber;
    this.brokerDetailsService.getUser().firstName = this.personalService.clientDetails.personal.firstName;
    this.brokerDetailsService.getUser().lastName = this.personalService.clientDetails.personal.lastName;
    Logger.log(Level.LOG, 'after update', this.personalService.getClient());
    this.loaderService.hide();
    this.routingService.route('app-disclosure');
  }

  private getClientDetails(clientResourceRef: any) {
    this.loaderService.show();
    this.personalService.getClientDetails(clientResourceRef).subscribe(response => {
      this.personalService.clientDetails = Object.assign(new SaveClientResponseModel(), response);
      this.loaderService.show();
      this.fillFields(this.personalService.clientDetails).then(() => {
        this.loaderService.hide();
      });
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    });
  }

  private async fillFields(client: SaveClientResponseModel) {
    Logger.log(Level.LOG_OBJECT, 'on init', client);
    const personalInfo = client.personal;
    this.personalContactModel.personal.firstName = personalInfo.firstName;
    this.personalContactModel.personal.lastName = personalInfo.lastName;
    this.personalContactModel.personal.titleCode = personalInfo.titleCode;
    this.personalContactModel.contact.email = client.contact.email;
    this.personalContactModel.contact.mobileNumber = client.contact.mobileNumber;
    this.personalContactModel.contact.email = client.contact.email;
    this.personalContactModel.contact.mobileNumber = client.contact.mobileNumber;
    this.checkIfAddressAreSame(client.addressList);
    this.personalContactModel.personal.titleDescription =
      await this.lookupService.getDescriptionFromCode(personalInfo.titleCode, LookupService.GROUP_TITLE);
    this.personalContactModel.personal.maritalStatusCode = personalInfo.maritalStatusCode;
    this.personalContactModel.personal.maritalStatusDescription =
      await this.lookupService.getDescriptionFromCode(personalInfo.maritalStatusCode, LookupService.GROUP_MARITAL_STATUS);
    this.personalContactModel.personal.occupationCode = personalInfo.occupationCode;
    this.personalContactModel.personal.occupationDescription =
      await this.lookupService.getDescriptionFromCode(personalInfo.occupationCode, LookupService.GROUP_OCCUPATION);
    this.personalContactModel.personal.genderCode = personalInfo.genderCode;
    this.personalContactModel.personal.genderDescription =
      await this.lookupService.getDescriptionFromCode(personalInfo.genderCode, LookupService.GROUP_GENDER);
    this.disableGenderSelect(this.personalContactModel.personal.titleDescription);

    this.personalContactModel.personal.idDetails.identityType = client.personal.personIdentity.identityType;
    this.personalContactModel.personal.idDetails.identityValue = client.personal.personIdentity.identityValue;
  }

  private disableGenderSelect(titleDesc: string) {
    const gender = this.personalDetailsHandlerService.getGenderFromTitle(titleDesc);
    if (gender === this.personalDetailsHandlerService.UNSPECIFIC_GENDER) {
      this.disableGender = false;
    } else {
      this.disableGender = true;
    }
  }

  checkIfAddressAreSame(addresses: CifAddressInfoModel[]) {
    for (let i = 0, len = addresses.length; i < len; i++) {
      if (addresses[i].addressType === 'HOME') {
        this.home = Object.assign(new AddressTypeModel('HOME'), addresses[i].addressDetails);
        this.home.formatAddressLine();
      } else if (addresses[i].addressType === 'WORK') {
        this.work = Object.assign(new AddressTypeModel('WORK'), addresses[i].addressDetails);
        this.work.formatAddressLine();
      }
    }
    console.log('home', this.home.formattedAddressLine);
    console.log('work', this.work.formattedAddressLine);
    if (this.home.formattedAddressLine === this.work.formattedAddressLine) {
      this.copyWorkAddress = true;
    }
  }


}
