import {DefaultLookupModel} from '../../shared/lookup/default-lookup.model';
import {PreviousClaimsModel} from './previous-claims.model';

export class DisclosureQuestionsModel {
    public recordKey: string;
    public currentlyInsured: string; // Q1
    public hasPreviousClaim: string; // Q5
    public currentInsurerInfo: DefaultLookupModel; // Q2
    public uninsuredPeriodInfo: DefaultLookupModel; // Q3
    public cancellationReasonInfo: DefaultLookupModel; // Q6
    public insuredPeriodInfo: DefaultLookupModel; // Q4
    public previousClaims: PreviousClaimsModel[] = [];
}
