import {Injectable} from '@angular/core';

@Injectable()
export class SessionStorageService {

    public static readonly KEY_IS_LOGOUT = 'KEY_IS_LOGOUT';
    public static readonly VALUE_TRUE = 'true';
    public static readonly USER_STATUS = 'userStatus';
    public clearSessionStorage() {
        sessionStorage.clear();
    }

    public setItem(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }

    public removeItem(key: string) {
        sessionStorage.removeItem(key);
    }

    public getItem(key: string): string {
        return sessionStorage.getItem(key);
    }

}
