export class PremiumConfig {
    // Premium General config
    public static readonly HEADING = '';
    public static readonly SUB_HEADING = 'Based on the information you provided, we have tailored your client\'s insurance premium to meet their needs. Review the information below and if you want to edit, you can do so by clicking the back button at the bottom of the screen.';
    public static readonly EMPTY_DISPLAY_VALUE = ' ';
    public static readonly EMPTY_STRING = '';
    public static readonly VEHICLE_COVER_TYPE_CODE = '1001';
    public static readonly ALL_RISK_COVER_TYPE_CODE = '5002';

    // Cover Type Title config
    public static readonly COVER_TYPE_TITLE = {
        VEHICLE: 'Car Insurance',
        CONTENTS: 'Home Contents Insurance',
        BUILDING: 'Building Insurance',
        ALL_RISK: 'Personal Belongings Insurance'
    };

    // Premium Benefit Description config
    public static readonly PREMIUM_BENEFIT_DESCRIPTION_CONFIG = {
        configCoverType1: 'Momentum Assist',
        configCoverType2: 'Multiply Starter',
        configCoverType3: 'Safe Dayz'
    };

    // Premium Benefit Tag line config
    public static readonly PREMIUM_BENEFIT_TAGLINE_CONFIG = {
        configCoverType1: 'We provide you with emergency roadside and home assistance. You also get emergency legal and medical assistance at no additional cost.',
        configCoverType2: 'As a Momentum client you automatically qualify for Multiply Starter, giving you access to rewards from our retail partners.',
        configCoverType3: 'Every year, you can get up to 30% cash back on your car and home insurance premiums, every year, even if you claim, as part of your Multiply benefit.'
    };

    // Premium Benefit Icon config
    public static readonly PREMIUM_BENEFIT_ICON_URL_CONFIG = {
        configCoverType1: 'PREMIUM-MOMENTUM-ASSIST',
        configCoverType2: 'PREMIUM-MULTIPLY-STARTER',
        configCoverType3: 'PREMIUM-SAFE-DAYZ'
    };


    // Premium Benefit Active status config
    public static readonly PREMIUM_BENEFIT_ACTIVE_STATUS_CONFIG = {
        configCoverType1: true,
        configCoverType2: true,
        configCoverType3: true
    };

    // Premium Benefit Order config
    public static readonly PREMIUM_BENEFIT_ORDER_CONFIG = {
        configCoverType1: 1,
        configCoverType2: 2,
        configCoverType3: 3
    };

    // Premium Benefit metadata config
    public static readonly PREMIUM_BENEFIT_METADATA_CONFIG = {
        configCoverType1: {},
        configCoverType2: {},
        configCoverType3: {}
    };

}
