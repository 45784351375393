import {BaseRiskComponent} from '../../base/component/base-risk.component';
import {Component, Injector} from '@angular/core';
import {BaseRiskService} from '../../base/service/base-risk.service';
import {BuildingService} from '../service/building.service';
import {BuildingConfig} from '../building.config';
import {BuildingDetailsModel} from '../model/building-details.model';
import {BuildingStructureDetailsModel} from '../model/building-structure-details.model';
import {RiskOptionService} from '../../../../shared/risk/risk-option.service';

@Component({
    selector: 'app-building',
    templateUrl: './building.component.html',
    styleUrls: ['./building.component.scss']
})
export class BuildingComponent extends BaseRiskComponent {

    constructor(
        injector: Injector,
        public buildingService: BuildingService,
        public riskOptionService: RiskOptionService
    ) {
        super(injector);
    }

    getRiskService(): BaseRiskService {
        return this.buildingService;
    }

    getRiskConfig() {
        return BuildingConfig;
    }

    getComponentModelConfig(cardId: string) {
        return {
            'configTab1': this.getBuildingDetailsModel(cardId),
            'configTab2': this.getStructureDetailsModel(cardId)
        };
    }

    private getBuildingDetailsModel(cardId: string): BuildingDetailsModel {
        return this.buildingService.getBuildingDetailsModel(cardId);
    }

    private getStructureDetailsModel(cardId: string): BuildingStructureDetailsModel {
        return this.buildingService.getStructureDetailsModel(cardId);
    }

}
