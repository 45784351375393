import {Component, Injector} from '@angular/core';
import {BaseRiskTabComponent} from '../../../base/component/base-risk-tab.component';
import {BaseRiskTabService} from '../../../base/service/base-risk-tab.service';
import {PersonalBelongingsDetailsService} from '../../service/personal-belongings-details.service';
import {GoogleAnalyticsEventModel} from '../../../../../shared/google-analytics/model/google-analytics-event.model';

@Component({
    selector: 'app-personal-belongings-details',
    templateUrl: './personal-belongings-details.component.html',
    styleUrls: [],
    providers: [PersonalBelongingsDetailsService]
})
export class PersonalBelongingsDetailsComponent extends BaseRiskTabComponent {

    constructor(
        injector: Injector,
        private personalBelongingsDetailsService: PersonalBelongingsDetailsService
    ) {
        super(injector);
    }

    getRiskTabService(): BaseRiskTabService {
        return this.personalBelongingsDetailsService;
    }

    initAnalytics(): void {
    }

    doComponentInit(): void {
        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('selectedPersonalBelongingsOption').valueChanges.subscribe(
                selectedValue => {
                    this.personalBelongingsDetailsService.selectedPersonalBelongingsCoverOption(selectedValue);
                    this.personalBelongingsDetailsService.setGoogleAnalyticsForPersonalBelongingsCoverOption(selectedValue);
                }
            ));
    }

    propagatePostLoadUpdates(): void {
        // TODO - Implement me
    }

    getGoogleAnalyticsEventLabel(): string {
        return GoogleAnalyticsEventModel.EVENT_COMPLETED_PERSONAL_BELONGINGS_COVER;
    }

}
