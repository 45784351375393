import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {CardModule} from '../../card/card.module';
import {BuildingComponent} from './component/building.component';
import {BuildingService} from './service/building.service';
import {BuildingUrlService} from './service/building-url.service';
import {BuildingDetailsComponent} from './component/building-details/building-details.component';
import {StructureDetailsComponent} from './component/structure-details/structure-details.component';

@NgModule({
    declarations: [
        BuildingComponent,
        BuildingDetailsComponent,
        StructureDetailsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        CardModule
    ],
    exports: [BuildingComponent],
    providers: [BuildingService, BuildingUrlService],
    entryComponents: [BuildingDetailsComponent, StructureDetailsComponent]
})
export class BuildingModule {
}
