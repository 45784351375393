import {Injectable} from '@angular/core';
import {UrlService} from '../../../../shared/services/url.service';
import {MSTIConfiguration} from '../../../../shared/msti.config';

@Injectable()
export class PersonalBelongingsUrlService extends UrlService {

  constructor() {
    super();
  }

  public getSaveOrUpdateMovablesURL(quoteId: string, contentId: string): string {
    // return `${this.baseUrlPath()}/quotes/${quoteId}/contents/${contentId}/movables`;
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/contents/${contentId}/allRisks`;
  }

  public getPersonalBelongingsListURL(quoteId: string, contentId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/contents/${contentId}/allRisks`;
  }

  public getPersonalBelongingsRiskURL(quoteId: string, contentId: string, riskId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/contents/${contentId}/allRisks/${riskId}`;
  }

  public getUpdateSpecifiedMovablesURL(quoteId: string, contentId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/contents/${contentId}/allRisks`;
  }

  public getDeleteMovablesURL(quoteId: string, contentId: string, riskId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/contents/${contentId}/allRisks/${riskId}`;
  }

  private baseUrlPath(): string {
    // return `${MSTIConfiguration.constants.urlPrefix}${this.FULFILLMENT_API_V2}${this.partyKey()}`;
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}`;
  }

}
