import {FormControl} from '@angular/forms';

export function emailValidator(control: FormControl) {
    const emailAddress = control.value;
    if (emailAddress) {
        const pattern =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!pattern.test(emailAddress)) {
            return {
                validateObj: {
                    valid: false,
                    msg: 'Email must be a valid email address'
                }
            };
        } else {
            return null;
        }
    } else {
        return {
            validateObj: {
                valid: false,
                msg: 'Email is required'
            }
        };
    }
}




