import {Component, Injector} from '@angular/core';
import {BaseRiskTabComponent} from '../../../base/component/base-risk-tab.component';
import {BaseRiskTabService} from '../../../base/service/base-risk-tab.service';
import {ContentsDetailsService} from '../../service/contents-details.service';
import {AddressLookupModel} from '../../../../../shared/lookup/address-lookup.model';
import {ContentsService} from '../../service/contents.service';
import {AddressTypeModel} from '../../../../../shared/msti-agm/model/address-type.model';
import {GoogleAnalyticsEventModel} from '../../../../../shared/google-analytics/model/google-analytics-event.model';

@Component({
    selector: 'app-contents-details',
    templateUrl: './contents-details.component.html',
    styleUrls: [],
    providers: [ContentsDetailsService]
})
export class ContentsDetailsComponent extends BaseRiskTabComponent {

    constructor(
        injector: Injector,
        private contentsDetailsService: ContentsDetailsService
    ) {
        super(injector);
    }

    getRiskTabService(): BaseRiskTabService {
        return this.contentsDetailsService;
    }

    initAnalytics(): void {
        // TODO - Implement me
    }

    doComponentInit(): void {
        // TODO - Confirm, would it be necessary to move this card id initialisation to a base class for all risk services to benefit from?
        this.contentsDetailsService.parentCardId = this.parentCardId;

        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('contentsAddressSameAsPhysical').valueChanges.subscribe(
                selectedValue => {
                    const isAddressSameAsPhysical = !this.contentsDetailsService.isRadioOptionNo(selectedValue);
                    this.processAddressSameAsPhysical(isAddressSameAsPhysical);
                }
            )
        );



      this.subscriptions.push(
        this.cardTabContainerComponent.dynamicFormComponent.form.get('contentsAddress').valueChanges.subscribe(
          selectedValue => {
            if (selectedValue) {
              this.contentsDetailsService.setUniqueAddressForCard(selectedValue);
              this.displayDropdownOrAddressPicker();
              this.updateCardHeading(selectedValue);
            }
          }
        )
      );

        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('addressSelection').valueChanges.subscribe(
                selectedValue => {
                    this.processAddressSelection(selectedValue);
                    this.updateCardHeading(selectedValue.value);
                }
            )
        );
    }

    private processAddressSameAsPhysical(isAddressSameAsPhysical: boolean): void {
        if (isAddressSameAsPhysical === true) {
            this.contentsDetailsService.clearAddressForCard();
            this.contentsDetailsService.displayAddressSelectControl(false);
            this.contentsDetailsService.displayAddressControl(false);
            this.updateCardHeading(this.contentsDetailsService.getClientPhysicalAddress());
        } else {
          this.displayDropdownOrAddressPicker();
        }
    }

    private processAddressSelection(selectedAddress: AddressLookupModel): void {
        if (selectedAddress && selectedAddress.getDescription() === ContentsService.VALUE_ADD_NEW_ADDRESS) {
            this.contentsDetailsService.displayAddressControl(true);
        } else {
            this.contentsDetailsService.displayAddressControl(false);
        }
    }

    private updateCardHeading(selectedAddress: AddressTypeModel) {
        if (selectedAddress) {
            this.cardHeadingUpdateEventEmitter.emit(selectedAddress.formattedAddressLine);
        }
    }

    propagatePostLoadUpdates(): void {
        // TODO - Implement me
    }

    getGoogleAnalyticsEventLabel(): string {
        return GoogleAnalyticsEventModel.EVENT_COMPLETE_STRUCTURE_DETAILS_ON_HOME_CONTENTS_INSURANCE;
    }

   displayDropdownOrAddressPicker() {
    if (this.contentsDetailsService.getStoredAddresses().length > 0) {
      this.contentsDetailsService.displayAddressSelectControl(true);
      this.contentsDetailsService.displayAddressControl(false);
    } else {
      this.contentsDetailsService.displayAddressSelectControl(false);
      this.contentsDetailsService.displayAddressControl(true);
    }
  }

}
