import {BaseRiskComponent} from '../../base/component/base-risk.component';
import {Component, Injector} from '@angular/core';
import {BaseRiskService} from '../../base/service/base-risk.service';
import {ContentsService} from '../service/contents.service';
import {ContentsConfig} from '../contents.config';
import {ContentsDetailsModel} from '../model/contents-details.model';
import {ContentsStructureDetailsModel} from '../model/contents-structure-details.model';
import {RiskOptionService} from '../../../../shared/risk/risk-option.service';
import {PersonalBelongingsModel} from '../../personal-belongings/model/personal-belongings.model';
import {PersonalBelongingsService} from '../../personal-belongings/service/personal-belongings.service';

@Component({
    selector: 'app-contents',
    templateUrl: './contents.component.html',
    styleUrls: ['./contents.component.scss']
})
export class ContentsComponent extends BaseRiskComponent {

    constructor(
        injector: Injector,
        public contentsService: ContentsService,
        public riskOptionService: RiskOptionService,
        public personalBelongingsService: PersonalBelongingsService
    ) {
        super(injector);
    }

    getRiskService(): BaseRiskService {
        return this.contentsService;
    }

    getRiskConfig() {
        return ContentsConfig;
    }

    getComponentModelConfig(cardId: string) {
        return {
            'configTab1': this.getContentsDetailsModel(cardId),
            'configTab2': this.getStructureDetailsModel(cardId),
            'configTab3': this.getPersonalBelongingsModel(cardId) // TODO - Make this third  tab dynamic
        };
    }

    private getContentsDetailsModel(cardId: string): ContentsDetailsModel {
        return this.contentsService.getContentsDetailsModel(cardId);
    }

    private getStructureDetailsModel(cardId: string): ContentsStructureDetailsModel {
        return this.contentsService.getStructureDetailsModel(cardId);
    }

    private getPersonalBelongingsModel(cardId: string): PersonalBelongingsModel {
        return this.personalBelongingsService.getPersonalBelongingsModel(cardId);
    }

}
