import {Component, Injector} from '@angular/core';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';
import {ButtonDynamicControlModel} from './button-dynamic-control.model';

@Component({
    selector: 'app-button-dynamic-form-control',
    templateUrl: './button-dynamic-form-control.component.html',
    styleUrls: ['./button-dynamic-control.component.scss']

})
export class ButtonDynamicFormControlComponent extends BaseDynamicFormControl<string> {

    public static readonly STYLE_LINK = 'link';

    classes: string;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    doInit() {
        if ((<ButtonDynamicControlModel>this.dynamicControlModel).style === ButtonDynamicFormControlComponent.STYLE_LINK) {
            this.classes = 'btn-as-link';
        }
    }

    updateFormControlValue(inputValue: any) {
        // TODO - Implement me
    }

    clearValue() {
        // Not applicable for this control type
    }

    onButtonClick() {
        if ((<ButtonDynamicControlModel>this.dynamicControlModel).onClickSubject) {
            (<ButtonDynamicControlModel>this.dynamicControlModel).onClickSubject.next();
        }
    }

}
