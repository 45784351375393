import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BaseDynamicControlModel} from '../base-dynamic-control.model';

@Injectable()
export class DynamicFormControlService {

    constructor() { }

    toFormGroup(dynamicFormControls: BaseDynamicControlModel<any>[]) {
        const group: any = {};

        dynamicFormControls.forEach(cardFormControl => {
            group[cardFormControl.key] = new FormControl(cardFormControl.value || '', cardFormControl.validation);
        });

        return new FormGroup(group);
    }

}
