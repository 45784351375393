import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {DisclosureRoutingModule} from './disclosure-routing.module';
import {DisclosureService} from './services/disclosure.service';
import {FormsModule} from '@angular/forms';
import {DisclosureUrlService} from './services/disclosure-url.service';
import {DisclosureThankyouComponent} from './disclosure-thankyou/disclosure-thankyou.component';
import {DisclosureEntryComponent} from './disclosure-entry.component';
import {DisclosureQuestionsComponent} from './disclosure-questions/disclosure-questions.component';
import {DisclosureItcDialogComponent} from './disclosure-itc/disclosure-itc-dialog/disclosure-itc-dialog.component';
import {DisclosureItcComponent} from './disclosure-itc/disclosure-itc.component';
import {DisclosureCaptureClaimComponent} from './previous-claims/disclosure-capture-claim.component';
import {ClaimDetailsComponent} from './previous-claims/claim-details/claim-details.component';


@NgModule({

  declarations: [DisclosureEntryComponent, DisclosureThankyouComponent, DisclosureQuestionsComponent, DisclosureItcComponent, DisclosureItcDialogComponent, DisclosureCaptureClaimComponent,
    ClaimDetailsComponent],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    DisclosureRoutingModule,
  ],
  entryComponents: [DisclosureItcDialogComponent, ClaimDetailsComponent],
  exports: [],
  providers: [DisclosureService, DisclosureUrlService]
})
export class DisclosureModule {
}
