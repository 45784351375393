import {PersonalService} from '../../service/personal.service';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {ImageService} from '../../../shared/services/image.service';
import {MSTIParentComponent} from '../../../shared/msti-parent';
import {PersonalDetailModel} from '../../model/personal-detail.model';
import {LoaderService} from '../../../shared/screen-loader/loader.service';
import {LookupFilteredDropDownModel} from '../../../shared/lookup/lookup.filtered.drop.down.model';
import {MatDialog} from '@angular/material';
import {MSTITooltipComponent} from '../../../shared/pop-up-dialogs/msti-tooltip/msti-tooltip.component';
import {PersonalDetailsHandlerService} from '../../service/personal-details-handler.service';
import {DigitalAnalyticsService} from '../../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsElementModel} from '../../../shared/digital-analytics/model/digital-analytics-element.model';
import {GoogleAnalyticsService} from '../../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../../shared/google-analytics/model/google-analytics-action.model';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonalDetailsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PersonalDetailsComponent),
      multi: true
    }
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class PersonalDetailsComponent extends MSTIParentComponent implements OnInit, ControlValueAccessor {

  @Input() personalContact: PersonalDetailModel;
  @Input() disableGender: boolean;

  singleSelectOptionsGenders: LookupFilteredDropDownModel;
  singleSelectOptionsTitles: LookupFilteredDropDownModel;
  singleSelectOptionsOccupations: LookupFilteredDropDownModel;
  singleSelectOptionsMaritalStatus: LookupFilteredDropDownModel;
  infoIcon: any;
  isTitleValid = true;
  isMaritalStatusValid = true;
  isOccupationValid = true;
  disableCellNumber = false;

  constructor(
    imageService: ImageService, private personalService: PersonalService, private loaderService: LoaderService,
    public dialog: MatDialog, private personalDetailsHandler: PersonalDetailsHandlerService,
    private digitalAnalyticsService: DigitalAnalyticsService, private googleAnalyticsService: GoogleAnalyticsService
  ) {
    super();
    this.initializeLookUpData();
    this.initializeIcons(imageService);

  }


  private initializeLookUpData() {
    this.singleSelectOptionsGenders = new LookupFilteredDropDownModel([]);
    this.singleSelectOptionsTitles = new LookupFilteredDropDownModel([]);
    this.singleSelectOptionsOccupations = new LookupFilteredDropDownModel([]);
    this.singleSelectOptionsMaritalStatus = new LookupFilteredDropDownModel([]);
  }

  private initializeIcons(imageService: ImageService) {
    this.infoIcon = imageService.getImage('INFO-ICON');
  }

  ngOnInit() {
    this.loaderService.show();
    this.personalService.getOccupations().then((data) => {
      this.singleSelectOptionsOccupations.setValues(data);
    }).then(() => this.loaderService.hide());
    this.loaderService.show();
    this.personalService.getTitles().then((data) => {
      this.singleSelectOptionsTitles.setValues(data);
    }).then(() => this.loaderService.hide());

    this.loaderService.show();
    this.personalService.getGenders().then((data) => {
      this.singleSelectOptionsGenders.setValues(data);
    }).then(() => this.loaderService.hide());

    this.loaderService.show();
    this.personalService.getMaritalStatuses().then((data) => {
      this.singleSelectOptionsMaritalStatus.setValues(data);
    }).then(() => this.loaderService.hide());
  }

  isFieldValid(field: any) {
    if (field && field.errors) {
      if ((this.isParentFormSubmitted || field.touched) && field.errors.validateObj) {
        return true;
      } else {
        return false;
      }
    }
  }

  isTitleValidCheck(input, title: any) {
    this.setGenderBasedOnSelection(title);
    this.isTitleValid = this.personalDetailsHandler.validateInput(title, this.singleSelectOptionsTitles, input);
    if (!this.isTitleValid) {
      this.personalContact.personal.titleDescription = null;
    }
  }

  private setGenderBasedOnSelection(title: any) {
    if (!this.personalDetailsHandler.isNullOrUndefined(title)) {
      const capitalizedTitle = this.personalDetailsHandler.capitalize(title);
      this.preSelectGender(this.personalDetailsHandler.getGenderFromTitle(capitalizedTitle));
    } else {
      this.disableGender = false;
    }
  }

  isMaritalValidCheck(input, maritalStatus: any) {
    this.isMaritalStatusValid = this.personalDetailsHandler.validateInput(maritalStatus, this.singleSelectOptionsMaritalStatus, input);
    if (!this.isMaritalStatusValid) {
      this.personalContact.personal.maritalStatusDescription = null;
    }
  }

  isOccupationValidCheck(input, occupation: any) {
    this.isOccupationValid = this.personalDetailsHandler.validateInput(occupation, this.singleSelectOptionsOccupations, input);
    if (!this.isOccupationValid) {
      this.personalContact.personal.occupationDescription = null;
    }
  }

  onValueChange($event: any) {
    this.propagateChange(this.personalContact);
  }

  writeValue(personalDetailModel: PersonalDetailModel) {
    if (personalDetailModel) {
      this.personalContact = personalDetailModel;
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    // We are not handling touch input events in this component
  }

  openToolTipDialog() {
    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFireElementTag(DigitalAnalyticsElementModel.ELEMENT_PERSONAL_INFO_EMAIL_ADDRESS_INFO,
      attributes);

    this.googleAnalyticsService.fireGoogleAnalytics(
      GoogleAnalyticsActionModel.ACTION_YES,
      GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_EMAIL_ADDRESS
    );

    return this.dialog.open(MSTITooltipComponent, {
      width: '52%',
      data: {message: 'We will use this as the default method of communication.', title: ''},
      panelClass: 'msti-dialog'
    });
  }

  private propagateChange = (_: any) => {
  }

  preSelectGender(gender: string) {
    if (gender === this.personalDetailsHandler.UNSPECIFIC_GENDER) {
      this.disableGender = false;
    } else {
      this.personalContact.personal.genderDescription = gender;
      this.disableGender = true;
    }
  }

  clearTitleField($event) {
    this.personalDetailsHandler.clearField($event);
    this.personalService.getTitles().then((data) => {
      this.singleSelectOptionsTitles.setValues(data);
    }).then(() => this.loaderService.hide());
  }

  clearOccupationField($event) {
    this.personalDetailsHandler.clearField($event);
    this.personalService.getOccupations().then((data) => {
      this.singleSelectOptionsOccupations.setValues(data);
    }).then(() => this.loaderService.hide());
  }

  clearMaritalStatusField($event) {
    this.personalDetailsHandler.clearField($event);
    this.personalService.getMaritalStatuses().then((data) => {
      this.singleSelectOptionsMaritalStatus.setValues(data);
    }).then(() => this.loaderService.hide());
  }

}
