import {EventEmitter, Type} from '@angular/core';

export class CardTabModel {
    constructor(
        public component: Type<any>,
        public title: string,
        public subTitle: string,
        public isActive: boolean,
        public componentModel: object,
        public metaData: any,
        public menuItems: string[],
        public tabLoadedEventEmitter: EventEmitter<any>
    ) {}
}
