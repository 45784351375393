import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ImageService} from '../../services/image.service';

@Component({
    selector: 'app-msti-tooltip',
    templateUrl: './msti-tooltip.component.html',
    styleUrls: ['./msti-tooltip.component.scss']
})
export class MSTITooltipComponent implements OnInit {
    closeIcon: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private imageService: ImageService) {
        this.closeIcon = imageService.getImage('CLOSE-ICON');
    }

    ngOnInit() {
    }
}
