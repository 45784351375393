import {Injectable, ViewContainerRef} from '@angular/core';

@Injectable()
export class DynamicComponentService {

    createListeners: any[] = [];
    destroyListeners: any[] = [];

    onContainerCreated(fn) {
        this.createListeners.push(fn);
    }

    onContainerDestroyed(fn) {
        this.destroyListeners.push(fn);
    }

    registerContainer(id: string, container: ViewContainerRef) {
        this.createListeners.forEach((fn) => {
            fn(id, container);
        });
    }

    destroyContainer(container) {
        this.destroyListeners.forEach((fn) => {
            fn(container);
        });
    }

}
