import {AddressTypeModel} from '../../../shared/msti-agm/model/address-type.model';
import {AddressDetailsModel} from './address-details.model';

export class CifAddressInfoModel {

  public addressType: string; // HOME, WORK, POSTAL, DAYTIME, NIGHTTIME, ROAMING
  public addressDetails: AddressDetailsModel = new AddressDetailsModel();


  static getCifAddressInfoModel(addressTypeModel: AddressTypeModel): CifAddressInfoModel {
    const cifAddressInfoModel: CifAddressInfoModel = new CifAddressInfoModel();
    cifAddressInfoModel.addressType = addressTypeModel.addressType;
    cifAddressInfoModel.addressDetails.unitNo = addressTypeModel.unitNumber;
    cifAddressInfoModel.addressDetails.sectSchemeName = addressTypeModel.sectionSchemeName;
    cifAddressInfoModel.addressDetails.estateCode = addressTypeModel.estateCode;
    cifAddressInfoModel.addressDetails.estateName = addressTypeModel.estateName;
    cifAddressInfoModel.addressDetails.streetCode = addressTypeModel.streetCode;
    cifAddressInfoModel.addressDetails.streetTypeCode = addressTypeModel.streetTypeCode;
    cifAddressInfoModel.addressDetails.streetTypeDesc = addressTypeModel.streetTypeDesc;
    cifAddressInfoModel.addressDetails.streetDesc = addressTypeModel.streetDesc;
    cifAddressInfoModel.addressDetails.streetNo = addressTypeModel.streetNo;
    cifAddressInfoModel.addressDetails.suburbCode = addressTypeModel.suburbCode;
    cifAddressInfoModel.addressDetails.suburbDesc = addressTypeModel.suburbDesc;
    cifAddressInfoModel.addressDetails.municipalityCode = addressTypeModel.municipalityCode;
    cifAddressInfoModel.addressDetails.municipalityName = addressTypeModel.municipalityName;
    cifAddressInfoModel.addressDetails.provinceCode = addressTypeModel.provinceCode;
    cifAddressInfoModel.addressDetails.provinceDesc = addressTypeModel.provinceDesc;
    cifAddressInfoModel.addressDetails.postalCode = addressTypeModel.postalCode;
    cifAddressInfoModel.addressDetails.poBoxNo = addressTypeModel.poBoxNo;
    return cifAddressInfoModel;
  }

  public toAddressTypeModel(): AddressTypeModel {
    const addressTypeModel = Object.assign(new AddressTypeModel(this.addressType), this);
    addressTypeModel.formatAddressLine();
    return addressTypeModel;
  }

}
