import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {AddressTypeModel} from '../../../../shared/msti-agm/model/address-type.model';
import {AddressDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/address/address-dynamic-control.model';
import {AutocompleteDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/autocomplete/autocomplete-dynamic-control.model';
import {BaseDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/base-dynamic-control.model';
import {CurrencyDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/currency/currency-dynamic-control.model';
import {RadioButtonYesNoDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/radiobutton-yesno/radiobutton-yesno-dynamic-control.model';
import {LookupFilteredDropDownModel} from '../../../../shared/lookup/lookup.filtered.drop.down.model';
import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {BaseRiskTabService} from '../../base/service/base-risk-tab.service';
import {BuildingService} from './building.service';
import {LoaderService} from '../../../../shared/screen-loader/loader.service';
import {AppMangerService} from '../../../../mstiappmanager/service/app_manager.service';

@Injectable()
export class BuildingDetailsService extends BaseRiskTabService {

    private ownershipTypes: LookupFilteredDropDownModel;
    private areaTypes: LookupFilteredDropDownModel;
    private neighbourhoodSetups: LookupFilteredDropDownModel;
    private propertyOccupancies: LookupFilteredDropDownModel;
    private propertyOccupantTypes: LookupFilteredDropDownModel;

    private hideAddressSelection: BehaviorSubject<boolean>;
    private hideAddressControl: BehaviorSubject<boolean>;
    private availableAddresses: Subject<LookupModel[]>;

    public parentCardId: string;

    constructor(
        private loaderService: LoaderService, private appManagerService: AppMangerService,
        private buildingService: BuildingService
    ) {
        super();
        this.ownershipTypes = new LookupFilteredDropDownModel([]);
        this.areaTypes = new LookupFilteredDropDownModel([]);
        this.neighbourhoodSetups = new LookupFilteredDropDownModel([]);
        this.propertyOccupancies = new LookupFilteredDropDownModel([]);
        this.propertyOccupantTypes = new LookupFilteredDropDownModel([]);
        this.hideAddressControl = new BehaviorSubject<boolean>(true);
        this.hideAddressSelection = new BehaviorSubject<boolean>(true);
        this.availableAddresses = new Subject<LookupModel[]>();
    }

    init(): void {
        this.loaderService.show();

        this.buildingService.getOwnershipTypes().then(data => {
            this.ownershipTypes.setValues(data);
        }).then(() => this.loaderService.hide());

        this.buildingService.getAreaTypes().then(data => {
            this.areaTypes.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.buildingService.getNeighbourSetups().then(data => {
            this.neighbourhoodSetups.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.buildingService.getOccupancies().then(data => {
            this.propertyOccupancies.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.buildingService.getOccupantTypes().then(data => {
            this.propertyOccupantTypes.setValues(data);
        }).then(() => this.loaderService.hide());
    }

    public getStoredAddresses(): AddressTypeModel[] {
        return this.buildingService.getAddresses();
    }

    public displayAddressSelectControl(displayAddressSelectControl: boolean) {
        this.hideAddressSelection.next(!displayAddressSelectControl);

        if (displayAddressSelectControl === true) {
            this.availableAddresses.next(this.getAvailableAddresses().filteredValues);
        }
    }

    private getAvailableAddresses() {
        return this.buildingService.getAddressLookups();
    }

    public displayAddressControl(displayAddressControl: boolean) {
        this.hideAddressControl.next(!displayAddressControl);
    }

    public clearAddressForCard() {
        this.buildingService.removeAddress(this.parentCardId);
    }

    public setUniqueAddressForCard(address: AddressTypeModel) {
        this.buildingService.addAddress(this.parentCardId, address);
    }

    public getClientPhysicalAddress(): AddressTypeModel {
        return this.buildingService.getClientHomeAddressDetails().toAddressTypeModel();
    }

    getDynamicFormControls(): BaseDynamicControlModel<any>[] {
        // Get the min max values
        const sectionValidation = this.appManagerService.getValidation('3001');
        let minVal = 23500;
        if (sectionValidation) {
            minVal = sectionValidation.field.minimumValue;
        }
        const dynamicFormControls: BaseDynamicControlModel<any>[] = [
            new CurrencyDynamicControlModel(
                {
                    key: 'buildingAmount',
                    label: 'For what amount would your client like to insure your building?',
                    hint: '',
                    value: 0.00,
                    validation: [Validators.required, Validators.min(minVal)],
                    order: 1
                }
            ),
            new RadioButtonYesNoDynamicControlModel(
                {
                    key: 'buildingAddressSameAsPhysical',
                    label: 'Is the building your client\'s permanent home address?',
                    value: '',
                    validation: [Validators.required],
                    order: 2
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'addressSelection',
                    label: 'Select your address from the list.',
                    hint: '',
                    validation: [Validators.required],
                    order: 3,
                    availableOptionsObservable: this.availableAddresses.asObservable(),
                    hidden: this.hideAddressSelection.asObservable()
                }
            ),
            new AddressDynamicControlModel(
                {
                    key: 'buildingAddress',
                    label: 'Building address',
                    hint: '',
                    value: new AddressTypeModel('HOME'), // TODO - Confirm this
                    validation: [],
                    order: 4,
                    hidden: this.hideAddressControl.asObservable()
                }
            ),
            new RadioButtonYesNoDynamicControlModel(
                {
                    key: 'buildingBurglaries',
                    label: 'Were there any burglaries at this building in the past year?',
                    value: '',
                    validation: [Validators.required],
                    order: 5
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'buildingOwnership',
                    label: 'Does your client own or rent the building?',
                    hint: '',
                    validation: [Validators.required],
                    order: 6,
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.ownershipTypes.filteredValues).asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'buildingAreaType',
                    label: 'Select the area type where the building is located.',
                    hint: '',
                    validation: [Validators.required],
                    order: 7,
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.areaTypes.filteredValues).asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'buildingNeighbourhood',
                    label: 'Select one that best describes your client\'s neighbourhood setup.',
                    hint: '',
                    validation: [Validators.required],
                    order: 8,
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.neighbourhoodSetups.filteredValues).asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'buildingPropertyOccupation',
                    label: 'When is the building occupied most often?',
                    hint: '',
                    validation: [Validators.required],
                    order: 9,
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.propertyOccupancies.filteredValues).asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'buildingPropertyOccupants',
                    label: 'Who occupies the building the most?',
                    hint: '',
                    validation: [Validators.required],
                    order: 10,
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.propertyOccupantTypes.filteredValues).asObservable()
                }
            )
        ];

        return dynamicFormControls.sort((a, b) => a.order - b.order);
    }

}
