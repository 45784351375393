import {CifBuildingStructureModel} from './cif-building-structure.model';
import {BaseCifRiskModel} from '../../../base/model/base-cif-risk.model';
import {SaveClientResponseModel} from '../../../../../personal/model/SaveClientModels/save-client-response.model';
import {PifRiskAddressModel} from '../../../../model/pif/pif-risk-address.model';

export class CifBuildingModel extends BaseCifRiskModel {

  sectionCode: string;
  riskId: string;
  riskResourceRef: string;
  buildingTypeCode: string;
  industryTypeCode: string;
  sumInsured: string;
  riskAddress?: PifRiskAddressModel | null;
  previousBurglaries: boolean;
  neighbourTypeCode: string;
  ownershipTypeCode: string;
  occupancyTypeCode: string;
  occupantTypeCode: string;
  structure: CifBuildingStructureModel;

  constructor(clientModel: SaveClientResponseModel) {
    super();
  }

}
