import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {LoaderService} from './loader.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'msti-loader',
  templateUrl: 'loader.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit, OnDestroy {

  private loaderSubscription: Subscription;
  private loaderWithMessageSubscription: Subscription;
  show = false;
  header = '';
  caption = '';
  headerAvailable = false;

  constructor(
    private loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.loaderWithMessageSubscription = this.loaderService.loaderWithMessageChanged
      .subscribe((dialogInfo: any) => {
        this.show = dialogInfo.show;
        this.headerAvailable = dialogInfo.show;
        if (dialogInfo.popUpTitle || dialogInfo.popUpCaption) { // Custom Loader
          this.header = dialogInfo.popUpTitle;
          this.caption = dialogInfo.popUpCaption;
        }
        this.changeDetectorRef.detectChanges();
      });

    this.loaderSubscription = this.loaderService.loaderChanged.subscribe((value) => {
      this.show = value;
      this.caption = 'Please wait';
      this.changeDetectorRef.detectChanges();
    });

  }

  ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
    this.loaderWithMessageSubscription.unsubscribe();
  }

}
