import {Component, Injector} from '@angular/core';
import {VehicleDescriptionService} from '../../service/vehicle-description.service';
import {LookupModel} from '../../../../../shared/lookup/lookup.model';
import {NeedHelpService} from '../../../../../footer/need-help/services/need-help.service';
import {NeedHelpPageTags} from '../../../../../footer/need-help/need-help-page-tags';
import {NeedHelpDialogService} from '../../../../../footer/need-help/services/need-help-dialog.service';
import {DigitalAnalyticsElementModel} from '../../../../../shared/digital-analytics/model/digital-analytics-element.model';
import {GoogleAnalyticsEventModel} from '../../../../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../../../../shared/google-analytics/model/google-analytics-action.model';
import {BaseRiskTabComponent} from '../../../base/component/base-risk-tab.component';
import {BaseRiskTabService} from '../../../base/service/base-risk-tab.service';
import {DigitalAnalyticsPageModel} from '../../../../../shared/digital-analytics/model/digital-analytics-page.model';

@Component({
    selector: 'app-vehicle-description',
    templateUrl: './vehicle-description.component.html',
    styleUrls: ['./vehicle-description.component.scss'],
    providers: [VehicleDescriptionService]
})
export class VehicleDescriptionComponent extends BaseRiskTabComponent {

    constructor(
        injector: Injector,
        private vehicleDescriptionService: VehicleDescriptionService,
        private needHelpService: NeedHelpService,
        private needHelpDialogService: NeedHelpDialogService
    ) {
        super(injector);
    }

    getRiskTabService(): BaseRiskTabService {
        return this.vehicleDescriptionService;
    }

    initAnalytics(): void {
        this.subscriptions.push(
            this.tabLoadedEventEmitter.subscribe(() => {
                const attributes: Map<number, string> = new Map<number, string>();
                this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_VEHICLE_DESCRIPTION_PAGE_ID,
                    attributes);
            })
        );
    }

    doComponentInit(): void {
        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleCondition').valueChanges.subscribe(
                selectedValue => {
                    this.vehicleDescriptionService.updateVehicleCondition(selectedValue, this.loadingPreviousData);
                }
            )
        );

        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleType').valueChanges.subscribe(selectedValue => {
                if (this.loadingPreviousData === false) {
                    const vehicleYear = this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleYear').value;
                    this.clearMakes();
                    this.clearModels();
                    this.loadMakes(selectedValue, vehicleYear);
                }
            })
        );

        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleYear').valueChanges.subscribe(selectedValue => {
                if (this.loadingPreviousData === false) {
                    const vehicleType = this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleType').value;
                    this.clearMakes();
                    this.clearModels();
                    this.loadMakes(vehicleType, selectedValue);
                    this.clearVehicleColours();
                }
                this.updateCardHeading();
            })
        );

        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleMake').valueChanges.subscribe(selectedValue => {
                if (this.loadingPreviousData === false) {
                    const vehicleType = this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleType').value;
                    const vehicleYear = this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleYear').value;
                    this.clearModels();
                    this.loadModels(vehicleType, vehicleYear, selectedValue);
                }
                this.updateCardHeading();
            })
        );

        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleColour').valueChanges.subscribe(selectedValue => {
                this.updateCardHeading();
            })
        );

        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegistrationNumber').valueChanges.subscribe(
                selectedValue => {
                    this.updateCardHeading();
                }
            )
        );

        this.subscriptions.push(this.vehicleDescriptionService.cannotFindVehicleClickedSubject.subscribe(() => {
            this.cannotFindVehicleClicked();
        }));
    }

    propagatePostLoadUpdates(): void {
        this.vehicleDescriptionService.propagateMakeListUpdate(this.componentModel.makesList);
        this.vehicleDescriptionService.propagateModelListUpdate(this.componentModel.modelsList);
    }

    getGoogleAnalyticsEventLabel(): string {
        return GoogleAnalyticsEventModel.EVENT_COMPLETE_VEHICLE_DESCRIPTION_ON_VEHICLE_COVER;
    }

    private loadMakes(vehicleType: LookupModel, vehicleYear: LookupModel) {
        this.vehicleDescriptionService.loadMakes(vehicleType, vehicleYear);
    }

    private loadModels(vehicleType: LookupModel, vehicleYear: LookupModel, vehicleMake: LookupModel) {
        this.vehicleDescriptionService.loadModels(vehicleType, vehicleYear, vehicleMake);
    }

    private clearMakes() {
        this.vehicleDescriptionService.clearMakes();
    }

    private clearModels() {
        this.vehicleDescriptionService.clearModels();
    }

    private clearVehicleColours() {
        this.vehicleDescriptionService.clearVehicleColours();
    }

    private updateCardHeading() {
        const registrationNumber = this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegistrationNumber').value;
        const vehicleYear = this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleYear').value;
        const vehicleMake = this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleMake').value;
        const vehicleColour = this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleColour').value;

        let cardHeading = registrationNumber ? registrationNumber : '';
        cardHeading += registrationNumber && ((vehicleYear && vehicleYear.getDescription) || (vehicleMake && vehicleMake.getDescription))
            ? ' - ' : '';
        cardHeading += vehicleYear && vehicleYear.getDescription ? vehicleYear.getDescription() : '';
        cardHeading += (vehicleYear && vehicleYear.getDescription) && (vehicleMake && vehicleMake.getDescription) ? ' ' : '';
        cardHeading += vehicleMake && vehicleMake.getDescription ? vehicleMake.getDescription() : '';
        cardHeading += (cardHeading.length > 0) && (vehicleColour && vehicleColour.getDescription) ? ' - ' : '';
        cardHeading += vehicleColour && vehicleColour.getDescription ? vehicleColour.getDescription() : '';

        this.cardHeadingUpdateEventEmitter.emit(cardHeading);
    }

    private cannotFindVehicleClicked() {
        const attributes: Map<number, string> = new Map<number, string>();
        this.digitalAnalyticsService.constructAndFireElementTag(
            DigitalAnalyticsElementModel.ELEMENT_VEHICLE_CANNOT_FIND_VEHICLE, attributes
        );
        this.googleAnalyticsService.fireGoogleAnalytics(
            GoogleAnalyticsActionModel.ACTION_SUBMITTED,
            GoogleAnalyticsEventModel.EVENT_CANNOT_FIND_VEHICLE_MAKE_OR_MODEL
        );

        this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_COVER_DETAILS_VEHICLE_NOT_FOUND);
        const dialogRef = this.needHelpDialogService.showDialog();

        // NOTE: We need to reset the current page if the user cancels the dialog
        this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {
            this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_COVER_DETAILS);
        }));
    }

}
