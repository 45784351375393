import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {CardModel} from './model/card.model';
import {FormGroup} from '@angular/forms';
import {CardTabModel} from './model/card.tab.model';
import {CardTabComponent} from './card.tab.component';
import {CardTabMenuItemParamsModel} from './model/card.tabmenuitem.params.model';
import {AutoUnsubscribe} from '../../shared/auto-unsubscribe';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent extends AutoUnsubscribe implements OnInit, OnDestroy, AfterContentInit, AfterViewChecked {

    @Input() card: CardModel;
    @Input() cardIndex: number;

    @Output() persistFormDataEvent = new EventEmitter();
    @Output() tabStatusChangedEvent = new EventEmitter();

    @ViewChild('tabItemContentEntry', { read: ViewContainerRef }) tabItemContentEntry: ViewContainerRef;

    currentTabIndex = 0;
    isCardCollapsed = true;
    cardTabModels: CardTabModel[];
    tabMenuItems: string[];
    cardSavedTabStatus: boolean[];
    cardStatus = '';

    constructor(
        private resolver: ComponentFactoryResolver,
        private elementRef: ElementRef,
        private cdRef: ChangeDetectorRef
    ) {
        super();
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    ngOnInit() {
        this.tabMenuItems = this.card.tabs.map(tab => tab.title);
        this.cardTabModels = this.card.tabs;
        this.cardStatus = this.card.status;

        if (this.isNewTab()) {
            this.cardSavedTabStatus = this.card.tabs.map(() => false);
        } else {
            this.cardSavedTabStatus = this.card.tabs.map(e => {
                return !this.isEmpty(e.componentModel);
            });

            this.updateCardStatus();
        }

        this.toggleCardElement(!this.isCardCollapsed);
    }

    ngOnDestroy() {
        this.clearView();
    }

    ngAfterContentInit() {
        this.showTab(this.currentTabIndex);
    }

    private showTab(tabIndex: number): void {
        if (this.isTabAllowed(tabIndex)) {
            this.clearView();

            this.currentTabIndex = tabIndex;

            if (!this.isCardCollapsed) {
                this.loadTab(this.currentTabIndex);
                this.triggerOnTabLoadEvent();
                window.scrollTo(0, 0);
            }
        }
    }

    private loadTab(tabIndex: number): void {
        const cardTabElement = this.cardTabModels[tabIndex];
        const componentFactory = this.resolver.resolveComponentFactory(cardTabElement.component);
        const componentRef = this.tabItemContentEntry.createComponent(componentFactory);

        this.setComponentInputs(componentRef.instance, cardTabElement);
        this.setComponentOutputs(componentRef.instance, tabIndex);

        window.scrollTo(0, 0);
    }

    private clearView() {
        if (this.tabItemContentEntry) {
            this.tabItemContentEntry.clear();
        }
    }

    private setComponentInputs(componentInstance: any, cardTabElement: CardTabModel) {
        const cardTabComponentInstance = <CardTabComponent>componentInstance;
        cardTabComponentInstance.title = cardTabElement.title;
        cardTabComponentInstance.subTitle = cardTabElement.subTitle;
        cardTabComponentInstance.isActive = cardTabElement.isActive;
        cardTabComponentInstance.componentModel = cardTabElement.componentModel;
        cardTabComponentInstance.metaData = cardTabElement.metaData;
        cardTabComponentInstance.menuItems = cardTabElement.menuItems;
        cardTabComponentInstance.tabLoadedEventEmitter = cardTabElement.tabLoadedEventEmitter;
        cardTabComponentInstance.parentCardId = this.card.id;
    }

    private setComponentOutputs(componentInstance: any, tabIndex: number) {
        this.subscriptions.push(componentInstance.onCloseEventEmitter.subscribe(data => {
            this.closeTab();
        }));

        this.subscriptions.push(componentInstance.onSubmitEventEmitter.subscribe(form => {
            this.onTabSubmit(form, tabIndex);
        }));

        this.subscriptions.push(componentInstance.onTabMenuItemClickEventEmitter.subscribe(data => {
            this.onTabMenuItemClick(data);
        }));

        this.subscriptions.push(componentInstance.cardHeadingUpdateEventEmitter.subscribe(data => {
            this.card.heading = data;
        }));
    }

    private isTabAllowed(tabIndex: number): boolean {

        let isTabAllowed = false;

        // Check if current tab is the first tab, allow if it is the first tab
        if (this.currentTabIndex === 0 && this.currentTabIndex === tabIndex) {
            isTabAllowed = true;
        }

        // Check if tab is sequentially the next one, if so allow it
        if (!isTabAllowed && (this.currentTabIndex + 1) === tabIndex) {
            isTabAllowed = true;
        }

        // Check if current tab has already been saved, if so allow it
        if (!isTabAllowed && this.cardSavedTabStatus[tabIndex] === true) {
            isTabAllowed = true;
        }

        return isTabAllowed;
    }

    private saveTabStatus(tabIndex: number, isValid: boolean): void {
        this.cardSavedTabStatus[tabIndex] = isValid;
        this.updateCardStatus();
    }

    private onTabSubmit(form: FormGroup, tabIndex: number) {
        this.persistFormData(tabIndex, form.value);

        if (form.valid) {
            this.saveTabStatus(tabIndex, true);

            if (tabIndex < this.tabMenuItems.length - 1) {
                tabIndex++;
                this.showTab(tabIndex);
            } else {
                this.finishTab();
            }
        } else {
            this.saveTabStatus(tabIndex, false);
            window.scrollTo(0, 0);
        }

        this.tabStatusChangedEvent.emit();
    }

    onTabMenuItemClick(cardTabMenuItemParams: CardTabMenuItemParamsModel) {
        if (!this.isCardCollapsed) {
            if (cardTabMenuItemParams.allowProceedToNextTab) {
                this.saveTabStatus(cardTabMenuItemParams.fromTabIndex, false);
                this.showTab(cardTabMenuItemParams.toTabIndex);
            } else {
                this.persistFormData(cardTabMenuItemParams.fromTabIndex, cardTabMenuItemParams.form.value);
                if (cardTabMenuItemParams.form.valid) {
                    this.saveTabStatus(cardTabMenuItemParams.fromTabIndex, true);
                    this.showTab(cardTabMenuItemParams.toTabIndex);
                } else {
                    this.saveTabStatus(cardTabMenuItemParams.fromTabIndex, false);
                    window.scrollTo(0, 0);
                }
            }

            this.tabStatusChangedEvent.emit();
        }
    }

    private persistFormData(tabIndex: number, formData: any) {
        this.persistFormDataEvent.emit({
            'cardId': this.card.id,
            'tabIndex': tabIndex,
            'formData': formData
        });
    }

    private triggerOnTabLoadEvent() {
        this.cardTabModels[this.currentTabIndex].tabLoadedEventEmitter.next();
    }

    onCardExpandClick() {
        this.isCardCollapsed = !this.isCardCollapsed;
        this.toggleCardElement(!this.isCardCollapsed);
        this.showTab(0);
    }

    isNewTab() {
        return (
            this.getCardStatus() === 'not-yet-completed' && this.isCardCollapsed
        );
    }

    isTabCompleted() {
        return this.getCardStatus() === 'completed';
    }

    getCardStatus() {
        return this.cardStatus.toLowerCase().replace(/ /g, '-').replace(/!/g, '');
    }

    private updateCardStatus() {
        if (this.cardSavedTabStatus) {
            if (this.cardSavedTabStatus.length > 0) {

                const firstCardModelElement = this.cardSavedTabStatus[0];
                const lastCardModelElement = this.cardSavedTabStatus[this.cardSavedTabStatus.length - 1];

                if (lastCardModelElement) {
                    this.cardStatus = 'Completed!';
                } else if (firstCardModelElement) {
                    this.cardStatus = 'In progress';
                } else {
                    this.cardStatus = 'Not yet completed';
                }
                this.card.status = this.cardStatus;
            }
        }
    }

    private toggleCardElement(isVisible) {
        const tabList = this.elementRef.nativeElement.querySelectorAll('div.cover-item-card');
        if (tabList.length > 0) {
            for (let i = 0; i < tabList.length; i++) {
                const tab = tabList[i];
                if (isVisible === true) {
                    tab.classList.add('expanded');
                    tab.classList.remove('collapsed');
                } else {
                    tab.classList.add('collapsed');
                    tab.classList.remove('expanded');
                }
            }
        }
    }

    private closeTab() {
        this.isCardCollapsed = true;
        this.showTab(0);
        this.clearView();
    }

    private finishCard() {
        const tabList = this.elementRef.nativeElement.querySelectorAll('div.tab-item-wrapper');

        if (tabList.length > 0) {
            const tab = tabList[0];
            tab.classList.add('tabs-completed');
        }
    }

    private finishTab() {
        this.closeTab();
        this.finishCard();
    }

    private isEmpty(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

}
