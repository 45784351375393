import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MarketingTopComponent} from './helper/marketing-top/marketing-top.component';
import {MarketingBottomComponent} from './helper/marketing-bottom/marketing-bottom.component';
import {MarketingComponent} from './marketing/marketing.component';
import {QuoteModule} from '../quote/quote.module';
import {MarketingCallCentreComponent} from './marketing-call-centre/marketing-call-centre.component';
import {SharedModule} from '../shared/shared.module';
import {PickComponent} from './helper/pick/pick.component';
import {RiskPickService} from './helper/pick/risk-pick.service';

@NgModule({
    declarations: [MarketingTopComponent, MarketingBottomComponent, MarketingComponent, MarketingCallCentreComponent, PickComponent],
    imports: [
        CommonModule,
        QuoteModule,
        SharedModule
    ],
    exports: [MarketingTopComponent, MarketingBottomComponent, PickComponent],
    providers: [RiskPickService]
})
export class MarketingModule {
}
