import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MstiErrorService} from './msti-error.service';

import {ErrorHandlerRoutingModule} from './error-handler-routing.module';
import {MstiErrorComponent} from './msti-error-components/msti-error.component';

@NgModule({
    declarations: [MstiErrorComponent],
    imports: [
        FormsModule,
        CommonModule,
        ErrorHandlerRoutingModule
    ],
    entryComponents: [],
    exports: [],
    providers: [MstiErrorService]
})
export class ErrorHandlerModule {
}
