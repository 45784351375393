import {CifRegularDriverModel} from './cif-regular-driver.model';
import {CifVehicleDetailsModel} from './cif-vehicle-details.model';
import {CifSecurityFeatureModel} from './cif-security-feature.model';
import {CifVehicleAddressModel} from './cif-vehicle-address.model';
import {CifCoverModel} from './cif-cover.model';
import {CifVehicleAccessoryModel} from './cif-vehicle-accessory.model';
import {CifPremiumModel} from './cif-premium.model';
import {VehicleDescriptionModel} from '../vehicle-description.model';
import {VehicleDetailsModel} from '../vehicle-details.model';
import {SaveClientResponseModel} from '../../../../../personal/model/SaveClientModels/save-client-response.model';
import {CifAddressInfoModel} from '../../../../../personal/model/SaveClientModels/cif-address-info.model';
import {VehicleDriverDetailsModel} from '../vehicle-driver-details.model';
import {RegisteredIdTypeModel} from '../../../../../personal/model/registered-id-type.model';
import {AddressTypeModel} from '../../../../../shared/msti-agm/model/address-type.model';
import {BaseCifRiskModel} from '../../../base/model/base-cif-risk.model';
import {CifMoneyModel} from '../../../../model/cif/cif-money.model';
import {PifVehicleAddressModel} from '../pif/pif-vehicle-address.model';

export class CifVehicleModel extends BaseCifRiskModel {
  jurisdiction: string;
  retailPrice: CifMoneyModel;
  tradePrice: CifMoneyModel;
  outstandingFinanceAmt: CifMoneyModel;
  vehicleValueTypeCode: string;
  trackerRequest: boolean;
  preInspectionWaived: boolean;
  surveyWaived: boolean;
  effectiveDateFrom: string;
  effectiveDateTo: string;
  registeredOwner: string;
  clientId: string; // Mandatory
  // riskResourceRef: string;
  riskDescription: string; // Mandatory
  sectionCode: string; // Mandatory
  sumInsured: string;
  usageCode: string; // Mandatory
  possessionCode: string; // Mandatory
  modificationCode: string; // Mandatory
  regularDriver: CifRegularDriverModel; // TODO - Confirm, this field doesn't appear to be used
  vehicle: CifVehicleDetailsModel; // Mandatory
  securityFeatures: CifSecurityFeatureModel[];
  addresses: CifVehicleAddressModel[]; // Mandatory
  covers: CifCoverModel[];
  accessories: CifVehicleAccessoryModel[];
  premium: CifPremiumModel;
  riskAddress: PifVehicleAddressModel;

  constructor(clientModel: SaveClientResponseModel) {
    super();
    this.vehicle = new CifVehicleDetailsModel();
    this.premium = new CifPremiumModel();
    this.securityFeatures = [];
    this.addresses = [];
    this.covers = [];
    this.accessories = [];
    this.sectionCode = '1001';
    this.usageCode = '001'; // Private
  }

  populateFromVehicleDescriptionModel(vehicleDescriptionModel: VehicleDescriptionModel) {
    this.vehicle.makeModel.yearModel = vehicleDescriptionModel.vehicleYear.getCode();
    this.vehicle.conditionCode = vehicleDescriptionModel.vehicleCondition.getCode();
    this.vehicle.registrationNumber = vehicleDescriptionModel.vehicleRegistrationNumber;
    this.vehicle.makeModel.makeCode = vehicleDescriptionModel.vehicleMake.getCode();
    this.vehicle.makeModel.modelCode = vehicleDescriptionModel.vehicleModel.getCode();
    this.vehicle.makeModel.mmCode = vehicleDescriptionModel.vehicleModel.mmCode;
    this.vehicle.colourCode = vehicleDescriptionModel.vehicleColour.getCode();
    // this.vehicleDetails.vin = vehicleDescriptionModel.vehicleVinNumber;
    this.vehicle.engineNumber = vehicleDescriptionModel.vehicleEngineNumber;
    this.vehicle.typeCode = vehicleDescriptionModel.vehicleType.getCode();
    this.possessionCode = vehicleDescriptionModel.vehiclePossession.getCode();

    this.riskDescription = vehicleDescriptionModel.vehicleYear.getCode() + ' ' + vehicleDescriptionModel.vehicleMake.getDescription() +
      ' ' + vehicleDescriptionModel.vehicleModel.getDescription();
  }

  populateFromVehicleDetailsModel(vehicleDetailsModel: VehicleDetailsModel, clientHomeAddressDetails: CifAddressInfoModel) {
    this.resetArrays();

    this.modificationCode = vehicleDetailsModel.vehicleModificationType.getCode();

    for (const lookupModel of vehicleDetailsModel.vehicleSecurityDevices) {
      this.securityFeatures.push(new CifSecurityFeatureModel('', lookupModel.getCode()));
    }

    this.addAddress(
      'NIGHTTIME',
      vehicleDetailsModel.vehicleNightTimeLocation.getCode(),
      vehicleDetailsModel.vehicleNightTimeAreaType.getCode(),
      vehicleDetailsModel.physicalAddressIsNightAddress,
      vehicleDetailsModel.vehicleNightTimeAddress,
      clientHomeAddressDetails
    );

    this.addAddress(
      'DAYTIME',
      vehicleDetailsModel.vehicleDayTimeLocation.getCode(),
      '',
      vehicleDetailsModel.physicalAddressIsDayAddress,
      vehicleDetailsModel.vehicleDayTimeAddress,
      clientHomeAddressDetails
    );
  }

  private resetArrays() {
    this.securityFeatures = new Array();
    this.addresses = new Array();
    this.covers = new Array();
    this.accessories = new Array();
  }

  populateFromVehicleDriverDetailsModel(vehicleDriverDetailsModel: VehicleDriverDetailsModel) {
    this.regularDriver = new CifRegularDriverModel();

    if (vehicleDriverDetailsModel.vehicleIsRegularDriver === '1') {
      // Object.assign(this.regularDriver, this._clientModel);//TODO Ab-not sure if we need this or not
      this.regularDriver.isPolicyHolder = true;
      this.regularDriver.relationshipToInsuredCode = '001'; // Self
    } else {
      this.regularDriver.isPolicyHolder = false;

      const registeredIdTypeModel = new RegisteredIdTypeModel();
      registeredIdTypeModel.identityType = 'RSA_ID';
      registeredIdTypeModel.identityValue = vehicleDriverDetailsModel.vehicleRegularDriverIDNumber;

      this.regularDriver.personal.personIdentity = registeredIdTypeModel;
      this.regularDriver.personal.titleCode = vehicleDriverDetailsModel.vehicleRegularDriverTitle.getCode();
      this.regularDriver.personal.firstName = vehicleDriverDetailsModel.vehicleRegularDriverFullName;
      this.regularDriver.personal.lastName = vehicleDriverDetailsModel.vehicleRegularDriverSurname;
      this.regularDriver.personal.genderCode = vehicleDriverDetailsModel.vehicleRegularDriverGender.getCode();
      this.regularDriver.personal.maritalStatusCode = vehicleDriverDetailsModel.vehicleRegularDriverMaritalStatus.getCode();
      this.regularDriver.personal.occupationCode = vehicleDriverDetailsModel.vehicleRegularDriverOccupation.getCode();
      this.regularDriver.contact.mobileNumber = vehicleDriverDetailsModel.vehicleRegularDriverContactNumber;
      this.regularDriver.addressList.push(CifAddressInfoModel.getCifAddressInfoModel(vehicleDriverDetailsModel.vehicleRegularDriverAddress));
      this.regularDriver.relationshipToInsuredCode = vehicleDriverDetailsModel.vehicleRegularDriverRelationshipToInsured.getCode();
    }

    this.regularDriver.driversLicense.driversLicenseCode = vehicleDriverDetailsModel.vehicleRegularDriverLicenseType.getCode();

    // TODO - Shouldn't this be an array?
    this.regularDriver.driversLicense.limitations.push(vehicleDriverDetailsModel.vehicleRegularDriverLicenseLimitations.getCode());

    // NOTE: default month and day to 01 (yyyy-mm-dd)
    this.regularDriver.driversLicense.issueDate = vehicleDriverDetailsModel.vehicleRegularDriverLicenseDateIssued;

    this.regularDriver.driversLicense.ageIssued = this.getAgeFromDateString(
      vehicleDriverDetailsModel.vehicleRegularDriverLicenseDateIssued
    );

    this.regularDriver.hasDrivingConviction = vehicleDriverDetailsModel.vehicleRegularDriverHasDrivingConvictions === '1';

    if (this.regularDriver.hasDrivingConviction === true) {
      // TODO - Not yet implemented, this is an upcoming feature
      this.regularDriver.drivingConvictions = [];
    }
  }

  private addAddress(
    addressType: string,
    storageTypeCode: string,
    areaTypeCode: string,
    sameAsHome: string,
    address: AddressTypeModel,
    clientHomeAddressDetails: CifAddressInfoModel
  ) {
    const cifVehicleAddressModel = new CifVehicleAddressModel();

    cifVehicleAddressModel.addressType = addressType;
    cifVehicleAddressModel.storageTypeCode = storageTypeCode;
    cifVehicleAddressModel.areaTypeCode = areaTypeCode;
    cifVehicleAddressModel.sameAsHome = sameAsHome === '1';

    if (!cifVehicleAddressModel.sameAsHome) {
      cifVehicleAddressModel.addressDetails.unitNo = address.unitNumber || '';
      cifVehicleAddressModel.addressDetails.sectSchemeName = address.sectionSchemeName || '';
      cifVehicleAddressModel.addressDetails.estateCode = address.estateCode || '';
      cifVehicleAddressModel.addressDetails.estateName = address.estateName || '';
      cifVehicleAddressModel.addressDetails.streetCode = address.streetCode || '';
      cifVehicleAddressModel.addressDetails.streetDesc = address.streetDesc || '';
      cifVehicleAddressModel.addressDetails.streetTypeCode = address.streetTypeCode || '';
      cifVehicleAddressModel.addressDetails.streetTypeDesc = address.streetTypeDesc || '';
      cifVehicleAddressModel.addressDetails.streetNo = address.streetNo || '';
      cifVehicleAddressModel.addressDetails.suburbCode = address.suburbCode || '';
      cifVehicleAddressModel.addressDetails.suburbDesc = address.suburbDesc || '';
      cifVehicleAddressModel.addressDetails.municipalityCode = address.municipalityCode || '';
      cifVehicleAddressModel.addressDetails.municipalityName = address.municipalityName || '';
      cifVehicleAddressModel.addressDetails.provinceCode = address.provinceCode || '';
      cifVehicleAddressModel.addressDetails.provinceDesc = address.provinceDesc || '';
      cifVehicleAddressModel.addressDetails.postalCode = address.postalCode || '';
      cifVehicleAddressModel.addressDetails.poBoxNo = address.poBoxNo || '';
    } else {
      cifVehicleAddressModel.addressDetails.unitNo = clientHomeAddressDetails.addressDetails.unitNo || '';
      cifVehicleAddressModel.addressDetails.sectSchemeName = clientHomeAddressDetails.addressDetails.sectSchemeName || '';
      cifVehicleAddressModel.addressDetails.estateCode = clientHomeAddressDetails.addressDetails.estateCode || '';
      cifVehicleAddressModel.addressDetails.estateName = clientHomeAddressDetails.addressDetails.estateName || '';
      cifVehicleAddressModel.addressDetails.streetCode = clientHomeAddressDetails.addressDetails.streetCode || '';
      cifVehicleAddressModel.addressDetails.streetDesc = clientHomeAddressDetails.addressDetails.streetDesc || '';
      cifVehicleAddressModel.addressDetails.streetTypeCode = clientHomeAddressDetails.addressDetails.streetTypeCode || '';
      cifVehicleAddressModel.addressDetails.streetTypeDesc = clientHomeAddressDetails.addressDetails.streetTypeDesc || '';
      cifVehicleAddressModel.addressDetails.streetNo = clientHomeAddressDetails.addressDetails.streetNo || '';
      cifVehicleAddressModel.addressDetails.suburbCode = clientHomeAddressDetails.addressDetails.suburbCode || '';
      cifVehicleAddressModel.addressDetails.suburbDesc = clientHomeAddressDetails.addressDetails.suburbDesc || '';
      cifVehicleAddressModel.addressDetails.municipalityCode = clientHomeAddressDetails.addressDetails.municipalityCode || '';
      cifVehicleAddressModel.addressDetails.municipalityName = clientHomeAddressDetails.addressDetails.municipalityName || '';
      cifVehicleAddressModel.addressDetails.provinceCode = clientHomeAddressDetails.addressDetails.provinceCode || '';
      cifVehicleAddressModel.addressDetails.provinceDesc = clientHomeAddressDetails.addressDetails.provinceDesc || '';
      cifVehicleAddressModel.addressDetails.postalCode = clientHomeAddressDetails.addressDetails.postalCode || '';
      cifVehicleAddressModel.addressDetails.poBoxNo = clientHomeAddressDetails.addressDetails.poBoxNo || '';
    }

    this.addresses.push(cifVehicleAddressModel);
  }


}
