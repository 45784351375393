import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MSTIConfiguration} from './msti.config';
import {HttpClientModule} from '@angular/common/http';
import {MSTIService} from './services/msti.service';
import {BrokerDetailsService} from './broker-common/broker-details.service';
import {LogoutService} from './services/logout.service';
import {StepperComponent} from './stepper/stepper.component';
import {StepperService} from './stepper/stepper.service';
import {RiskOptionDataService} from './risk/risk-options-data.service';
import {RiskOptionService} from './risk/risk-option.service';
import {ImageService} from './services/image.service';
import {Globals} from './globals/globals';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatRadioModule,
  MatSelectModule
} from '@angular/material';
import {MstiConfirmationDialogComponent} from './pop-up-dialogs/msti-confirmation-dialog/msti-confirmation-dialog.component';
import {LookupService} from './lookup/lookup.service';
import {LoaderComponent} from './screen-loader/loader.component';
import {LoaderService} from './screen-loader/loader.service';
import {MSTITooltipComponent} from './pop-up-dialogs/msti-tooltip/msti-tooltip.component';
import {UrlService} from './services/url.service';
import {MstiAgmService} from './msti-agm/msti-agm.service';
import {AgmCoreModule} from '@agm/core';
import {MstiAgmComponent} from './msti-agm/msti-agm.component';
import {MstiAgmPopupDialogComponent} from './msti-agm/msti-agm-popup-dialog/msti-agm-popup-dialog.component';
import {FormatCurrencyPipe} from '../shared/pipes/format-currency.pipe';
import {SentenceTitleCasePipe} from '../shared/pipes/sentence-title-case.pipe';
import {SentenceToNamePipe} from '../shared/pipes/sentence-to-name.pipe';
import {ReplaceLineBreaksPipe} from './pipes/replace-line-breaks.pipe';
import {DigitalAnalyticsService} from './digital-analytics/digital-analytics.service';
import {GoogleAnalyticsService} from './google-analytics/google-analytics.service';
import {LayoutModule} from '@angular/cdk/layout';
import {ErrorHandlerModule} from './msti-error-handler/error-handler.module';
import {SessionStorageService} from './services/session-storage.service';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {RoutingService} from './services/routing.service';
import {environment} from '../../environments/environment';
import {DynamicFormModule} from './dynamic-form/dynamic-form.module';
import {ValidationModule} from './validators/validation.module';
import {PersonalBelongingsSpecifiedPopupDialogComponent} from './dynamic-form/dynamic-form-control/control-types/risk-specific/personal-belongings-specified/personal-belongings-specified-popup-dialog/personal-belongings-specified-popup-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UiUtilsService} from './utilities/ui-utils.service';
import {BrokerUrlService} from './services/broker-url.service';
import {AbCryptService} from './services/abcrypt.service';

@NgModule({
  declarations: [
    StepperComponent,
    MstiConfirmationDialogComponent,
    LoaderComponent,
    MSTITooltipComponent,
    MstiAgmComponent,
    MstiAgmPopupDialogComponent,
    FormatCurrencyPipe,
    SentenceTitleCasePipe,
    SentenceToNamePipe,
    ReplaceLineBreaksPipe,
    PersonalBelongingsSpecifiedPopupDialogComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDatepickerModule,
    LayoutModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapsApiKey,
      libraries: ['places']
    }),
    MatMomentDateModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    StepperComponent,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatChipsModule,
    MatNativeDateModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    LoaderComponent,
    MstiAgmComponent,
    FormatCurrencyPipe,
    SentenceTitleCasePipe,
    SentenceToNamePipe,
    ReplaceLineBreaksPipe,
    ValidationModule,
    ErrorHandlerModule,
    MatMomentDateModule,
    DynamicFormModule,
    ReactiveFormsModule,
    FormsModule
  ],
  entryComponents: [MstiConfirmationDialogComponent, MSTITooltipComponent, MstiAgmPopupDialogComponent,
    PersonalBelongingsSpecifiedPopupDialogComponent],
  providers: [
    MSTIConfiguration,
    MSTIService,
    RoutingService,
    BrokerDetailsService,
    LogoutService,
    StepperService,
    RiskOptionDataService,
    RiskOptionService,
    ImageService,
    LookupService,
    Globals,
    LoaderService,
    UrlService,
    MstiAgmService,
    FormatCurrencyPipe,
    SentenceTitleCasePipe,
    SentenceToNamePipe,
    ReplaceLineBreaksPipe,
    DigitalAnalyticsService,
    GoogleAnalyticsService,
    SessionStorageService,
    UiUtilsService,
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}
    },
    BrokerUrlService,
    AbCryptService

  ]
})
export class SharedModule {
}
