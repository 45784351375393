import {Injectable} from '@angular/core';
import {MSTIService} from '../../shared/services/msti.service';
import {AdminFeeUrlService} from './admin-fee-url.service';
import {AdminFeeModel} from '../models/admin-fee.model';

@Injectable()
export class AdminFeeService {

  adminFeeDetails: AdminFeeModel;

  constructor(private adminFeeUrlService: AdminFeeUrlService, private mstiService: MSTIService) {}

  getAdminFeeDetails(agreementResourceRef: string, clientResourceRef: string) {
    const url = this.adminFeeUrlService.getSaveUpdateGetBrokerFeeURL(agreementResourceRef, clientResourceRef);
    return this.mstiService.getWithAbAuth(url);
  }

  saveAdminFeeDetails(agreementResourceRef: string, clientResourceRef: string, adminFeeDetails: AdminFeeModel) {
    const url = this.adminFeeUrlService.getSaveUpdateGetBrokerFeeURL(agreementResourceRef, clientResourceRef);
    return this.mstiService.postWithAbAuth(url, adminFeeDetails);
  }

  updateAdminFeeDetails(agreementResourceRef: string, clientResourceRef: string, adminFeeDetails: AdminFeeModel) {
    const url = this.adminFeeUrlService.getSaveUpdateGetBrokerFeeURL(agreementResourceRef, clientResourceRef);
    return this.mstiService.putWithAbAuth(url, adminFeeDetails);
  }
}
