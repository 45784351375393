import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {Attribute, Directive, forwardRef} from '@angular/core';

@Directive({
    selector: '[validateInputExists][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputValueValidator),
            multi: true
        }
    ]
})
export class InputValueValidator implements Validator {

    constructor(@Attribute('validateInputExists') public validateInputExists: string) {
    }

    validate(control: AbstractControl): { [key: string]: any } {
        const input = control.value;
        if (!input || input === '') {
            return {
                validateObj: {
                    valid: false,
                    msg: 'This field is required'
                }
            };
        }
    }
}
