import {CifRiskDetailsModel} from './cif-risk-details.model';
import {AgreementInformationModel} from '../agreement-information.model';

export class CifQuoteSaveResponseModel {

  //  clientInfo: CifClientInfoModel;

  // TODO - This field must be removed once the quote api V2 implementation is completed as it no longer exists on the response object
  // vehicles: CifVehicleModel[];

  // quoteInfo: CifQuoteInfoModel; // Old
  riskReferences: CifRiskDetailsModel[];
  agreementInformation: AgreementInformationModel;
}
