import {Component, Injector} from '@angular/core';
import {BaseRiskTabComponent} from '../../../base/component/base-risk-tab.component';
import {BaseRiskTabService} from '../../../base/service/base-risk-tab.service';
import {BuildingDetailsService} from '../../service/building-details.service';
import {AddressLookupModel} from '../../../../../shared/lookup/address-lookup.model';
import {BuildingService} from '../../service/building.service';
import {AddressTypeModel} from '../../../../../shared/msti-agm/model/address-type.model';
import {GoogleAnalyticsEventModel} from '../../../../../shared/google-analytics/model/google-analytics-event.model';

@Component({
    selector: 'app-building-details',
    templateUrl: './building-details.component.html',
    styleUrls: [],
    providers: [BuildingDetailsService]
})
export class BuildingDetailsComponent extends BaseRiskTabComponent {

    constructor(
        injector: Injector,
        private buildingDetailsService: BuildingDetailsService
    ) {
        super(injector);
    }

    getRiskTabService(): BaseRiskTabService {
        return this.buildingDetailsService;
    }

    initAnalytics(): void {
        // TODO - Implement me
    }

    doComponentInit(): void {
        // TODO - Confirm, would it be necessary to move this card id initialisation to a base class for all risk services to benefit from?
        this.buildingDetailsService.parentCardId = this.parentCardId;

        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('buildingAddressSameAsPhysical').valueChanges.subscribe(
                selectedValue => {
                    const isAddressSameAsPhysical = !this.buildingDetailsService.isRadioOptionNo(selectedValue);
                    this.processAddressSameAsPhysical(isAddressSameAsPhysical);
                }
            )
        );

        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('buildingAddress').valueChanges.subscribe(
                selectedValue => {
                    if (selectedValue) {
                        this.buildingDetailsService.setUniqueAddressForCard(selectedValue);
                        this.updateCardHeading(selectedValue);
                        this.displayDropdownOrAddressPicker();
                    }
                }
            )
        );

        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('addressSelection').valueChanges.subscribe(
                selectedValue => {
                    this.processAddressSelection(selectedValue);
                    this.updateCardHeading(selectedValue.value);
                }
            )
        );
    }

    private processAddressSameAsPhysical(isAddressSameAsPhysical: boolean): void {
        if (isAddressSameAsPhysical === true) {
            this.buildingDetailsService.clearAddressForCard();
            this.buildingDetailsService.displayAddressSelectControl(false);
            this.buildingDetailsService.displayAddressControl(false);
            this.updateCardHeading(this.buildingDetailsService.getClientPhysicalAddress());
        } else {
            this.displayDropdownOrAddressPicker();
        }
    }

  private displayDropdownOrAddressPicker() {
    if (this.buildingDetailsService.getStoredAddresses().length > 0) {
      this.buildingDetailsService.displayAddressSelectControl(true);
      this.buildingDetailsService.displayAddressControl(false);
    } else {
      this.buildingDetailsService.displayAddressSelectControl(false);
      this.buildingDetailsService.displayAddressControl(true);
    }
  }

    private processAddressSelection(selectedAddress: AddressLookupModel): void {
        if (selectedAddress && selectedAddress.getDescription() === BuildingService.VALUE_ADD_NEW_ADDRESS) {
            this.buildingDetailsService.displayAddressControl(true);
        } else {
            this.buildingDetailsService.displayAddressControl(false);
        }
    }

    private updateCardHeading(selectedAddress: AddressTypeModel) {
        if (selectedAddress) {
            this.cardHeadingUpdateEventEmitter.emit(selectedAddress.formattedAddressLine);
        }
    }

    propagatePostLoadUpdates(): void {
        // TODO - Implement me
    }

    getGoogleAnalyticsEventLabel(): string {
        return GoogleAnalyticsEventModel.EVENT_COMPLETE_BUILDING_DETAILS_ON_MY_BUILDING;
    }

}
