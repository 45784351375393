import {AfterViewChecked, Component, Injector, ViewChild} from '@angular/core';
import {map, startWith} from 'rxjs/operators';
import {MatAutocomplete} from '@angular/material';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';
import {Observable} from 'rxjs';
import {LookupModel} from '../../../../../lookup/lookup.model';
import {AutocompleteDynamicControlModel} from './autocomplete-dynamic-control.model';

@Component({
    selector: 'app-autocomplete-dynamic-form-control',
    templateUrl: './autocomplete-dynamic-form-control.component.html',
    styleUrls: ['./autocomplete-dynamic-control.component.scss']
})
export class AutocompleteDynamicFormControlComponent extends BaseDynamicFormControl<string> implements AfterViewChecked {

    private matAutocomplete: MatAutocomplete;
    private afterViewCheckedComplete: boolean;

    availableOptions: LookupModel[] = [];
    filteredOptions: Observable<LookupModel[]>;

    constructor(
        injector: Injector
    ) {
        super(injector);
        this.afterViewCheckedComplete = false;
    }

    @ViewChild(MatAutocomplete) set content(content: MatAutocomplete) {
        this.matAutocomplete = content;

        if (this.matAutocomplete) {
            this.setAutocompleteSelectedOptionListener();
        }
    }

    doInit() {
        this.subscriptions.push(
            (this.dynamicControlModel as unknown as AutocompleteDynamicControlModel)
                .availableOptionsObservable.subscribe((availableOptions) => {
            this.availableOptions = availableOptions;
        }));

        if (this.form.controls[this.dynamicControlModel.key]) {
            // Disable by default and re-enable once options are received via the availableOptionChangedSubscription below
            this.form.controls[this.dynamicControlModel.key].disable();
            this.filteredOptions = this.form.controls[this.dynamicControlModel.key].valueChanges
                .pipe(
                    startWith(''),
                    map(value => {
                        if (
                            this.availableOptions &&
                            this.availableOptions.length > 0 &&
                            !this.isHidden
                        ) {
                            this.resetAutoCompleteStatus();
                        }

                        return this.filter(value.toString());
                    })
                );

            this.subscriptions.push(
                (this.dynamicControlModel as unknown as AutocompleteDynamicControlModel).availableOptionsObservable
                .subscribe((availableOptions) => {
                    this.clearField();

                    if (availableOptions && availableOptions.length > 0) {
                        this.form.controls[this.dynamicControlModel.key].enable();
                    } else {
                        this.form.controls[this.dynamicControlModel.key].disable();
                    }

                })
            );
        }
    }

    updateFormControlValue(inputValue: any) {
        this.form.controls[this.dynamicControlModel.key].setValue(inputValue);
        this._clearFormControlErrors();
    }

    clearValue() {
        this.dynamicControlModel.value = null;
    }

    // NOTE: For some reason ngAfterViewInit doesn't work here
    ngAfterViewChecked() {
        if (!this.afterViewCheckedComplete && this.matAutocomplete) {
            // NOTE: This code block is required for when we patch values
            const currentValue = this.form.get(this.dynamicControlModel.key).value;
            if (currentValue) {
                this.matAutocomplete._emitSelectEvent(currentValue);
                this._clearFormControlErrors();
            } else {
                // TODO - We should find a way only emit the event once for patched values instead of listening for every change
                this.subscriptions.push(this.form.get(this.dynamicControlModel.key).valueChanges.subscribe(selectedValue => {
                    if (selectedValue) {
                        this.matAutocomplete._emitSelectEvent(selectedValue);
                    }
                }));
            }
            this.afterViewCheckedComplete = true;
            this.cdRef.detectChanges();
        }
    }

    private setAutocompleteSelectedOptionListener() {
        this.subscriptions.push(this.matAutocomplete.optionSelected.subscribe(selectedOption => {
            if (selectedOption) {
                this._clearFormControlErrors();
            }
        }));
    }

    private filter(value: string) {
        const options = this.availableOptions;

        if (options) {
            const filterValue = value.toLowerCase();
            return options.filter(option =>
                option.getDescription().toLowerCase().startsWith(filterValue)
            );
        }
    }

    private resetAutoCompleteStatus() {
        this.form.controls[this.dynamicControlModel.key].setErrors({ 'invalidOption': true });
    }

    isInputValueValidCheck($event) {
        // Don't test if the selection box is still open
        if ($event.target.getAttribute('aria-expanded') === 'false') {

            if (this.availableOptions && this.availableOptions.length > 0 &&
                !this.availableOptions.find(function (option) {
                    return option.getDescription() === $event.target.value;
                })) {
                this.clearField();
            }
        }
    }

    autoCompleteFn(option?: LookupModel): string | undefined {
        return option ? option.getDescription() : undefined;
    }

}
