import {BaseDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/base-dynamic-control.model';

export abstract class BaseRiskTabService {

    constructor() { }

    public isRadioOptionNo(radioOption: string) {
        return !radioOption || radioOption === '0';
    }

    abstract init(): void;
    abstract getDynamicFormControls(): BaseDynamicControlModel<any>[];

}
