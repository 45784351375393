import {Component, Input, OnInit} from '@angular/core';
import {PremiumConfig} from '../premium.config';
import {PremiumQuoteSummaryItemModel} from '../../model/premium-quote-summary-item.model';
import {ImageService} from '../../../shared/services/image.service';
import {MatDialog} from '@angular/material';
import {MSTITooltipComponent} from '../../../shared/pop-up-dialogs/msti-tooltip/msti-tooltip.component';
import {FormatCurrencyPipe} from '../../../shared/pipes/format-currency.pipe';
import {DigitalAnalyticsService} from '../../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsElementModel} from '../../../shared/digital-analytics/model/digital-analytics-element.model';
import {GoogleAnalyticsService} from '../../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../../shared/google-analytics/model/google-analytics-action.model';
import {BrokerCommissionModel} from '../../../quote/model/cif/brokerCommission.model';
import {QuoteService} from '../../../quote/service/quote.service';
import {MSTIService} from '../../../shared/services/msti.service';
import {LoaderService} from '../../../shared/screen-loader/loader.service';
import {QuoteUrlService} from '../../../quote/service/quote-url.service';

@Component({
  selector: 'app-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.scss']
})
export class QuoteSummaryComponent implements OnInit {
  @Input() premiumQuoteSummaryItemModels: PremiumQuoteSummaryItemModel[];
  @Input() premiumTotal: string;
  @Input() vatAmount: string;
  @Input() brokerCommission: BrokerCommissionModel;

  emptyDisplayValue = PremiumConfig.EMPTY_DISPLAY_VALUE;
  infoIcon: string;

  commissionAmount = '';
  brokerName = '';

  constructor(private imageService: ImageService, public dialog: MatDialog, private quoteService: QuoteService,
              private formatCurrencyPipe: FormatCurrencyPipe, private digitalAnalyticsService: DigitalAnalyticsService,
              private googleAnalyticsService: GoogleAnalyticsService, private mstiService: MSTIService, private loaderService: LoaderService,
              private quoteUrlService: QuoteUrlService) {
    this.infoIcon = imageService.getImage('INFO-ICON');
  }

  ngOnInit() {
    this.premiumTotal = this.formatCurrencyPipe.transform(0, this.premiumTotal);
    this.vatAmount = this.formatCurrencyPipe.transform(0, this.vatAmount);
    this.brokerName = this.brokerCommission.firstName + ' ' + this.brokerCommission.lastName;
    this.commissionAmount = this.brokerCommission.totalCommission;
  }

  openToolTipDialog(description, hint) {
    if (description === 'Momentum Assist') {
      const attributes: Map<number, string> = new Map<number, string>();
      this.digitalAnalyticsService.constructAndFireElementTag(
        DigitalAnalyticsElementModel.ELEMENT_PREMIUM_ASSIST_INFO,
        attributes
      );
      this.googleAnalyticsService.fireGoogleAnalytics(
        GoogleAnalyticsActionModel.ACTION_YES,
        GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_MOMENTUM_ASSIST
      );
    } else if (description === 'SASRIA') {
      const attributes: Map<number, string> = new Map<number, string>();
      this.digitalAnalyticsService.constructAndFireElementTag(
        DigitalAnalyticsElementModel.ELEMENT_PREMIUM_SASRIA_INFO,
        attributes
      );
      this.googleAnalyticsService.fireGoogleAnalytics(
        GoogleAnalyticsActionModel.ACTION_YES,
        GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_SASRIA
      );
    } else if (description === 'Excess') {
      this.googleAnalyticsService.fireGoogleAnalytics(
        GoogleAnalyticsActionModel.ACTION_YES,
        GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_EXCESS
      );
    }

    return this.dialog.open(MSTITooltipComponent, {
      width: '52%',
      data: {message: hint, title: ''},
      panelClass: 'msti-dialog'
    });
  }

  onDownloadQuoteDocClick() {
    this.loaderService.show();
    const quoteId = this.quoteService.quoteId;
    const url = this.quoteUrlService.getQuoteDocURL('client', quoteId);
    this.mstiService.getPdf(url).subscribe(resp => {
      const link = document.createElement('a') as HTMLAnchorElement;
      link.href = URL.createObjectURL(resp);
      link.download = 'Quotation_schedule_' + this.quoteService.quoteDisplayNumber + '.pdf';
      link.click();
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.dialog.open(MSTITooltipComponent, {
        width: '52%',
        data: {message: 'There was and technical issue while downloading your quote document. Please retry', title: 'Sorry..'},
        panelClass: 'msti-dialog'
      });
    });
  }
}
