import {MSTIParentComponent} from '../../../../shared/msti-parent';
import {AfterViewChecked, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CardTabComponent} from '../../../card/card.tab.component';
import {FormGroup} from '@angular/forms';
import {CardTabMenuItemParamsModel} from '../../../card/model/card.tabmenuitem.params.model';
import {CardTabContainerComponent} from '../../../card/card-tab-container/card-tab-container.component';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../../../shared/screen-loader/loader.service';
import {DigitalAnalyticsService} from '../../../../shared/digital-analytics/digital-analytics.service';
import {GoogleAnalyticsService} from '../../../../shared/google-analytics/google-analytics.service';
import {BaseRiskTabService} from '../service/base-risk-tab.service';
import {GoogleAnalyticsActionModel} from '../../../../shared/google-analytics/model/google-analytics-action.model';

export abstract class BaseRiskTabComponent extends MSTIParentComponent implements OnInit, AfterViewChecked, CardTabComponent {

    @Input() title: string;
    @Input() subTitle: string;
    @Input() isActive: boolean;
    @Input() componentModel: any;
    @Input() metaData: any;
    @Input() menuItems: string[];
    @Input() parentCardId: string;
    @Input() tabLoadedEventEmitter: EventEmitter<any>;

    @Output() onCloseEventEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSubmitEventEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output() onTabMenuItemClickEventEmitter: EventEmitter<CardTabMenuItemParamsModel> = new EventEmitter<CardTabMenuItemParamsModel>();
    @Output() cardHeadingUpdateEventEmitter: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild(CardTabContainerComponent) cardTabContainerComponent: CardTabContainerComponent;

    private loaderServiceSubscription: Subscription;
    private loadedInputData: boolean;

    protected loadingPreviousData: boolean;
    protected loaderService: LoaderService;
    protected digitalAnalyticsService: DigitalAnalyticsService;
    protected googleAnalyticsService: GoogleAnalyticsService;

    constructor(
        injector: Injector
    ) {
        super();
        this.loaderService = injector.get(LoaderService);
        this.digitalAnalyticsService = injector.get(DigitalAnalyticsService);
        this.googleAnalyticsService = injector.get(GoogleAnalyticsService);
        this.loadedInputData = false;
    }

    ngOnInit() {
        this.getRiskTabService().init();
        this.initAnalytics();

        this.loaderServiceSubscription = this.loaderService.loaderChanged.subscribe(flag => {
            if (flag === false) {
                this.cardTabContainerComponent.dynamicFormComponent.dynamicFormControls = this.getRiskTabService().getDynamicFormControls();
                this.cardTabContainerComponent.dynamicFormComponent.updateForm();
                this.doComponentInit();
                this.loaderServiceSubscription.unsubscribe(); // We only want this to run the first time the form is loaded
            }
        });
    }

    // NOTE: For some reason ngAfterViewInit doesn't work here
    ngAfterViewChecked() {
        if (this.componentModel && !this.loadedInputData && this.cardTabContainerComponent.dynamicFormComponent.form) {
            this.loadingPreviousData = true;
            this.cardTabContainerComponent.dynamicFormComponent.patchUpdate = this.componentModel;
            this.cardTabContainerComponent.dynamicFormComponent.updateValues();
            this.propagatePostLoadUpdates();
            this.loadedInputData = true;
            this.loadingPreviousData = false;
        }
    }

    onClose() {
        this.onCloseEventEmitter.emit();
    }

    onSubmit(form: FormGroup) {
        this.onSubmitEventEmitter.emit(form);

        this.fireFormBasedGoogleAnalytics(
            form,
            GoogleAnalyticsActionModel.ACTION_SUBMITTED,
            this.getGoogleAnalyticsEventLabel()
        );
    }

    onTabMenuItemClick(data) {
        this.onTabMenuItemClickEventEmitter.emit(data);

        this.fireFormBasedGoogleAnalytics(
            data.form,
            GoogleAnalyticsActionModel.ACTION_SUBMITTED,
            this.getGoogleAnalyticsEventLabel()
        );
    }

    protected fireFormBasedGoogleAnalytics(form: FormGroup, eventAction: string, eventLabel: string) {
        if (form.valid) {
            this.googleAnalyticsService.fireGoogleAnalytics(eventAction, eventLabel);
        }
    }

    abstract getRiskTabService(): BaseRiskTabService;
    abstract initAnalytics(): void;
    abstract doComponentInit(): void;
    abstract propagatePostLoadUpdates(): void;
    abstract getGoogleAnalyticsEventLabel(): string;

}
