import {BuildingDetailsComponent} from './component/building-details/building-details.component';
import {StructureDetailsComponent} from './component/structure-details/structure-details.component';

export class BuildingConfig {

    public static readonly CONFIG_NAME = 'Personal Building';
    public static readonly DEFAULT_STATUS = 'Not yet completed';
    public static readonly DEFAULT_DESCRIPTION = '';
    public static readonly DIALOG_CONFIRMATION_CLOSE_MESSAGE = 'Are you sure you want to remove this building cover?';
    public static readonly DIALOG_CONFIRMATION_CLOSE_TITLE = '';
    public static readonly DIALOG_CONFIRMATION_WIDTH = '45%';

    public static readonly TAB_COMPONENT_CONFIG = {
        'configTab1': BuildingDetailsComponent,
        'configTab2': StructureDetailsComponent
    };

    public static readonly TAB_COMPONENT_HEADING_CONFIG = {
        'configTab1': 'Building details',
        'configTab2': 'Structure details'
    };

    public static readonly TAB_COMPONENT_SUBHEADING_CONFIG = {
        'configTab1': '',
        'configTab2': ''
    };

    public static readonly TAB_COMPONENT_ACTIVE_STATUS_CONFIG = {
        'configTab1': true,
        'configTab2': false
    };

    public static readonly TAB_COMPONENT_METADATA_CONFIG = {
        'configTab1': {},
        'configTab2': {}
    };

 }
