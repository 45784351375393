import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {MarketingComponent} from './marketing/marketing/marketing.component';
import {MarketingCallCentreComponent} from './marketing/marketing-call-centre/marketing-call-centre.component';
import {QuoteComponent} from './quote/component/quote.component';
import {PremiumComponent} from './premium/component/premium.component';
import {NeedHelpThankYouMessageComponent} from './footer/need-help/thank-you-message/thank-you-message.component';
import {AppManagerComponent} from './mstiappmanager/app_manager.component';
import {PersonalComponent} from './personal/component/personal.component';
import {SelectCoverComponent} from './covers/select-cover.component';
import {DisclosureEntryComponent} from './disclosure/disclosure-entry.component';
import {AdminFeeComponent} from './admin-fee/admin-fee.component';

const appRoutes: Routes = [
  {path: 'marketing', component: MarketingComponent, data: {routeIdx: 'marketing'}},
  {path: 'marketing-call-centre', component: MarketingCallCentreComponent, data: {routeIdx: 'marketing-call-centre'}},
  {path: 'app-personal', component: PersonalComponent, data: {routeIdx: 'app-personal'}},
  {path: 'quote', component: QuoteComponent, data: {routeIdx: 'quote'}},
  {path: 'premium', component: PremiumComponent, data: {routeIdx: 'premium'}},
  {path: 'premium-quote', component: PremiumComponent, data: {routeIdx: 'premium-quote'}},
  {
    path: 'need-help-thankyou-message', component: NeedHelpThankYouMessageComponent,
    data: {routeIdx: 'need-help-thankyou-message'}
  },
  {path: 'app-select-cover', component: SelectCoverComponent, data: {routeIdx: 'app-select-cover'}},
  {path: 'app-disclosure-entry', component: DisclosureEntryComponent},
  {path: 'app-admin-fee', component: AdminFeeComponent},
  {path: 'app-manager-downtime', component: AppManagerComponent, data: {routeIdx: 'appmanager-downtime'}}];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
