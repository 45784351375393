export class QuoteCoverItemModel {
    constructor(
        public coverType: string,
        public description: string,
        public riskName: string,
        public tagLine: string,
        public iconUrl: string,
        public isActive: boolean,
        public order: number,
        public isSelected: boolean,
        public metaData: any
    ) {}
}
