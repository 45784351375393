import {Component, Injector} from '@angular/core';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';
import {ImageService} from '../../../../../services/image.service';

@Component({
    selector: 'app-currency-dynamic-form-control',
    templateUrl: './currency-dynamic-form-control.component.html',
    styleUrls: ['./currency-dynamic-control.component.scss']
})
export class CurrencyDynamicFormControlComponent extends BaseDynamicFormControl<
    string
> {
    maxLength = 10;

    type: string; // TODO - Confirm this field is used
    infoIcon: string;
    private imageService: ImageService;

    constructor(injector: Injector) {
        super(injector);
        this.imageService = injector.get(ImageService);
    }

    doInit() {
        this.infoIcon = this.imageService.getImage('INFO-ICON');
    }

    updateFormControlValue(inputValue: any) {
        // TODO - Implement me
    }

    clearValue() {
        this.dynamicControlModel.value = null;
    }

    onKeyDown(event) {
        const { keyCode, target } = event;

        if (target.value.length >= this.maxLength && keyCode !== 8) {
            event.preventDefault();
        }
    }
}
