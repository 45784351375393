import {NgModule} from '@angular/core';
import {InputValueValidator} from './checkValue.directive';
import {PhoneNumberValidator} from './phoneValidator.directive';
import {EmailAddressValidator} from './emailValidators.directive';
import {TwoDigitDecimalNumberDirective} from './two-digit-decimal-number.directive';


@NgModule({
    declarations: [
        InputValueValidator,
        PhoneNumberValidator,
        EmailAddressValidator,
        TwoDigitDecimalNumberDirective
    ],
    exports: [
        InputValueValidator,
        PhoneNumberValidator,
        EmailAddressValidator,
        TwoDigitDecimalNumberDirective
    ]
})
export class ValidationModule {
}
