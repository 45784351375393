import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {CardModule} from '../../card/card.module';
import {PersonalBelongingsDetailsComponent} from './component/personal-belongings-details/personal-belongings-details.component';
import {PersonalBelongingsService} from './service/personal-belongings.service';
import {PersonalBelongingsUrlService} from './service/personal-belongings-url.service';

@NgModule({
    declarations: [
        PersonalBelongingsDetailsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        CardModule
    ],
    exports: [],
    providers: [PersonalBelongingsService, PersonalBelongingsUrlService],
    entryComponents: [PersonalBelongingsDetailsComponent]
})
export class PersonalBelongingsModule {
}
