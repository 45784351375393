import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ImageService} from '../../services/image.service';

@Component({
    selector: 'app-msti-confirmation-dialog',
    templateUrl: './msti-confirmation-dialog.component.html',
    styleUrls: ['./msti-confirmation-dialog.component.scss']
})
export class MstiConfirmationDialogComponent implements OnInit {
    closeIcon;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private imageService: ImageService) {
        this.initializeIcons(imageService);
    }

    ngOnInit() {
    }

    initializeIcons(imageService: ImageService) {
        this.closeIcon = imageService.getImage('CLOSE-ICON');
    }

}
