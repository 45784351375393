import {RiskOptionModel} from './risk.option.model';
import {ImageService} from '../services/image.service';
import {Injectable} from '@angular/core';

@Injectable()
export class RiskOptionDataService {

    public static readonly RISK_DESCRIPTION_VEHICLE = 'Car Insurance';
    public static readonly RISK_DESCRIPTION_CONTENTS = 'Home Contents \& Personal Belongings Insurance';
    public static readonly RISK_DESCRIPTION_BUILDING = 'Building Insurance';

    public static readonly RISK_NAME_VEHICLE = 'Personal Motor';
    public static readonly RISK_NAME_CONTENTS = 'Personal Contents';
    public static readonly RISK_NAME_BUILDING = 'Personal Building';

    riskOptionsMap: Map<string, RiskOptionModel> = new Map<string, RiskOptionModel>();

    private initialised: boolean;

    constructor(private imageService: ImageService) {
        this.initialised = false;
    }

    private initialize() {
        this.riskOptionsMap.set(
            RiskOptionDataService.RISK_NAME_VEHICLE,
            new RiskOptionModel(
                RiskOptionDataService.RISK_DESCRIPTION_VEHICLE,
                this.imageService.getImage('MSTI-VEHICLE'),
                'Car',
                'car',
                RiskOptionDataService.RISK_NAME_VEHICLE,
                'Get peace of mind knowing your car is covered by Momentum Car Insurance, with a premium tailored to your profile. You can also insure motorcycles, caravans and trailers, and choose between comprehensive, liability and limited cover.'
                , false
                , 0
                , 1
            )
        );

        this.riskOptionsMap.set(
            RiskOptionDataService.RISK_NAME_CONTENTS,
            new RiskOptionModel(
                RiskOptionDataService.RISK_DESCRIPTION_CONTENTS,
                this.imageService.getImage('MSTI-HOME-CONTENT'),
                'Contents',
                'Contents',
                RiskOptionDataService.RISK_NAME_CONTENTS,
                'Insure items in your home and the valuables you carry when you leave the house. You need to have Home Contents Insurance to be currentlyInsured for Personal Belongings.'
                , false
                , 0
                , 2
            )
        );

        this.riskOptionsMap.set(
            RiskOptionDataService.RISK_NAME_BUILDING,
            new RiskOptionModel(
                RiskOptionDataService.RISK_DESCRIPTION_BUILDING,
                this.imageService.getImage('MSTI-BUILDING'),
                'Building',
                'Building',
                RiskOptionDataService.RISK_NAME_BUILDING,
                'Be prepared for any structural damage caused by rebuilding, repairing, or renovations to your home with Momentum\'s short-term insurance.'
                , false
                , 0
                , 3
            )
        );
/*
        this.riskOptionsMap.set(
            'Watercraft',
            new RiskOptionModel(
                'My watercraft',
                this.imageService.getImage('TEMP_260_150'),
                'Watercraft',
                'Watercraft',
                'Watercraft',
                'The key to making your home sweet home a reality is to start by setting a savings goal.'
                , false
                , 0
                , 8
            )
        );

        this.riskOptionsMap.set(
            'Caravan',
            new RiskOptionModel(
                'My caravan',
                this.imageService.getImage('TEMP_260_150'),
                'Caravan',
                'caravan',
                'Caravan',
                'The key to making your home sweet home a reality is to start by setting a savings goal.'
                , false
                , 0
                , 5
            )
        );

        this.riskOptionsMap.set(
            'Trailer',
            new RiskOptionModel(
                'My trailer',
                this.imageService.getImage('TEMP_260_150'),
                'Trailer',
                'Trailer',
                'Trailer',
                'The key to making your home sweet home a reality is to start by setting a savings goal.'
                , false
                , 0
                , 6
            )
        );

        this.riskOptionsMap.set(
            'Motorcycle',
            new RiskOptionModel(
                'My motorcycle',
                this.imageService.getImage('TEMP_260_150'),
                'Motorcycle',
                'Motorcycle',
                'Motorcycle',
                'The key to making your home sweet home a reality is to start by setting a savings goal.'
                , false
                , 0
                , 7
            )
        );*/

        this.riskOptionsMap.set(
            'AllRisk',
            new RiskOptionModel(
                'All risk',
                this.imageService.getImage('MSTI-ALL-RISK'),
                'allrisk',
                'allrisk',
                'AllRisk',
                'We will insure your personal belongings such as your jewellery, handbag, cell phone, travel luggage, and camera, wherever you are.'
                , false
                , 0
                , 4
            )
        );

        this.riskOptionsMap.set(
            'PersonalRisk',
            new RiskOptionModel(
                'Personal accident risk',
                this.imageService.getImage('TEMP_260_150'),
                'personalrisk',
                'personalrisk',
                'PersonalRisk',
                'The key to making your home sweet home a reality is to start by setting a savings goal.'
                , false
                , 0
                , 9
            )
        );

        this.initialised = true;
    }

    public getRiskOptionsForGivenRisks(risks: string[]) {
        if (this.initialised === false) {
            this.initialize();
        }

        const options: RiskOptionModel[] = [];
        for (const risk of risks) {
            options.push(this.riskOptionsMap.get(risk));
        }
        return options;
    }

}
