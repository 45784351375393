import {Injectable, Injector} from '@angular/core';
import {Subject} from 'rxjs';
import {BaseRiskService} from '../../base/service/base-risk.service';
import {SaveClientResponseModel} from '../../../../personal/model/SaveClientModels/save-client-response.model';
import {BaseCifRiskModel} from '../../base/model/base-cif-risk.model';
import {CifBuildingModel} from '../model/cif/cif-building.model';
import {LookupService} from '../../../../shared/lookup/lookup.service';
import {BuildingDetailsModel} from '../model/building-details.model';
import {AddressTypeModel} from '../../../../shared/msti-agm/model/address-type.model';
import {LookupFilteredDropDownModel} from '../../../../shared/lookup/lookup.filtered.drop.down.model';
import {AddressLookupModel} from '../../../../shared/lookup/address-lookup.model';
import {BuildingStructureDetailsModel} from '../model/building-structure-details.model';
import {BuildingUrlService} from './building-url.service';
import {CifClientInfoModel} from '../../../model/cif/cif-client-info.model';
import {CifQuoteModel} from '../../../model/cif/cif-quote.model';
import {BuildingModel} from '../model/building.model';
import {Logger} from '../../../../shared/utilities/logger';
import {Level} from '../../../../shared/utilities/logger-level';
import {NumberLookupModel} from '../../../../shared/lookup/number-lookup.model';

@Injectable()
export class BuildingService extends BaseRiskService {

  public static readonly VALUE_ADD_NEW_ADDRESS = 'Add new address';
  private static readonly TAB_INDEX_CONTENT_DETAILS = 0;
  private static readonly TAB_INDEX_STRUCTURE_DETAILS = 1;
  private buildingAddressMap: Map<string, AddressTypeModel>;

  constructor(
    injector: Injector,
    private lookupService: LookupService,
    private buildingUrlService: BuildingUrlService
  ) {
    super(injector);
    this.buildingAddressMap = new Map<string, AddressTypeModel>();
  }

  doDestroy(): void {
    this.buildingAddressMap = new Map<string, AddressTypeModel>();
  }

  instantiateRiskModel(client: SaveClientResponseModel): BaseCifRiskModel {
    return new CifBuildingModel(client);
  }

  getAddRiskToQuoteURL(quoteId): string {
    return this.buildingUrlService.getSaveBuildingURL(quoteId);
  }

  getDeleteRiskURL(quoteId: string, riskId: string): string {
    return this.buildingUrlService.getDeleteBuildingURL(quoteId, riskId);
  }

  private initBuildingDetailsModel(cardId: string) {
    if (!this.cardModels[cardId].buildingDetailsModel) {
      this.cardModels[cardId].buildingDetailsModel = new BuildingDetailsModel();
    }
  }

  private initStructuresDetailsModel(cardId: string) {
    if (!this.cardModels[cardId].structureDetailsModel) {
      this.cardModels[cardId].structureDetailsModel = new BuildingStructureDetailsModel();
    }
  }

  public getBuildingDetailsModel(cardId: string): BuildingDetailsModel {
    if (!this.cardModels[cardId]) {
      this.initCardModel(cardId);
    }

    if (!this.cardModels[cardId].buildingDetailsModel) {
      this.initBuildingDetailsModel(cardId);
    }

    return this.cardModels[cardId].buildingDetailsModel;
  }

  public getRiskID(cardId: string): string {
    if (this.cardModels[cardId]) {
      return this.cardModels[cardId].cifRiskModel.riskResourceRef;
    } else {
      return null;
    }
  }

  public getStructureDetailsModel(cardId: string): BuildingStructureDetailsModel {
    if (!this.cardModels[cardId]) {
      this.initCardModel(cardId);
    }

    if (!this.cardModels[cardId].structureDetailsModel) {
      this.initStructuresDetailsModel(cardId);
    }

    return this.cardModels[cardId].structureDetailsModel;
  }

  public addAddress(cardId: string, address: AddressTypeModel) {
    this.buildingAddressMap.set(cardId, address);
  }

  public removeAddress(cardId: string) {
    this.buildingAddressMap.delete(cardId);
  }

  public getAddresses(): AddressTypeModel[] {
    return Array.from(this.buildingAddressMap.values());
  }

  public getAddressLookups(): LookupFilteredDropDownModel {
    const addressLookups = [];

    const dummyAddressValue = new AddressTypeModel('DUMMY_VALUE');
    dummyAddressValue.formattedAddressLine = BuildingService.VALUE_ADD_NEW_ADDRESS;
    const dummyAddressLookupValue = new AddressLookupModel('0', dummyAddressValue);
    addressLookups.push(dummyAddressLookupValue);

    Array.from(this.buildingAddressMap.entries()).forEach(entry => {
      const addressLookupModel = new AddressLookupModel(entry[0], entry[1]);
      addressLookups.push(addressLookupModel);
    });

    return new LookupFilteredDropDownModel(addressLookups);
  }

  async getOwnershipTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_OWNERSHIP_TYPE);
  }

  async getAreaTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_AREA_TYPE);
  }

  async getNeighbourSetups() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_NEIGHBOUR);
  }

  async getOccupancies() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_OCCUPANCY);
  }

  async getOccupantTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_OCCUPIED_BY);
  }

  async getStructureTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_STRUCTURE_TYPE);
  }

  async getWallMaterials() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_STRUCTURE_WALL_MATERIAL);
  }

  async getRoofMaterials() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_STRUCTURE_ROOF_MATERIAL);
  }

  async getSecurityFeatures() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_STRUCTURE_SECURITY_FEATURE);
  }

  async getLapaDistances() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_STRUCTURE_LAPA_DISTANCE);
  }

  async getRoofPitch() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_STRUCTURE_ROOF_PITCH_TYPE);
  }

  async getStoreysTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_STRUCTURE_NUMBER_OF_STOREYS_TYPE);
  }

  async getStoreysOccupancyTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_BUILDING_STRUCTURE_NUMBER_OF_STOREYS_OCCUPIED);
  }

  doFormDataPersist(data: any) {
    if (data.tabIndex === BuildingService.TAB_INDEX_CONTENT_DETAILS) {
      this.initBuildingDetailsModel(data.cardId);
      this.cardModels[data.cardId].buildingDetailsModel.populateFromFormData(data.formData);
    } else if (data.tabIndex === BuildingService.TAB_INDEX_STRUCTURE_DETAILS) {
      this.initStructuresDetailsModel(data.cardId);
      this.cardModels[data.cardId].structureDetailsModel.populateFromFormData(data.formData);
      this.saveOrUpdateBuilding(data);
    }
  }

  saveOrUpdateBuilding(data: any) {
    const quoteId = this.quoteService.quoteId;
    const buildingModel = this.getBuildingDetailsModel(data.cardId);
    const structureModel = this.getStructureDetailsModel(data.cardId);
    const riskId = this.getRiskID(data.cardId);

    const clientInfo = CifClientInfoModel.create({
      clientResourceRef: this.userService.getUser().clientResourceRef,
      clientName: this.userService.getUser().firstName + ' ' + this.userService.getUser().firstName,
      clientNumber: this.userService.getUser().clientNumber
    });

    const quoteModel = CifQuoteModel.getMappedBuildingModel(clientInfo, BuildingModel.create(riskId, buildingModel, structureModel));
    if (riskId) {// Update building
      this.updateBuilding(quoteModel, data.cardId, quoteId, riskId);
    } else { // Save building
      if (quoteId) {
        this.saveBuilding(quoteModel, data.cardId, quoteId);
      } else {
        this.saveBuildingAndQuote(quoteModel, data.cardId);
      }
    }
  }

  private saveBuildingAndQuote(quoteModel: CifQuoteModel, cardId: string) {
    this.quoteService.saveQuote(quoteModel).then(response => {
      this.processRiskIdsInQuoteSaveResponse(response.riskReferences, cardId);
    });
  }

  private saveBuilding(quoteModel: CifQuoteModel, cardId: string, quoteId: string) {
    if (quoteModel.buildingRisks.length === 1) {
      this.saveBuildingToCif(quoteId, quoteModel.buildingRisks[0]).then(response => {
        this.processRiskIdInSaveResponse(response, cardId);
      });
    }
  }

  private updateBuilding(quoteModel: CifQuoteModel, cardId: string, quoteId: string, riskId: string | any) {
    if (quoteModel.buildingRisks.length === 1) {
      this.updateBuildingToCif(quoteId, quoteModel.buildingRisks[0], riskId).then(response => {

        this.processRiskIdInSaveResponse(response.riskResourceRef, cardId);
        this.cardModels[cardId].structureDetailsModel.populateStructureFromCifModel(response.structure, this.lookupService);
      });
    }
  }

  public async saveBuildingToCif(quoteId: string, buildingModel: CifBuildingModel): Promise<string> {
    this.loaderService.show();
    let savedRiskId = '';
    await this.mstiService.postWithAbAuth(
      this.buildingUrlService.getSaveBuildingURL(quoteId), buildingModel
    ).toPromise().then((response: any) => {
      Logger.log(Level.LOG, 'Save building response:', response);
      savedRiskId = response.riskResourceRef;
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while saving building', error);
      this.mstiErrorService.handleError(error);
    }).then(() => {
      this.loaderService.hide();
    });

    return savedRiskId;
  }

  public async updateBuildingToCif(quoteId: string, buildingModel: CifBuildingModel, riskId: string): Promise<CifBuildingModel> {
    this.loaderService.show();
    let building: CifBuildingModel;
    await this.mstiService.putWithAbAuth(
      this.buildingUrlService.getUpdateBuildingURL(quoteId, riskId), buildingModel
    ).toPromise().then((response: any) => {
      Logger.log(Level.LOG, 'update building response:', response);

      building = response;
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while update building', error);
      this.mstiErrorService.handleError(error);
    }).then(() => {
      this.loaderService.hide();
    });

    return building;
  }

  retrieveRiskDataFromCif(riskId: string, cardId: string): Promise<any> {
    this.loaderService.show();
    this.initCardModel(cardId);
    const buildingCompleteSubject = new Subject<any>();
    this.mstiService.getWithAbAuth(this.buildingUrlService.getRetrieveBuildingURL(this.quoteService.quoteId, riskId
    )).subscribe(async (response: CifBuildingModel) => {
      this.cardModels[cardId].cifBuildingDetailsModel =
        Object.assign(new CifBuildingModel(this.personalService.getClient()), response);
      this.cardModels[cardId].cifRiskModel.riskResourceRef = response.riskResourceRef;
      await this.populateBuildingFromCifModel(cardId);
      this.loaderService.hide();
      buildingCompleteSubject.complete();
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while retrieving building details', error);
      this.loaderService.hide();
      buildingCompleteSubject.error(error);
      this.mstiErrorService.handleError(error);
    });
    return buildingCompleteSubject.toPromise();
  }

  private async populateBuildingFromCifModel(cardId: string) {
    this.initBuildingDetailsModel(cardId);
    this.initStructuresDetailsModel(cardId);
    await this.cardModels[cardId].buildingDetailsModel.populateBuildingFromCifModel(this.cardModels[cardId].cifBuildingDetailsModel,
      this.lookupService);
    await this.cardModels[cardId].structureDetailsModel.populateStructureFromCifModel
    (this.cardModels[cardId].cifBuildingDetailsModel, this.lookupService);
  }

  getExistingCardHeading(cardId: string): string {
    const buildingDetailsModel = this.cardModels[cardId].buildingDetailsModel;

    if (buildingDetailsModel) {
      return buildingDetailsModel.buildingAddress ? buildingDetailsModel.buildingAddress.formattedAddressLine : '';
    } else {
      return '';
    }
  }

  getNumericDigits(): NumberLookupModel[] {
    const numberValues = [...Array(10).keys()];

    return numberValues.map((number) => {
      return new NumberLookupModel(number);
    });
  }

}
