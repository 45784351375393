import {Injectable} from '@angular/core';
import {BrokerDetailsService} from '../broker-common/broker-details.service';
import {RiskOptionService} from '../risk/risk-option.service';
import {PersonalService} from '../../personal/service/personal.service';
import {MSTIConfiguration} from '../msti.config';
import {SessionStorageService} from './session-storage.service';
import {QuoteService} from '../../quote/service/quote.service';
import {ContentsService} from '../../quote/sections/contents/service/contents.service';
import {RoutingService} from './routing.service';
import {LoaderService} from '../screen-loader/loader.service';
import {VehicleService} from '../../quote/sections/vehicle/service/vehicle.service';
import {BuildingService} from '../../quote/sections/building/service/building.service';


@Injectable()
export class LogoutService {

    constructor(
        private sessionStorageService: SessionStorageService,
        private userService: BrokerDetailsService,
        private riskOptionService: RiskOptionService,
        private personalService: PersonalService,
        private quoteService: QuoteService,
        private vehicleService: VehicleService,
        private contentsService: ContentsService,
        private buildingService: BuildingService,
        private routingService: RoutingService,
        private loaderService: LoaderService
    ) {
    }

    logout() {
        this.sessionStorageService.clearSessionStorage();

        if (MSTIConfiguration.constants.local === true) {
            this.userService.setUser(null);
            this.personalService.clientDetails = null;
            this.riskOptionService.clearAvailableOptions();
            this.quoteService.destroy();
            this.vehicleService.destroy();
            this.contentsService.destroy();
            this.buildingService.destroy();
            this.routingService.route('/login');
        } else {
            this.sessionStorageService.setItem(SessionStorageService.KEY_IS_LOGOUT, SessionStorageService.VALUE_TRUE);
            $('#logoutlink')[0].click();
        }

        if (MSTIConfiguration.constants.reloadAfterLogout === true) {
            window.location.reload(); // Force resetting of the inactivity timers
        }
    }

    backToPortfolio() {
        this.loaderService.show();
        setTimeout(() => {
            this.loaderService.hide();
            if (MSTIConfiguration.constants.local === false) {
                window.location.href = '/wps/portal/momV1'; // TODO - Should this be hardcoded?
            } else {
                this.logout();
            }
        }, 3000);

    }

    backToMomCoZa() {
        this.loaderService.show();
        setTimeout(() => {
            this.userService.setUser(null);
            this.personalService.clientDetails = null;
            this.riskOptionService.clearAvailableOptions();
            this.quoteService.destroy();
            this.vehicleService.destroy();
            this.contentsService.destroy();
            this.buildingService.destroy();
            this.loaderService.hide();
            window.location.replace( 'https://www.momentum.co.za/for'); // https://www.momentum.co.za/for';
        }, 3000);

    }

    backToPreLoginMarketing() {
        window.location.href = '/wps/portal/momV1';
    }
}
