import {LookupModel} from './lookup.model';
import {AddressTypeModel} from '../msti-agm/model/address-type.model';

export class AddressLookupModel extends LookupModel {

    id: string;
    value: AddressTypeModel;

    constructor(id: string, value: AddressTypeModel) {
        super();
        this.id = id;
        this.value = value;
    }

    public getCode(): string {
        return this.id;
    }

    public getDescription(): string {
        return this.value.formattedAddressLine;
    }

}
