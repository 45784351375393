import {CifVehicleDetailsModel} from '../cif-vehicle-details.model';
import {CifSecurityFeatureModel} from '../cif-security-feature.model';
import {BaseRiskModel} from '../../../../base/model/base-risk.model';
import {PifVehicleAddressModel} from '../../pif/pif-vehicle-address.model';

export class Vehicle extends BaseRiskModel {

  constructor(public riskResourceRef: string, public riskDescription: string,
              public sectionCode: string, public usageCode: string,
              public possessionCode: string, public modificationCode: string, // Mandatory
              public vehicle: CifVehicleDetailsModel, public securityFeatures?: (CifSecurityFeatureModel)[] | null,
              public riskAddress?: PifVehicleAddressModel | null
              // public addresses?: (CifVehicleAddressModel)[] | null,
  ) {
    super();
  }

  static create(vehicle: Vehicle) {
    return new Vehicle(vehicle.riskResourceRef, vehicle.riskDescription, vehicle.sectionCode, vehicle.usageCode, vehicle.possessionCode,
      vehicle.modificationCode, vehicle.vehicle, vehicle.securityFeatures, vehicle.riskAddress);
  }

}
