import {Component, Injector} from '@angular/core';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';

@Component({
    selector: 'app-textbox-numeric-dynamic-form-control',
    templateUrl: './textbox-numeric-dynamic-form-control.component.html',
    styleUrls: ['./textbox-numeric-dynamic-control.component.scss']
})
export class TextboxNumericDynamicFormControlComponent extends BaseDynamicFormControl<string> {

    type: string; // TODO - Confirm this field is used
    maxLengthAttribute = '';

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    doInit() {
        this.maxLengthAttribute =
            ((this.dynamicControlModel.maxLength && this.dynamicControlModel.maxLength) > 0
                ? String(this.dynamicControlModel.maxLength)
                : '');
    }

    updateFormControlValue(inputValue: any) {
        // TODO - Implement me
    }

    clearValue() {
        this.dynamicControlModel.value = null;
    }

    onKeyDown(event) {
        const { keyCode, target } = event;

        if (this.maxLengthAttribute !== '') {
            if (target.value.length >= Number(this.maxLengthAttribute) && keyCode !== 8) {
                event.preventDefault();
            }
        }
    }

}
