export class PreviousClaimsModel {
    public event: string; // DELETE ,CREATE, UPDATE
    public claimId: number;
    public riskCode: string;
    public dateOfClaim: string;
    public typeOfClaimCode: string;
    public claimAmount: string;
    public previousClaimHistoryId: string;
    public sectionCode: string;
    public customeEntry: boolean;

    constructor(claimId: number, event: string) {
        this.claimId = claimId;
        this.event = event;

    }
}
