import {Component, OnInit} from '@angular/core';
import {BrokerDetailsService} from '../shared/broker-common/broker-details.service';
import {BrokerDetailsModel} from '../shared/broker-common/broker-details.model';
import {isNull} from 'util';
import {LogoutService} from '../shared/services/logout.service';
import {ImageService} from '../shared/services/image.service';
import {GoogleAnalyticsService} from '../shared/google-analytics/google-analytics.service';
import {NeedHelpService} from '../footer/need-help/services/need-help.service';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

    logoImagePath: string;
    user: BrokerDetailsModel;
    hasUserLoggedIn: boolean;

    constructor(
        private userService: BrokerDetailsService,
        private logoutService: LogoutService,
        private imageService: ImageService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private needHelpService: NeedHelpService,
        public dialog: MatDialog,
    ) {
        this.logoImagePath = this.imageService.getImage('LOGO');
    }

    ngOnInit() {
        this.userService.userChanged.subscribe((loggedInUser: BrokerDetailsModel) => {
            this.user = loggedInUser;
            if (isNull(this.user)) {
                this.hasUserLoggedIn = false;
            } else {
                this.hasUserLoggedIn = true;
            }
        });

    }

    backToPortfolio() {
        this.googleAnalyticsService.fireGoogleAnalyticsForReturnToPortfolio(this.needHelpService.getCurrentPage());
        this.logoutService.backToPortfolio();
    }

    exit() {
       /* const dialogRef = this.dialog.open(ExitPopupDialogComponent, {
            width: '52%',
            panelClass: 'exit-popup-dialog',
        });*/
    }
}
