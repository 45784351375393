export class PremiumQuoteSummaryItemModel {
    constructor(
        public coverType: string,
        public description: string,
        public hint: string,
        public sumInsured: string,
        public excess: string,
        public premium: string,
        public isHeading: boolean = false,
        public isRiskDescriptionRow: boolean = false,
        public riskAddress: string = '',
    ) {}
}

