import {Injectable} from '@angular/core';
import {MSTIService} from '../services/msti.service';
import {MSTIConfiguration} from '../msti.config';
import {LoaderService} from '../screen-loader/loader.service';
import {UrlService} from '../services/url.service';
import {DefaultLookupModel} from './default-lookup.model';
import {LookupModel} from './lookup.model';
import {Logger} from '../utilities/logger';
import {Level} from '../utilities/logger-level';

@Injectable({
    providedIn: 'root'
})
export class LookupService {

    public static readonly GROUP_GENDER = 'GENDER_TYPE';
    public static readonly GROUP_MARITAL_STATUS = 'MARITAL_STATUS';
    public static readonly GROUP_OCCUPATION = 'OCCUPATION_TYPE';
    public static readonly GROUP_TITLE = 'TITLE_TYPE';
    public static readonly GROUP_FINANCE_HOUSE = 'FINANCE_HOUSE';
    public static readonly GROUP_RISK_STORAGE_TYPE = 'RISK_STORAGE_TYPE';
    public static readonly GROUP_REGULAR_DRIVER_RELATED_INSURER = 'REG_DRI_RELATED_INSURER';
    public static readonly GROUP_LICENSE_TYPE = 'LICENSE_TYPE';
    public static readonly GROUP_LICENSE_LIMITATIONS = 'LICENSE_LILITATIONS';
    public static readonly GROUP_PERIOD_WITHOUT_CVR = 'PERIOD_WITHOUT_CVR';
    public static readonly GROUP_INS_NAME_LIST = 'INS_NAME_LIST';
    public static readonly GROUP_PREV_CANCELLATION = 'PREV_CANCELLATION';
    public static readonly GROUP_RISK_TYPE = 'RISK_TYPE';
    public static readonly GROUP_CLAIM_TYPE = 'CLAIM_TYPE';

    public static readonly GROUP_VEHICLE_CONDITION = 'VEHICLE_CODE';
    public static readonly GROUP_VEHICLE_COLOUR = 'VEHICLE_COLOR';
    public static readonly GROUP_VEHICLE_TYPE = 'VEHICLE_TYPE';
    public static readonly GROUP_VEHICLE_POSSESSION = 'VEHICLE_POSSESSION';
    public static readonly GROUP_VEHICLE_MODIFICATION = 'VEH_MODIFICATION';
    public static readonly GROUP_VEHICLE_SECURITY_DEVICE = 'OTH_SECURITY_DEVICE';
    public static readonly GROUP_VEHICLE_AREA_TYPE = 'VEH_AREA_TYPE';

    public static readonly GROUP_CONTENTS_AREA_TYPE = 'CON_AREA_TYPE';
    public static readonly GROUP_CONTENTS_NEIGHBOUR = 'CON_NEIGHBOUR';
    public static readonly GROUP_CONTENTS_OCCUPANCY = 'CON_OCCUPANCY';
    public static readonly GROUP_CONTENTS_OCCUPIED_BY = 'CON_OCCUPIED_BY';
    public static readonly GROUP_CONTENTS_ALL_RISK_SMI_CODES = 'ALL_RISK_SMI_CODES';

    public static readonly GROUP_CONTENTS_STRUCTURE_TYPE = 'CON_DWELL_TYPE';
    public static readonly GROUP_CONTENTS_STRUCTURE_WALL_MATERIAL = 'CON_CONST';
    public static readonly GROUP_CONTENTS_STRUCTURE_ROOF_MATERIAL = 'CON_ROOF_TYPE';
    public static readonly GROUP_CONTENTS_STRUCTURE_SECURITY_FEATURE = 'CON_SECURITY_DEVICE';
    public static readonly GROUP_CONTENTS_STRUCTURE_LAPA_DISTANCE = 'CON_LAPA';

    public static readonly GROUP_BUILDING_OWNERSHIP_TYPE = 'BUILD_OWN';
    public static readonly GROUP_BUILDING_AREA_TYPE = 'BUILD_AREA_TYPE';
    public static readonly GROUP_BUILDING_NEIGHBOUR = 'BUILD_NEIGHBOUR';
    public static readonly GROUP_BUILDING_OCCUPANCY = 'BUILD_OCCUPANCY';
    public static readonly GROUP_BUILDING_OCCUPIED_BY = 'BUILD_OCCUPIED_BY';
    public static readonly GROUP_BUILDING_STRUCTURE_TYPE = 'BUILD_TYPE';
    public static readonly GROUP_BUILDING_STRUCTURE_WALL_MATERIAL = 'BUILD_CONST';
    public static readonly GROUP_BUILDING_STRUCTURE_ROOF_MATERIAL = 'BUILD_ROOF_TYPE';
    public static readonly GROUP_BUILDING_STRUCTURE_SECURITY_FEATURE = 'BUILD_SECURITY_DEVICE';
    public static readonly GROUP_BUILDING_STRUCTURE_LAPA_DISTANCE = 'BUILD_LAPA';

    public static readonly GROUP_BUILDING_STRUCTURE_ROOF_PITCH_TYPE = 'BUILD_ROOF_PITCH';
    public static readonly GROUP_BUILDING_STRUCTURE_NUMBER_OF_STOREYS_TYPE = 'BUILD_STOREYS';
    public static readonly GROUP_BUILDING_STRUCTURE_NUMBER_OF_STOREYS_OCCUPIED = 'BUILD_STOREYS_OCC';

    public static ALL_GROUPS: string[] = [
        LookupService.GROUP_GENDER,
        LookupService.GROUP_MARITAL_STATUS,
        LookupService.GROUP_OCCUPATION,
        LookupService.GROUP_TITLE
    ];

    private cache: Map<string, any>;

    constructor(
        private mstiService: MSTIService,
        private loaderService: LoaderService,
        private urlService: UrlService
    ) {
        this.cache = new Map<string, any>();
    }

    public cacheLookupData() {
        Logger.log(Level.LOG, 'Pre-caching started.......');
        for (const group of LookupService.ALL_GROUPS) {
            Logger.log(Level.LOG, 'Caching lookup data for group.......', group);
            this.getLookup(group);
        }
        Logger.log(Level.LOG, 'Pre-caching ended.......');
    }

    public async getLookup(lookupGroup: string) {
        if (!this.cache.has(lookupGroup) || this.cache.get(lookupGroup) === undefined || this.cache.get(lookupGroup) === null) {
            Logger.log(Level.LOG, 'Fetch and retrieve....', lookupGroup);
            this.cache.set(lookupGroup, await this.callService(lookupGroup));
        }
        Logger.log(Level.LOG, 'Retrieving from cache....', lookupGroup);
        return this.cache.get(lookupGroup);
    }

    private async callService(lookupGroup: string) {
        this.loaderService.show();

        const getLookupURL = this.urlService.getUrl(UrlService.KEY_LOOKUPS) +
            '?group=' + lookupGroup + '&scope=' + MSTIConfiguration.lookupsScope;
        let lookup: DefaultLookupModel[];

        await this.mstiService.get(getLookupURL).toPromise().then(
            (response) => {
                const responseArray = <LookupModel[]>response;
                // Note: this is required in order to preserve the functions on the DefaultLookupModel objects
                lookup = responseArray.map((singleResponse) => {
                    return Object.assign(new DefaultLookupModel(), singleResponse);
                });
            },
            (error) => {
                // TODO - Confirm error handling used here
                Logger.log(Level.ERROR, 'Unable to retrieve lookup ' + lookupGroup, error);
            }
        ).then(() => {
            this.loaderService.hide();
        });

        return lookup;
    }

    public async getCodeFromDescription(description: string, lookupGroup: string) {
        const data = await this.getLookup(lookupGroup);
        const val = data.find(x => x.description === description);
        return val.code;
    }

    public async getDescriptionFromCode(code: string, lookupGroup: string) {
        const data = await this.getLookup(lookupGroup);
        const val = data.find(x => x.code === code);
        return val.description;
    }

    public async getLookUpObjFromCode(code: string, lookupGroup: string) {
        const data = await this.getLookup(lookupGroup);
        const val = data.find(x => x.code === code);
        return val;
    }

}
