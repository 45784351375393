import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {CardModule} from '../../card/card.module';
import {VehicleComponent} from './component/vehicle.component';
import {VehicleService} from './service/vehicle.service';
import {VehicleDescriptionComponent} from './component/vehicle-description/vehicle-description.component';
import {VehicleDetailsComponent} from './component/vehicle-details/vehicle-details.component';
import {VehicleDriverDetailsComponent} from './component/vehicle-driver-details/vehicle-driver-details.component';
import {VehicleUrlService} from './service/vehicle-url.service';

@NgModule({
    declarations: [
        VehicleComponent,
        VehicleDescriptionComponent,
        VehicleDetailsComponent,
        VehicleDriverDetailsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        CardModule
    ],
    exports: [VehicleComponent],
    providers: [VehicleService, VehicleUrlService],
    entryComponents: [VehicleDescriptionComponent, VehicleDetailsComponent, VehicleDriverDetailsComponent]
})
export class VehicleModule {
}
