import {environment} from '../../../environments/environment';

export class EnvironmentConstants {

  name: string;
  production: boolean;
  local: boolean;
  urlPrefix: string;
  brokerBffUrlPrefix: string;
  loaderTimeout: number;
  customLoaderTimeout: number;
  enableAnalytics: boolean;
  enableInactivityTimeout: boolean;
  timerInterval: number;
  heartBeatTimerInterval: number;
  inactivityModalId: string;
  timerDuration: number;
  countdownStartTime: number;
  reloadAfterLogout: boolean;
  navigateToFakeLogin: boolean;
  animationTransitionTime: number;
  enableSmoothScrolling: boolean;
  enableAnimations: boolean;
  enableAutoGrowText: boolean;
  webSocketPath: string;
  stiUrlPrefix: string;
  abSalt: string;

  public init(name: string) {
    console.log('msti config called ', name);
    switch (name) {
      case 'dev': // portal dev / int
        this.loadPortalDev();
        break;
      case 'pre': // portal pre
        this.loadPortalPre();
        break;
      case 'prod': // portal prod
        this.loadPortalProd();
        break;
      default: // local
        this.decideLocalEnv();
        break;
    }
  }

  private loadPortalDev() {
    // dev
    this.name = 'dev';
    this.production = false;
    this.local = false;
    this.urlPrefix = 'https://retail-dev.momentum.co.za/';
    this.brokerBffUrlPrefix = 'https://external-stidev.momentum.co.za/';
    this.loaderTimeout = 1000;
    this.customLoaderTimeout = 6000;
    this.enableAnalytics = true;
    this.enableInactivityTimeout = true;
    this.timerInterval = 1000; // This should always be 1000 (1 second)
    this.heartBeatTimerInterval = 5000; // 5 seconds
    this.inactivityModalId = 'session-expire';
    this.timerDuration = 300; // 5 mins
    this.countdownStartTime = 60; // 60 seconds
    this.reloadAfterLogout = false;
    this.navigateToFakeLogin = false;
    this.animationTransitionTime = 0.35; // 350 Milliseconds
    this.enableSmoothScrolling = true;
    this.enableAnimations = true;
    this.enableAutoGrowText = true;
    this.webSocketPath = 'wss://internal-retail-dev.momentum.co.za/transaction-verifier/ws/verify';
    this.stiUrlPrefix = 'https://internal-stidev.momentum.co.za';
    this.abSalt = '7061737323313233';
    this.print();
  }

  private loadPortalPre() {
    // Pre
    this.name = 'pre';
    this.production = false;
    this.local = false;
    this.urlPrefix = 'https://retail-pre.momentum.co.za/';
    this.brokerBffUrlPrefix = 'https://external-stipre.momentum.co.za/';
    this.loaderTimeout = 1000;
    this.customLoaderTimeout = 6000;
    this.enableAnalytics = true;
    this.enableInactivityTimeout = true;
    this.timerInterval = 1000; // This should always be 1000 (1 second)
    this.heartBeatTimerInterval = 60000; // 1 minute
    this.inactivityModalId = 'session-expire';
    this.timerDuration = 600; // 10 minutes
    this.countdownStartTime = 60; // 1 minute
    this.reloadAfterLogout = false;
    this.navigateToFakeLogin = false;
    this.animationTransitionTime = 0.35; // 350 Milliseconds
    this.enableSmoothScrolling = true;
    this.enableAnimations = true;
    this.enableAutoGrowText = true;
    this.webSocketPath = 'wss://internal-retail-pre.momentum.co.za/transaction-verifier/ws/verify';
    this.stiUrlPrefix = 'https://external-stipre.momentum.co.za';
    this.abSalt = '7061737323313233';
  }

  private loadPortalProd() {
    // Prod
    this.name = 'prod';
    this.production = true;
    this.local = false;
    this.urlPrefix = 'https://retail.momentum.co.za/';
    this.urlPrefix = 'https://external-sti.momentum.co.za/';
    this.loaderTimeout = 1000;
    this.customLoaderTimeout = 6000;
    this.enableAnalytics = true;
    this.enableInactivityTimeout = true;
    this.timerInterval = 1000; // This should always be 1000 (1 second)
    this.heartBeatTimerInterval = 60000; // 1 minute
    this.inactivityModalId = 'session-expire';
    this.timerDuration = 600; // 10 minutes
    this.countdownStartTime = 60; // 1 minute
    this.reloadAfterLogout = false;
    this.navigateToFakeLogin = false;
    this.animationTransitionTime = 0.35; // 350 Milliseconds
    this.enableSmoothScrolling = true;
    this.enableAnimations = true;
    this.enableAutoGrowText = true;
    this.webSocketPath = 'wss://retail.momentum.co.za/transaction-verifier/ws/verify';
    this.stiUrlPrefix = 'https://external-sti.momentum.co.za';
    this.abSalt = '6541737984513233';
  }

  private decideLocalEnv() {

    const localEnv = environment.env;
    console.log('msti config called decideLocal Env ', localEnv);
    switch (localEnv) {
      case 'dev':
        this.loadLocalDev();
        break;
      case 'e2e':
        this.loadLocalE2E();
        break;
      default:
        this.loadLocalDefault();
        break;
    }
    this.print();
  }

  private loadLocalDefault() {
    this.name = 'local';
    this.production = false;
    this.local = true;
    this.urlPrefix = 'https://retail-pre.momentum.co.za/';
    this.brokerBffUrlPrefix = 'https://external-stipre.momentum.co.za/';
    this.loaderTimeout = 1000;
    this.customLoaderTimeout = 0;
    this.enableAnalytics = false;
    this.enableInactivityTimeout = false;
    this.timerInterval = 1000; // This should always be 1000 (1 second)
    this.heartBeatTimerInterval = 60000; // 1 minute
    this.inactivityModalId = 'session-expire';
    this.timerDuration = 600; // 10 minutes
    this.countdownStartTime = 60; // 1 minute
    this.reloadAfterLogout = false;
    this.navigateToFakeLogin = true;
    this.animationTransitionTime = 0.35; // 350 Milliseconds
    this.enableSmoothScrolling = true;
    this.enableAnimations = true;
    this.enableAutoGrowText = true;
    this.webSocketPath = 'wss://internal-retail-pre.momentum.co.za/transaction-verifier/ws/verify';
    this.stiUrlPrefix = 'https://internal-stipre.momentum.co.za';
    this.abSalt = '7061737323313233';
  }

  private loadLocalE2E() {
    this.name = 'e2e';
    this.production = false;
    this.local = true;
    this.urlPrefix = 'http://localhost:9081/';
    this.loaderTimeout = 0;
    this.customLoaderTimeout = 0;
    this.enableAnalytics = false;
    this.enableInactivityTimeout = false;
    this.timerInterval = 1000; // This should always be 1000 (1 second)
    this.heartBeatTimerInterval = 5000; // 5 seconds
    this.inactivityModalId = 'session-expire';
    this.timerDuration = 15; // 15 seconds
    this.countdownStartTime = 5; // 5 seconds
    this.reloadAfterLogout = false;
    this.navigateToFakeLogin = true;
    this.navigateToFakeLogin = true;
    this.animationTransitionTime = 0;
    this.enableSmoothScrolling = false;
    this.enableAnimations = false;
    this.enableAutoGrowText = false;
    this.webSocketPath = 'wss://localhost:9081/transaction-verifier/ws/verify';
    this.stiUrlPrefix = 'http://localhost:9081';
    this.abSalt = '7061737323313233';
  }

  private loadLocalDev() {
    // local dev with wiremock
    this.name = 'wiremock';
    this.production = false;
    this.local = true;
    this.urlPrefix = 'http://localhost:9081/';
    this.loaderTimeout = 0;
    this.customLoaderTimeout = 0;
    this.enableAnalytics = false;
    this.enableInactivityTimeout = false;
    this.timerInterval = 1000; // This should always be 1000 (1 second)
    this.heartBeatTimerInterval = 5000; // 5 seconds
    this.inactivityModalId = 'session-expire';
    this.timerDuration = 15; // 15 seconds
    this.countdownStartTime = 5; // 5 seconds
    this.reloadAfterLogout = true;
    this.navigateToFakeLogin = true;
    this.animationTransitionTime = 0.35; // 350 Milliseconds
    this.enableSmoothScrolling = true;
    this.enableAnimations = true;
    this.enableAutoGrowText = true;
    this.webSocketPath = 'wss://localhost:9081/transaction-verifier/ws/verify';
    this.stiUrlPrefix = 'http://localhost:9081';
    this.abSalt = '7061737323313233';
  }

  public print() {
    console.log('=================ENVIRONMENT VARIABLES START=================');
    console.log('Name: ' + this.name);
    console.log('Production: ' + this.production);
    console.log('Local: ' + this.local);
    console.log('UrlPrefix: ' + this.urlPrefix);
    console.log('LoaderTimeout: ' + this.loaderTimeout);
    console.log('EnableAnalytics: ' + this.enableAnalytics);
    console.log('EnableInactivityTimeout: ' + this.enableInactivityTimeout);
    console.log('TimerInterval: ' + this.timerInterval);
    console.log('HeartBeatTimerInterval: ' + this.heartBeatTimerInterval);
    console.log('InactivityModalId: ' + this.inactivityModalId);
    console.log('TimerDuration: ' + this.timerDuration);
    console.log('CountdownStartTime: ' + this.countdownStartTime);
    console.log('ReloadAfterLogout: ' + this.reloadAfterLogout);
    console.log('NavigateToFakeLogin: ' + this.navigateToFakeLogin);
    console.log('RoutingAnimationTime: ' + this.animationTransitionTime);
    console.log('EnableSmoothScrolling: ' + this.enableSmoothScrolling);
    console.log('EnableAnimations: ' + this.enableAnimations);
    console.log('EnableAutoGrowText: ' + this.enableAutoGrowText);
    console.log('StiUrlPrefix: ' + this.stiUrlPrefix);
    console.log('abSalt: ' + this.abSalt);
    console.log('=================ENVIRONMENT VARIABLES END=================');
  }

}
