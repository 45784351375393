export class DigitalAnalyticsElementModel {

    public static CATEGORY_MSTI = 'MSTI';

    public static ELEMENT_MARKETING_START_QUOTE = 'MSTI: Marketing Page: Start your quote';
    public static ELEMENT_MARKETING_WHY_US = 'MSTI: Marketing Page: Why choose us';
    public static ELEMENT_MARKETING_MY_CAR = 'MSTI: Marketing Page: My car';
    public static ELEMENT_MARKETING_CASH_BACK = 'MSTI: Marketing Page: 30% cash back ';
    public static ELEMENT_MARKETING_COVER_YOU_NEED = 'MSTI: Marketing Page: Cover you need';
    public static ELEMENT_PERSONAL_INFO_EMAIL_ADDRESS_INFO = 'MSTI: Personal Information: Email address info icon';
    public static ELEMENT_DISCLOSURE_ITC_KNOW_MORE = 'MSTI: ITC check page: I want to know more';
    public static ELEMENT_VEHICLE_CANNOT_FIND_VEHICLE = 'MSTI: Vehicle description : I cannot find my vehicle';
    public static ELEMENT_VEHICLE_REGULAR_DRIVER_INFO = 'MSTI: Driver details: Regular driver info icon';
    public static ELEMENT_VEHICLE_DRIVING_CONVICTIONS_INFO = 'MSTI: Driver details: Driving convictions info icon';
    public static ELEMENT_PREMIUM_TERMS_AND_CONDITIONS = 'MSTI: Premium page: Terms and conditions link';
    public static ELEMENT_PREMIUM_ASSIST_INFO = 'MSTI: Premium page: Momentum Assist info icon';
    public static ELEMENT_PREMIUM_SASRIA_INFO = 'MSTI: Premium page: SASRIA info icon';
    public static ELEMENT_COVER_ADD_VEHICLE = 'MSTI: Cover details page: Add another vehicle';
    public static ELEMENT_COVER_REMOVE_VEHICLE = 'MSTI: Cover details page: Remove vehicle';
    public static ELEMENT_DO_YOU_NEED_HELP_SEND = 'MSTI: Do you need help: Send';
    public static ELEMENT_MARKETING_HOME_CONTENTS = 'MSTI: Marketing Page: Personal Belongings & Home Contents Insurance';
    public static ELEMENT_COVER_ADD_CONTENT = 'MSTI: Cover details page: Add content risks insurance for another property';
    public static ELEMENT_COVER_REMOVE_CONTENT = 'MSTI: Cover details page: Remove content';
    public static ELEMENT_MARKETING_BUILDING = 'MSTI: Marketing Page: My building';
    public static ELEMENT_COVER_ADD_BUILDING = 'MSTI: Cover details page: Add building insurance for another property';
    public static ELEMENT_COVER_REMOVE_BUILDING = 'MSTI: Cover details page: Remove building';

    public elementId: string;
    public elementCategory: string;
    public attributeString: string;

    public static getDoYouNeedHelpElementLabel(callingPage: string): string {
        return `MSTI: ${callingPage}: Do you need help`;
    }

}
