import {AfterViewChecked, Component, ElementRef, Injector, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';
import {LookupModel} from '../../../../../lookup/lookup.model';
import {AutocompleteMultiSelectDynamicControlModel} from './autocomplete-multiselect-dynamic-control.model';

@Component({
    selector: 'app-autocomplete-multiselect-dynamic-form-control',
    templateUrl: './autocomplete-multiselect-dynamic-form-control.component.html',
    styleUrls: ['./autocomplete-multiselect-dynamic-control.component.scss']

})
export class AutocompleteMultiSelectDynamicFormControlComponent extends BaseDynamicFormControl<string> implements AfterViewChecked {

    @ViewChild('chipInputAutoCompleteMultiSelect') chipInputAutoCompleteMultiSelect: ElementRef;
    @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

    availableOptions: LookupModel[] = [];
    filteredOptions: LookupModel[] = [];
    selectedOptions: LookupModel[] = [];

    chipInputControlAutoCompleteMultiSelect = new FormControl();

    selectable = true;
    removable = true;
    addOnBlurAutoCompleteMultiSelect = false;
    separatorKeysCodes = [ENTER, COMMA];

    private afterViewCheckedComplete: boolean;

    constructor(
        injector: Injector
    ) {
        super(injector);
        this.afterViewCheckedComplete = false;
    }

    doInit() {
        this.availableOptions = (<AutocompleteMultiSelectDynamicControlModel>this.dynamicControlModel).options;
        this.filteredOptions = this.availableOptions;

        this.subscriptions.push(this.chipInputControlAutoCompleteMultiSelect.valueChanges.subscribe(val => {
            this.filteredOptions = this.filterMultiSelect(val.getDescription ? val.getDescription() : val);
        }));
    }

    updateFormControlValue(inputValue: any) {
        // TODO - Implement me
    }

    clearValue() {
        this.dynamicControlModel.value = null;
    }

    // NOTE: For some reason ngAfterViewInit doesn't work here
    ngAfterViewChecked() {
        if (!this.afterViewCheckedComplete && this.matAutocomplete) {
            // NOTE: This is required for when we patch values
            // TODO - We should find a way only emit the event once for patched values instead of listening for every change
            this.subscriptions.push(this.form.get(this.dynamicControlModel.key).valueChanges.subscribe(selectedValue => {
                if (selectedValue && selectedValue.length > 0) {
                    selectedValue.forEach(selectedSecurityDevice => {
                        this.matAutocomplete._emitSelectEvent(selectedSecurityDevice);
                    });
                }
            }));

            this.afterViewCheckedComplete = true;
            this.cdRef.detectChanges();
        }
    }

    private filterMultiSelect(value: string) {
        if (this.availableOptions) {
            const filterValue = value;
            return this.availableOptions.filter(option =>
                option.getDescription().toLowerCase().startsWith(filterValue)
            );
        }
    }

    private refreshMultiSelectOptions() {
        this.updateMultiSelectOptions();
        this.sortMultiSelectOptions();
        this.resetMultiSelectOptionsChipInput();
    }

    private updateMultiSelectOptions() {
        this.form.patchValue({ [this.dynamicControlModel.key]: this.selectedOptions });
    }

    private sortMultiSelectOptions() {
        this.availableOptions = this.availableOptions.sort(function (a, b) {
            if (a.getDescription() < b.getDescription()) { return -1; }
            if (a.getDescription() > b.getDescription()) { return 1; }
            return 0;
        });
    }

    private resetMultiSelectOptionsChipInput() {
        this.chipInputAutoCompleteMultiSelect.nativeElement.value = '';
        this.chipInputAutoCompleteMultiSelect.nativeElement.blur();
    }

    addMultiSelectOption(event: MatAutocompleteSelectedEvent): void {
        // NOTE: When data is patched it does not come through as a matoption, but rather as the raw value
        const selection = event.option.value ? event.option.value : event.option;

        if (!this.selectedOptions.includes(selection)) {
            this.selectedOptions.push(selection);
            this.availableOptions = this.availableOptions.filter(option => option.getDescription() !== selection.getDescription());
            this.refreshMultiSelectOptions();
        }
    }

    removeMultiSelectOption(selectedOption: any): void {
        const index = this.selectedOptions.indexOf(selectedOption);

        if (index >= 0) {
            this.selectedOptions.splice(index, 1);

            const removedOption =
                this.availableOptions.filter(
                    option => option.getDescription() === selectedOption.getDescription());
            if (removedOption && removedOption.length > 0) {
                this.availableOptions.push(removedOption[0]);
            }
        }

        this.refreshMultiSelectOptions();
    }

    onChipInputBlurAutoCompleteMultiSelect() {
        this.chipInputAutoCompleteMultiSelect.nativeElement.value = '';
    }

    clearChipListAutoCompleteMultiSelect() {
        this.chipInputControlAutoCompleteMultiSelect.setValue('');
    }

    autoCompleteFn(option?: LookupModel): string | undefined {
        return option ? option.getDescription() : undefined;
    }

}
