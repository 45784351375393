import {EventEmitter, Injectable} from '@angular/core';
import {BaseRiskTabService} from '../../base/service/base-risk-tab.service';
import {BaseDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/base-dynamic-control.model';
import {Validators} from '@angular/forms';
import {AutocompleteDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/autocomplete/autocomplete-dynamic-control.model';
import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {BehaviorSubject} from 'rxjs/index';
import {LookupFilteredDropDownModel} from '../../../../shared/lookup/lookup.filtered.drop.down.model';
import {LoaderService} from '../../../../shared/screen-loader/loader.service';
import {TextboxDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/textbox/textbox-dynamic-control.model';
import {RadioButtonYesNoDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/radiobutton-yesno/radiobutton-yesno-dynamic-control.model';
import {ContentsService} from './contents.service';
import {AutocompleteMultiSelectDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/autocomplete-multiselect/autocomplete-multiselect-dynamic-control.model';
import {TextboxNumericDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/textbox-numeric/textbox-numeric-dynamic-control.model';

@Injectable()
export class StructureDetailsService extends BaseRiskTabService {

    public static readonly CODE_TREATED_THATCH_ROOF = '003';
    public static readonly CODE_THATCH_ROOF = '004';

    public structureLightingHintClickedEvent: EventEmitter<any>;
    public structureCommuneHintClickedEvent: EventEmitter<any>;
    public structureApprovedHintClickedEvent: EventEmitter<any>;
    public structureRiskAddressHintClickedEvent: EventEmitter<any>;
    public structureHasThatchHintClickedEvent: EventEmitter<any>;

    private structureTypes: LookupFilteredDropDownModel;
    private wallMaterials: LookupFilteredDropDownModel;
    private roofMaterials: LookupFilteredDropDownModel;
    private securityFeatures: LookupFilteredDropDownModel;
    private lapaDistances: LookupFilteredDropDownModel;

    private showLightingConductorControl: BehaviorSubject<boolean>;
    private showLapaControls: BehaviorSubject<boolean>;
    private showThatchTreatedControl: BehaviorSubject<boolean>;

    constructor(
        private loaderService: LoaderService,
        private contentsService: ContentsService
    ) {
        super();
        this.structureTypes = new LookupFilteredDropDownModel([]);
        this.wallMaterials = new LookupFilteredDropDownModel([]);
        this.roofMaterials = new LookupFilteredDropDownModel([]);
        this.securityFeatures = new LookupFilteredDropDownModel([]);
        this.lapaDistances = new LookupFilteredDropDownModel([]);
    }

    init(): void {
        this.showLightingConductorControl = new BehaviorSubject<boolean>(true);
        this.showLapaControls = new BehaviorSubject<boolean>(true);
        this.showThatchTreatedControl = new BehaviorSubject<boolean>(true);

        this.structureLightingHintClickedEvent = new EventEmitter();
        this.structureCommuneHintClickedEvent = new EventEmitter();
        this.structureApprovedHintClickedEvent = new EventEmitter();
        this.structureRiskAddressHintClickedEvent = new EventEmitter();
        this.structureHasThatchHintClickedEvent = new EventEmitter();

        this.loaderService.show();
        this.contentsService.getStructureTypes().then(data => {
            this.structureTypes.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.contentsService.getWallMaterials().then(data => {
            this.wallMaterials.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.contentsService.getRoofMaterials().then(data => {
            this.roofMaterials.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.contentsService.getSecurityFeatures().then(data => {
            this.securityFeatures.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.contentsService.getLapaDistances().then(data => {
            this.lapaDistances.setValues(data);
        }).then(() => this.loaderService.hide());
    }

    isRoofMadeOfThatch(selectedValue) {
        if (selectedValue) {
            if (selectedValue.getCode() === StructureDetailsService.CODE_TREATED_THATCH_ROOF ||
                selectedValue.getCode() === StructureDetailsService.CODE_THATCH_ROOF) {
                this.displayLightingConductorControl(false);
            } else {
                this.displayLightingConductorControl(true);
            }
        }
    }

    private displayLightingConductorControl(displayLightingConductorControl: boolean) {
        this.showLightingConductorControl.next(displayLightingConductorControl);
    }

    isThereLapaAtRiskAddress(selectedValue) {
        this.displayLapaControls(this.isRadioOptionNo(selectedValue));
        this.showThatchTreatedControl.next(this.isRadioOptionNo(selectedValue));
    }

    private displayLapaControls(displayLightingConductorControl: boolean) {
        this.showLapaControls.next(displayLightingConductorControl);
    }

    doesLapaHaveThatchedRoof(selectedValue) {
        this.displayThatchTreatedControl(this.isRadioOptionNo(selectedValue));
    }

    private displayThatchTreatedControl(displayThatchTreatedControl: boolean) {
        this.showThatchTreatedControl.next(displayThatchTreatedControl);
    }

    getDynamicFormControls(): BaseDynamicControlModel<any>[] {
        const dynamicFormControls: BaseDynamicControlModel<any>[] = [
            new AutocompleteDynamicControlModel(
                {
                    key: 'structureType',
                    label: 'What is the structure type?',
                    hint: '',
                    validation: [Validators.required],
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.structureTypes.filteredValues).asObservable(),
                    order: 1
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'structureWallMaterial',
                    label: 'What is the wall construction of the structure?',
                    hint: '',
                    validation: [Validators.required],
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.wallMaterials.filteredValues).asObservable(),
                    order: 2
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'structureRoof',
                    label: 'What is the roof made of?',
                    hint: '',
                    validation: [Validators.required],
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.roofMaterials.filteredValues).asObservable(),
                    order: 3
                }
            ),
            new RadioButtonYesNoDynamicControlModel(
                {
                    key: 'structureLighting',
                    label: 'Is there a lightning conductor on the property?',
                    hint: ' A lightning conductor is a metal rod mounted on a structure that protects it from a lightning strike.',
                    hintClickedEvent: this.structureLightingHintClickedEvent,
                    value: '',
                    validation: [Validators.required],
                    hidden: this.showLightingConductorControl.asObservable(),
                    order: 4
                }
            ),
            new TextboxDynamicControlModel(
                {
                    key: 'structureBedRooms',
                    label: 'How many bedrooms does the structure have?',
                    hint: '',
                    validation: [Validators.required],
                    order: 5
                }
            ),
            new AutocompleteMultiSelectDynamicControlModel(
                {
                    key: 'structureSecurity',
                    label: 'Which of the following security features does the structure have?',
                    hint: '',
                    validation: [Validators.required],
                    options: this.securityFeatures.filteredValues,
                    order: 6
                }
            ),
            new RadioButtonYesNoDynamicControlModel(
                {
                    key: 'structureCommune',
                    label: 'Is the structure used as a commune?',
                    hint: 'A commune is a property that is rented out to various tenants for accommodation purposes and they share some spaces of the structure.',
                    hintClickedEvent: this.structureCommuneHintClickedEvent,
                    value: '',
                    validation: [Validators.required],
                    order: 7
                }
            ),
            new RadioButtonYesNoDynamicControlModel(
                {
                    key: 'structureApproved',
                    label: 'Is the construction of the structure approved by the local authority?',
                    hint: 'Did the local authority, such as municipality inspectors, inspected the structure and approved it?',
                    hintClickedEvent: this.structureApprovedHintClickedEvent,
                    value: '',
                    validation: [Validators.required],
                    order: 8
                }
            ),
            new RadioButtonYesNoDynamicControlModel(
                {
                    key: 'structureHasLapa',
                    label: 'Is there a lapa at the building address provided?',
                    hint: 'A lapa is a structure that usually has a thatched roof supported by wooden poles and commonly used as semi-open entertainment areas.',
                    hintClickedEvent: this.structureRiskAddressHintClickedEvent,
                    value: '',
                    validation: [Validators.required],
                    order: 9
                }
            ),
            new RadioButtonYesNoDynamicControlModel(
                {
                    key: 'structureHasThatch',
                    label: ' Does the lapa have a thatch roof?',
                    hint: 'A thatch roof is made out of dry vegetation such as straw.',
                    hintClickedEvent: this.structureHasThatchHintClickedEvent,
                    value: '',
                    validation: [Validators.required],
                    hidden: this.showLapaControls.asObservable(),
                    order: 10
                }
            ),
            new RadioButtonYesNoDynamicControlModel(
                {
                    key: 'structureIsTreated',
                    label: 'Is the thatch treated?',
                    hint: 'Thatch treatment refers to additional preventative measures to protect the roof from hazards such as fire.',
                    value: '',
                    validation: [Validators.required],
                    hidden: this.showThatchTreatedControl.asObservable(),
                    order: 11
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'structureDistance',
                    label: 'What is the distance between the lapa and the structure?',
                    hint: '',
                    validation: [Validators.required],
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.lapaDistances.filteredValues).asObservable(),
                    hidden: this.showThatchTreatedControl.asObservable(),
                    order: 12
                }
            ),
            new TextboxNumericDynamicControlModel(
                {
                    key: 'structureSize',
                    label: 'What is the size of the structure?',
                    hint: '',
                    validation: [Validators.required],
                    maxLength: 10,
                    hidden: this.showThatchTreatedControl.asObservable(),
                    order: 13
                }
            )
        ];
        return dynamicFormControls.sort((a, b) => a.order - b.order);
    }

}
