import {CifLicenseDetailsModel} from '../cif/cif-license-details.model';
import {CifDrivingConvictionModel} from '../cif/cif-driving-conviction.model';
import {CifPartyInfoModel} from '../../../../../personal/model/SaveClientModels/cif-party-info.model';
import {ContactInfoTypeModel} from '../../../../../personal/model/contact-info-type.model';
import {PifVehicleAddressDetailsModel} from './pif-vehicle-address-details.model';
import {CifRegularDriverModel} from '../cif/cif-regular-driver.model';
import {AddressDetailsModel} from '../../../../../personal/model/SaveClientModels/address-details.model';
import {CifAddressInfoModel} from '../../../../../personal/model/SaveClientModels/cif-address-info.model';

export class PifRegularDriverRetrieveModel {
  public personal: CifPartyInfoModel = new CifPartyInfoModel();
  public contact: ContactInfoTypeModel = new ContactInfoTypeModel();
  relationshipToInsuredCode: string; // Mandatory
  isPolicyHolder: boolean;
  hasDrivingConviction: boolean;
  driversLicense: CifLicenseDetailsModel;
  drivingConvictions: CifDrivingConvictionModel[];
  addressDetails: PifVehicleAddressDetailsModel;
  reason: string;

  constructor() {
    this.driversLicense = new CifLicenseDetailsModel();
    this.drivingConvictions = [];
  }


  static pifToCifRegularDriverModel(pifRegularDriverRetriveModel: PifRegularDriverRetrieveModel): CifRegularDriverModel {
    const val = Object.assign(new CifRegularDriverModel(), pifRegularDriverRetriveModel);
    const address = Object.assign(new AddressDetailsModel(), pifRegularDriverRetriveModel.addressDetails);
    // TODO check mapping for street code ;
    const cifAddressInfoModel = new CifAddressInfoModel();
    cifAddressInfoModel.addressType = 'HOME';
    cifAddressInfoModel.addressDetails = address;
    val.addressList.push(cifAddressInfoModel);
    return val;
  }
}
