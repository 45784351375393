import {Injectable} from '@angular/core';
import {UrlService} from '../../../../shared/services/url.service';
import {MSTIConfiguration} from '../../../../shared/msti.config';

@Injectable()
export class ContentsUrlService extends UrlService {

  constructor() {
    super();
  }


  private baseUrlPath(): string {
    // return `${MSTIConfiguration.constants.urlPrefix}${this.FULFILLMENT_API_V2}${this.partyKey()}`;
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}`;
  }

  public getSaveContentURL(quoteId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/contents`;
  }

  public getUpdateContentURL(quoteId: string, contentId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/contents/${contentId}`;
  }

  public getDeleteContentURL(quoteId: string, contentId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/contents/${contentId}`;
  }

  public getRetrieveContentURL(quoteId: string, contentId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/contents/${contentId}`;
  }

}
