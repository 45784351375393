import {AfterViewChecked, ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, ViewContainerRef} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BaseDynamicControlModel} from '../dynamic-form-control/base-dynamic-control.model';
import {DynamicFormControlService} from '../dynamic-form-control/service/dynamic-form-control-service';
import {DynamicComponentService} from '../helper/DynamicComponentService';
import {Level} from '../../utilities/logger-level';
import {SmoothScroll} from '../../utilities/smooth-scroll';
import {Logger} from '../../utilities/logger';
import {BaseDynamicFormControl} from '../dynamic-form-control/base-dynamic-form-control';
import {v4 as uuid} from 'uuid';
import {AutoUnsubscribe} from '../../auto-unsubscribe';

@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html'
})
export class DynamicFormComponent extends AutoUnsubscribe implements OnInit, AfterViewChecked {

    @Output() onSubmitEventEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

    public dynamicFormControls: BaseDynamicControlModel<any>[] = [];
    public form: FormGroup;
    public patchUpdate: any;
    public hasFormValueChanged: boolean;

    private id: string;
    private submitted: boolean;
    private dynamicComponentViewContainerRef: ViewContainerRef;

    constructor(
        private dynamicFormControlService: DynamicFormControlService,
        public cdRef: ChangeDetectorRef,
        private resolver: ComponentFactoryResolver,
        dynamicComponentService: DynamicComponentService
    ) {
        super();
        this.id = uuid();

        // TODO - Perhaps this should be a subscription to an observable?
        dynamicComponentService.onContainerCreated((id, container) => {
            if (id === this.id) {
                this.dynamicComponentViewContainerRef = container;
                this.loadFormComponents();
            }
        });

        dynamicComponentService.onContainerDestroyed(() => {
            this.dynamicComponentViewContainerRef = undefined;
        });
    }

    ngOnInit() {
        this.submitted = false;
        this.hasFormValueChanged = false;
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    onFormValueChanged() {
        if (this.form.dirty) {
            this.hasFormValueChanged = true;
            this.submitted = false;
        }
    }

    onSubmit() {
        if (this.form.valid) {
            this.submitted = true;
            this.hasFormValueChanged = false;
            Logger.log(Level.LOG, 'Form submission succeeded');
            // Logger.log(Level.LOG, JSON.stringify(this.form.value));
            Logger.log(Level.LOG, this.form.value);
            this.onSubmitEventEmitter.emit(this.form);
        } else {
            this.submitted = false;
            this.hasFormValueChanged = true;
            Logger.log(Level.LOG, 'Form submission failed');
            this.validateAllFormFields(this.form);

            // NOTE: There is a slight delay between executing the above validation and fields being rendered as invalid
            setTimeout(() => {
                SmoothScroll.smoothScrollToTop('.mat-form-field-invalid');
            }, 250);
        }
    }

    updateValues() {
        // TODO - Correct the null pointer that occurs here, oddly though testing that the form is not undefined breaks the value patching
        this.form.patchValue(this.patchUpdate);
    }

    updateForm() {
        // TODO - This is designed only to be run once by the calling service, what about dynamic updates?
        this.loadFormGroup();
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    setFormClasses() {
        return {
            'form-changed': this.hasFormValueChanged,
            'form-unsubmitted': !this.submitted
        };
    }

    private loadFormGroup() {
        this.form = this.dynamicFormControlService.toFormGroup(
            this.dynamicFormControls
        );
    }

    private loadFormComponents() {
        if (!this.dynamicComponentViewContainerRef) {
            Logger.log(Level.WARN, 'Attempted to load components before the container ref was available');
        } else if (this.dynamicFormControls && this.dynamicFormControls.length > 0) {
            this.dynamicFormControls.forEach(cardFormControl => {
                const componentFactory = this.resolver.resolveComponentFactory(cardFormControl.getControlClass());
                const componentRef = this.dynamicComponentViewContainerRef.createComponent(componentFactory);

                // Set inputs
                (<BaseDynamicFormControl<any>>componentRef.instance).form = this.form;
                (<BaseDynamicFormControl<any>>componentRef.instance).dynamicControlModel = cardFormControl;

                // Set outputs
                this.subscriptions.push((<BaseDynamicFormControl<any>>componentRef.instance).onFormValueChanged.subscribe(data => {
                    this.onFormValueChanged();
                }));

                (<BaseDynamicFormControl<any>>componentRef.instance).init();
            });
        }
    }

}
