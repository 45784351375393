import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {PremiumConfig} from './premium.config';
import {PremiumPersonalInfoModel} from '../model/premium-personal-info.model';
import {PremiumQuoteSummaryItemModel} from '../model/premium-quote-summary-item.model';
import {PremiumBenefitItemModel} from '../model/premium-benefit-item.model';
import {PremiumService} from '../service/premium.service';
import {DigitalAnalyticsService} from '../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsPageModel} from '../../shared/digital-analytics/model/digital-analytics-page.model';
import {DigitalAnalyticsElementModel} from '../../shared/digital-analytics/model/digital-analytics-element.model';
import {UrlService} from '../../shared/services/url.service';
import {ImageService} from '../../shared/services/image.service';
import {LogoutService} from '../../shared/services/logout.service';
import {BrokerDetailsService} from '../../shared/broker-common/broker-details.service';
import {LoaderService} from '../../shared/screen-loader/loader.service';
import {MstiErrorService} from '../../shared/msti-error-handler/msti-error.service';
import {QuoteService} from '../../quote/service/quote.service';
import {NeedHelpService} from '../../footer/need-help/services/need-help.service';
import {NeedHelpPageTags} from '../../footer/need-help/need-help-page-tags';
import {Logger} from '../../shared/utilities/logger';
import {Level} from '../../shared/utilities/logger-level';
import {RoutingService} from '../../shared/services/routing.service';
import {SmoothScroll} from '../../shared/utilities/smooth-scroll';
import {GoogleAnalyticsService} from '../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../shared/google-analytics/model/google-analytics-action.model';
import {BrokerCommissionModel} from '../../quote/model/cif/brokerCommission.model';
import {CifQuoteSummaryResponseModel} from '../../quote/model/cif/cif-quote-summary-response.model';
import {MSTIService} from '../../shared/services/msti.service';
import {QuoteUrlService} from '../../quote/service/quote-url.service';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./premium.component.scss']
})
export class PremiumComponent implements OnInit {

  premiumPageHeading = PremiumConfig.HEADING;
  premiumPageSubHeading = PremiumConfig.SUB_HEADING;

  premiumPersonalInfoModel: PremiumPersonalInfoModel;
  premiumQuoteSummaryItemModels: PremiumQuoteSummaryItemModel[];
  premiumBenefitItemModels: PremiumBenefitItemModel[] = [];

  premiumTotal: string;
  vatAmount: string;

  isQuoteSubmitted = false;
  isTermsAcceptanceChecked = false;

  termsPdfUrl: string;
  quoteId: string;
  quoteDisplayNumber: string;
  hasVehiclePremium = false;
  isPreLoginUser: boolean;
  brokerCommission: BrokerCommissionModel;
  showQuoteSummary = false;
  showCustomLoader = false;

  constructor(private premiumService: PremiumService, private elementRef: ElementRef, private digitalAnalyticsService: DigitalAnalyticsService,
              private urlService: UrlService, private imageService: ImageService, private logoutService: LogoutService,
              private userService: BrokerDetailsService, private loaderService: LoaderService, private mstiErrorService: MstiErrorService,
              private quoteService: QuoteService, private needHelpService: NeedHelpService, private routingService: RoutingService,
              private googleAnalyticsService: GoogleAnalyticsService, private mstiService: MSTIService, private quoteUrlService: QuoteUrlService) {
  }

  ngOnInit() {
    SmoothScroll.smoothScrollToTop();
    this.loaderService.show();
    this.SetGA();
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_QUOTE_PREMIUM);
    this.termsPdfUrl = this.urlService.getUnprefixedUrl(UrlService.KEY_PREMIUM_TERMS_PDF);
    this.premiumPersonalInfoModel = this.premiumService.getPersonalInfo();
    this.loaderService.hide();
    this.initPremiumQuoteSummaryItemModels();

    // this.loaderService.hideCustomLoader();
  }

  private SetGA() {
    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_PREMIUM_PAGE_ID, attributes);
  }

  private initPremiumQuoteSummaryItemModels() {
    // this.loaderService.showCustomLoader(QuoteConfig.DIALOG_INFO_CONFIG);
    this.showCustomLoader = true;
    this.quoteId = this.quoteService.quoteId;
    this.quoteDisplayNumber = this.quoteService.quoteDisplayNumber;
    // const existingQuote = await  this.quoteService.getPremiumSummary();

    if (this.quoteId) {
      this.mstiService.getWithAbAuth(this.quoteUrlService.getGetPremiumSummaryURL(this.quoteId)).subscribe(response => {
        const existingQuote = Object.assign(new CifQuoteSummaryResponseModel(), response);
        Logger.log(Level.LOG, 'Quote summary retrieve response:', existingQuote);
        this.processPremiumSummaryResponse(existingQuote);
        this.showQuoteSummary = true;
        this.initPremiumBenefitItemModels();
        this.showCustomLoader = false;
      }, (error) => {
        Logger.log(Level.ERROR, `Error retrieving existing premium summary for quoteId: ${this.quoteId}`, error);
        this.loaderService.hideCustomLoader();
        this.showCustomLoader = false;
        this.mstiErrorService.handleError(error);
      });
    }

  }

  private processPremiumSummaryResponse(existingQuote: CifQuoteSummaryResponseModel) {
    Logger.log(Level.LOG, 'existingQuote', existingQuote);

    if (existingQuote) {

      if (existingQuote.premiumRiskList && existingQuote.premiumRiskList.length > 0) {

        this.premiumTotal = this.premiumService.formatCurrencyValue(existingQuote.totalPremium.premiumValue, false);
        Logger.log(Level.LOG, 'existingQuote 2', existingQuote.totalPremium.premiumVatComponent);
        this.vatAmount = this.premiumService.formatCurrencyValue(existingQuote.totalPremium.premiumVatComponent.toString(), false);
        let riskDescription = PremiumConfig.EMPTY_STRING;
        let sectionCode = PremiumConfig.EMPTY_STRING;
        let coverType = PremiumConfig.EMPTY_STRING;
        let heading = this.premiumService.getHeading(sectionCode, '');
        const isHeadingRow = false;
        this.hasVehiclePremium = existingQuote.premiumRiskList.some(riskPremium => riskPremium.sectionCode === PremiumConfig.VEHICLE_COVER_TYPE_CODE);

        this.premiumQuoteSummaryItemModels = new Array<PremiumQuoteSummaryItemModel>();

        const premiumQuoteSummaryCoverSections = [];

        existingQuote.premiumRiskList = this.premiumService.updateRiskPremiums(existingQuote.premiumRiskList);
        for (const riskPremium of existingQuote.premiumRiskList) {
          if (riskPremium.subjectMatterInsuredList && riskPremium.coverList) { // TODO need to remove this
            riskDescription = riskPremium.riskDescription;
            sectionCode = riskPremium.sectionCode;
            coverType = this.premiumService.getCoverType(sectionCode);
            heading = this.premiumService.getHeading(sectionCode, riskPremium.riskDescription);
            heading = this.premiumService.updateHeading(heading, premiumQuoteSummaryCoverSections, coverType, riskPremium.riskDescription);
            let accessories = riskPremium.subjectMatterInsuredList.map((accessory) => {
              return new PremiumQuoteSummaryItemModel(
                coverType,
                this.premiumService.getFormattedRiskDescription(sectionCode, riskDescription, accessory.smiDescription),
                PremiumConfig.EMPTY_STRING,
                this.premiumService.formatCurrencyValue(accessory.sumInsured ?
                  accessory.sumInsured : PremiumConfig.EMPTY_DISPLAY_VALUE, true),
                this.premiumService.formatCurrencyValue(accessory.excess.excessValue.toString() ?
                  accessory.excess.excessValue.toString() : PremiumConfig.EMPTY_DISPLAY_VALUE, true),
                this.premiumService.formatCurrencyValue(this.premiumService.getFormattedRiskPremium(sectionCode,
                  accessory.smiDescription,
                  riskPremium.totalRiskPremium.premiumValue,
                  accessory.premium ? accessory.premium.premiumValue : PremiumConfig.EMPTY_STRING), true),
                isHeadingRow,
                this.premiumService.isRiskDescriptionRow(sectionCode, accessory.smiDescription),
                riskPremium.riskDescription
              );
            });
            Logger.log(Level.LOG, 'Ab b4 accessories', accessories);
            let covers = riskPremium.coverList.map((cover) => {
              return new PremiumQuoteSummaryItemModel(
                coverType,
                cover.coverDescription,
                this.premiumService.getHintDescription(cover.coverDescription.trim()),

                this.premiumService.formatCurrencyValue(cover.sumInsured ?
                  cover.sumInsured : PremiumConfig.EMPTY_DISPLAY_VALUE, true),

                this.premiumService.formatCurrencyValue(PremiumConfig.EMPTY_DISPLAY_VALUE, false),

                this.premiumService.formatCurrencyValue(cover.premium ?
                  cover.premium.premiumValue : PremiumConfig.EMPTY_DISPLAY_VALUE, true),
                isHeadingRow,
                this.premiumService.isRiskDescriptionRow(sectionCode, PremiumConfig.EMPTY_STRING),
                riskPremium.riskDescription
              );
            });
            Logger.log(Level.LOG, 'Ab b4 covers', covers);
            accessories = this.premiumService.updateAccessories(sectionCode, accessories, covers);
            covers = this.premiumService.updateCovers(covers);
            Logger.log(Level.LOG, 'Ab covers', covers);
            const premiumQuoteSummaryCoverSection = [...heading, ...accessories, ...covers];
            /* let sasriaValue = 0;
             let sasriaDivident = 0;
             for (const element of premiumQuoteSummaryCoverSection) {
                 if (element.description === 'SASRIA') {
                     sasriaValue = +element.premium.replace('R', '');
                 } else {
                     if (!this.isStringSame(element.premium, ' ') && !this.isStringSame(element.premium, 'Premium')) {
                         sasriaDivident = sasriaDivident + 1;
                     }

                 }
             }
             let sasriaDividentValue = 0;
             if (sasriaDivident !== 0) {
                 sasriaDividentValue = sasriaValue / sasriaDivident;
             } else {
                 sasriaDividentValue = sasriaValue;
             }
             for (const element of premiumQuoteSummaryCoverSection) {
                 if (element.description !== 'SASRIA' && element.coverType === 'Personal Belongings Insurance') {
                     if (!this.isStringSame(element.premium, ' ') && !this.isStringSame(element.premium, 'Premium')) {
                         const intermValue = element.premium.replace(/\s/g, '');
                         let newPremium = +intermValue.replace('R', '');
                         newPremium = newPremium - sasriaDividentValue;
                         element.premium = this.premiumService.formatCurrencyValue(newPremium, true);
                     }

                 }
             }*/

            premiumQuoteSummaryCoverSections.push(premiumQuoteSummaryCoverSection);

            Logger.log(Level.LOG, 'premiumQuoteSummaryCoverSection:', premiumQuoteSummaryCoverSection);
          }

          const premiumQuoteSummaryCoverSectionsCombined = [].concat(...premiumQuoteSummaryCoverSections);
          this.premiumQuoteSummaryItemModels = premiumQuoteSummaryCoverSectionsCombined;
          Logger.log(Level.LOG, 'this.premiumQuoteSummaryItemModels', this.premiumQuoteSummaryItemModels);
          Logger.log(Level.LOG, 'this.vatAmount', this.vatAmount);
        }
      }
    }
    this.brokerCommission = existingQuote.brokerCommission;
  }

  isStringSame(str1: string, str2: string): boolean {
    if (str1.localeCompare(str2) === 0) {
      return true;
    } else {
      return false;
    }

  }

  initPremiumBenefitItemModels() {
    this.premiumBenefitItemModels = new Array<PremiumBenefitItemModel>();

    const premiumBenefitDescriptionConfig = PremiumConfig.PREMIUM_BENEFIT_DESCRIPTION_CONFIG;
    const premiumBenefitTagLineConfig = PremiumConfig.PREMIUM_BENEFIT_TAGLINE_CONFIG;
    const premiumBenefitIconUrlConfig = PremiumConfig.PREMIUM_BENEFIT_ICON_URL_CONFIG;
    const premiumBenefitActiveStatusConfig = PremiumConfig.PREMIUM_BENEFIT_ACTIVE_STATUS_CONFIG;
    const premiumBenefitOrderConfig = PremiumConfig.PREMIUM_BENEFIT_ORDER_CONFIG;
    const premiumBenefitMetaDataConfig = PremiumConfig.PREMIUM_BENEFIT_METADATA_CONFIG;

    for (const [key, value] of Object.entries(premiumBenefitDescriptionConfig)) {
      if (premiumBenefitActiveStatusConfig[key]) {
        this.premiumBenefitItemModels.push(
          new PremiumBenefitItemModel(
            premiumBenefitDescriptionConfig[key],
            premiumBenefitTagLineConfig[key],
            this.imageService.getImage(premiumBenefitIconUrlConfig[key]),
            premiumBenefitActiveStatusConfig[key],
            premiumBenefitOrderConfig[key],
            premiumBenefitMetaDataConfig[key]
          )
        );
      }
    }

    this.premiumBenefitItemModels = this.premiumBenefitItemModels.sort((a, b) => a.order - b.order);
  }

  termsAndConditionsClicked() {
    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFireElementTag(
      DigitalAnalyticsElementModel.ELEMENT_PREMIUM_TERMS_AND_CONDITIONS,
      attributes
    );
    this.googleAnalyticsService.fireGoogleAnalytics(
      GoogleAnalyticsActionModel.ACTION_YES,
      GoogleAnalyticsEventModel.EVENT_VIEW_TERMS_AND_CONDITIONS
    );
  }

  onBack() {
    this.routingService.route('app-admin-fee');
  }

  onSubmit() {
    /*const attributes: Map<number, string> = new Map<number, string>();

    this.digitalAnalyticsService.constructAndFireConversionEventTag(
      DigitalAnalyticsConversionModel.PAGE_CONVERSION_SUBMIT_QUOTE,
      DigitalAnalyticsConversionModel.PAGE_CONVERSION_COMPLETE,
      attributes);

    this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_THANK_YOU_PAGE_ID, attributes);*/
    this.googleAnalyticsService.fireGoogleAnalytics(GoogleAnalyticsActionModel.ACTION_SUBMITTED, GoogleAnalyticsEventModel.EVENT_SUBMIT_QUOTE
    );
    Logger.log(Level.LOG, 'Abx', this.userService.getUser());

    this.submitCallBackForAcceptance();
    this.isQuoteSubmitted = true;
    this.saveTndCs();
    this.finishQuote();
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_QUOTE_THANK_YOU_FOR_YOUR_APPLICATION);
    SmoothScroll.smoothScrollToTop();

  }

  private saveTndCs() {
    this.premiumService.saveTermsAndConditionsAgreement(this.userService.getUser()).subscribe((response: any) => {
      this.loaderService.show();
      Logger.log(Level.LOG, 'Save Terms and Conditions', response);
      if (response) { // No response will come if there is an error
        this.loaderService.hide();
      }
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    });
  }


  private submitCallBackForAcceptance() {
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_QUOTE_PREMIUM_PAGE_CLIENT_APPROVES);
    const getAdviceModel = this.needHelpService.getAdviceDataForQuoteAccept('I LIKE PREMIUM', null);
    this.needHelpService.getAdvice(getAdviceModel).subscribe(response => {
      // Fire and forget
    });
  }

  private finishQuote() {
    const activeTab = this.elementRef.nativeElement.querySelectorAll('li.active');

    if (activeTab.length > 0) {
      activeTab[0].classList.remove('active');
    }
  }


}
