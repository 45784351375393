import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {AppManagerComponent} from './app_manager.component';
import {AppMangerService} from './service/app_manager.service';
import {AppInitializerService} from './app-initialiser-service.service';
import {AppNotAllowedComponent} from './components/app_not_allowed.component';
import {AppManagerRoutingModule} from './app-manager-routing.module';

@NgModule({
    declarations: [
        AppManagerComponent , AppNotAllowedComponent],
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
      AppManagerRoutingModule
    ],
    entryComponents: [],
    exports: [],
    providers: [AppMangerService, AppInitializerService]
})
export class AppManagerModule {
}
