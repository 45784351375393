import {Injectable} from '@angular/core';
import {UrlService} from '../../../../shared/services/url.service';
import {MSTIConfiguration} from '../../../../shared/msti.config';

@Injectable()
export class VehicleUrlService extends UrlService {

  constructor() {
    super();
  }

  public getAddVehicleToQuoteURL(quoteId: string): string {
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}brokerClientsApi/personalQuotations/${quoteId}/vehicles`;
  }

  public getUpdateVehicleURL(quoteId: string, vehicleId: string): string {
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}brokerClientsApi/personalQuotations/${quoteId}/vehicles/${vehicleId}`;
  }

  public getUpdateVehicleRegularDriverURL(quoteId: string, vehicleId: string): string {
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}brokerClientsApi/personalQuotations/${quoteId}/vehicles/${vehicleId}/regularDriver`;
  }

  public getRetrieveVehicleURL(quoteId: string, riskId: string): string {
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}brokerClientsApi/personalQuotations/${quoteId}/vehicles/${riskId}`;
  }

  public getRetrieveVehicleDriverURL(quoteId: string, riskId: string): string {
    // return `${this.getRetrieveVehicleURL(quoteId, riskId)}/drivers`;
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}brokerClientsApi/personalQuotations/${quoteId}/vehicles/${riskId}/regularDriver`;
  }

  public getDeleteVehicleURL(quoteId: string, vehicleId: string): string {
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}brokerClientsApi/personalQuotations/${quoteId}/vehicles/${vehicleId}`;
  }

}
