import {Component, Injector} from '@angular/core';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';

@Component({
    selector: 'app-email-dynamic-form-control',
    templateUrl: './email-dynamic-form-control.component.html',
    styleUrls: ['./email-dynamic-control.component.scss']

})
export class EmailDynamicFormControlComponent extends BaseDynamicFormControl<string> {

    // TODO - Confirm, the 'email' error for invalid emails doesn't seem to be set anywhere as expected by the corresponding html

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    doInit() { }

    updateFormControlValue(inputValue: any) {
        // TODO - Implement me
    }

    clearValue() {
        this.dynamicControlModel.value = null;
    }

}
