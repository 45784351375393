import {CifLicenseDetailsModel} from './cif-license-details.model';
import {CifDrivingConvictionModel} from './cif-driving-conviction.model';
import {SaveClientModel} from '../../../../../personal/model/SaveClientModels/save-client.model';

export class CifRegularDriverModel extends SaveClientModel {

  relationshipToInsuredCode: string; // Mandatory
  isPolicyHolder: boolean;
  hasDrivingConviction: boolean;
  driversLicense: CifLicenseDetailsModel;
  drivingConvictions: CifDrivingConvictionModel[];
  reason: string;

  constructor() {
    super();
    this.driversLicense = new CifLicenseDetailsModel();
    this.drivingConvictions = [];
  }

}
