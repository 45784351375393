import {Component, OnInit} from '@angular/core';
import {NeedHelpService} from '../footer/need-help/services/need-help.service';
import {NeedHelpPageTags} from '../footer/need-help/need-help-page-tags';
import {GoogleAnalyticsService} from '../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../shared/google-analytics/model/google-analytics-event.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminFeeModel} from './models/admin-fee.model';
import {RoutingService} from '../shared/services/routing.service';
import {SmoothScroll} from '../shared/utilities/smooth-scroll';
import {QuoteService} from '../quote/service/quote.service';
import {BrokerDetailsService} from '../shared/broker-common/broker-details.service';
import {AdminFeeService} from './services/admin-fee.service';
import {LoaderService} from '../shared/screen-loader/loader.service';
import {MstiErrorService} from '../shared/msti-error-handler/msti-error.service';

@Component({
  selector: 'app-broker-fee',
  templateUrl: 'admin-fee.component.html'
})
export class AdminFeeComponent implements OnInit {

  adminFeeModel: AdminFeeModel = new AdminFeeModel();
  adminFeeForm: FormGroup;
  adminFee: FormControl;
  hasConsentedAdminFee: FormControl;

  constructor(
    private routingService: RoutingService,
    private formBuilder: FormBuilder,
    private needHelpService: NeedHelpService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private quoteService: QuoteService,
    private brokerDetailsService: BrokerDetailsService,
    private adminFeeService: AdminFeeService,
    private loaderService: LoaderService,
    private mstiErrorService: MstiErrorService
  ) {
  }

  ngOnInit() {
    SmoothScroll.smoothScrollToTop();
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_ADMIN_FEE);
    this.setFormControls();
    const agreementResourceRef = this.quoteService.quoteId;
    const clientResourceRef = this.brokerDetailsService.getUser().clientResourceRef;

    if (agreementResourceRef && clientResourceRef) {
      this.getBrokerFeeDetails(agreementResourceRef, clientResourceRef);

    }

  }

  private setFormControls() {
    this.adminFeeForm = this.formBuilder.group({});
    this.adminFee = new FormControl('', [Validators.min(1), Validators.pattern('^[0-9]+(\.[0-9][0-9]?)?$'), Validators.required]);
    this.hasConsentedAdminFee = new FormControl('', );
    this.adminFeeForm.addControl('adminFee', this.adminFee);
    this.adminFeeForm.addControl('hasConsentedAdminFee', this.hasConsentedAdminFee);
    this.onSelect();
  }

  onNext() {
    this.adminFeeModel.brokerFeeCapture = this.hasConsentedAdminFee.value;
    this.adminFeeModel.brokerFee = this.adminFee.value;

    this.googleAnalyticsService.fireGoogleAnalytics(
      String(this.adminFeeModel.brokerFeeCapture),
      GoogleAnalyticsEventModel.EVENT_CONSENTED_ADMIN_FEE
    );

    const agreementResourceRef = this.quoteService.quoteId;
    const clientResourceRef = this.brokerDetailsService.getUser().clientResourceRef;

    if (this.adminFeeService.adminFeeDetails && this.adminFeeService.adminFeeDetails.brokerFee !== undefined) {
      this.updateBrokerFeeDetails(agreementResourceRef, clientResourceRef);
    } else {
      this.saveBrokerFeeDetails(agreementResourceRef, clientResourceRef);
    }

  }

  onBack() {
    this.routingService.route('quote');
  }

  onSelect() {
    this.checkValidation();
  }

  private getBrokerFeeDetails(agreementResourceRef: string, clientResourceRef: string) {
    this.loaderService.show();
    this.adminFeeService.getAdminFeeDetails(agreementResourceRef, clientResourceRef).subscribe(response => {
      this.adminFeeService.adminFeeDetails = Object.assign(new AdminFeeModel(), response);
      this.fillFields(this.adminFeeService.adminFeeDetails);
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    });
  }

  private  saveBrokerFeeDetails(agreementResourceRef: string, clientResourceRef: string) {
    this.setAdminFeeModel();
    this.adminFeeService.saveAdminFeeDetails(agreementResourceRef, clientResourceRef, this.adminFeeModel).subscribe((response: any) => {
      this.routingService.route('premium');
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    });
  }

  private  updateBrokerFeeDetails(agreementResourceRef: string, clientResourceRef: string) {
    this.setAdminFeeModel();
    this.adminFeeService.updateAdminFeeDetails(agreementResourceRef, clientResourceRef, this.adminFeeModel).subscribe((response: any) => {
      this.routingService.route('premium');
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    });
  }

  private setAdminFeeModel() {
    this.loaderService.show();
    this.adminFeeModel.setAdminFeeModel(this.hasConsentedAdminFee.value, this.adminFee.value);
    this.adminFeeService.adminFeeDetails = Object.assign(new AdminFeeModel(), this.adminFeeModel);
  }

  private fillFields(adminFee: AdminFeeModel) {
    this.adminFeeModel.setAdminFeeModel(adminFee.brokerFeeCapture, adminFee.brokerFee);
    this.adminFeeService.adminFeeDetails = Object.assign(new AdminFeeModel(), this.adminFeeModel);
    this.hasConsentedAdminFee.setValue(String(adminFee.brokerFeeCapture));
    this.adminFee.setValue(adminFee.brokerFee);
    this.checkValidation();
  }

  private checkValidation() {
    if (this.hasConsentedAdminFee.value === true || this.hasConsentedAdminFee.value === 'true') {
      this.adminFeeForm.get('adminFee').setValidators(
        [Validators.min(0),
          Validators.pattern('^[0-9]+(\.[0-9][0-9]?)?$'),
          Validators.required]);
    } else {
      this.adminFee.setValue(null);
      this.adminFeeForm.get('adminFee').setValidators([Validators.min(1), Validators.pattern('^[0-9]+(\.[0-9][0-9]?)?$')]);
    }
    this.adminFeeForm.get('adminFee').updateValueAndValidity();
  }

}
