import {Injectable} from '@angular/core';
import {LookupFilteredDropDownModel} from '../../shared/lookup/lookup.filtered.drop.down.model';

@Injectable()
export class PersonalDetailsHandlerService {
    public maleTitles: string[] = ['Mr'];
    public femaleTitles: string[] = ['Miss', 'Ms', 'Mrs'];
    public UNSPECIFIC_GENDER = 'unspecific';


    constructor() {
    }

    validateInput(value: string, lookupData: LookupFilteredDropDownModel, input: any): boolean {
        console.log('value', value);
        if (value && lookupData) {
            const dataList = lookupData.values;
            if (!dataList.find(x => x.description.toLowerCase() === value.toLowerCase())) {
               // this.clearField(input); // this was a fix for the dropdown issue
                return false;
            }
        }
        return true;
    }

    capitalize(value: string): string { // This method capitalise the first letter...Eg: spring to Spring
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }

    clearField(input) {
        input.target.value = '';
        input.target.setAttribute('ng-reflect-model', '');
        input.target.dispatchEvent(new Event('input'));
    }

    getGenderFromTitle(title: string) {
        if (this.maleTitles.indexOf(title) !== -1) {// all male categories
            return 'Male';
        } else if (this.femaleTitles.indexOf(title) !== -1) {// female categories
            return 'Female';
        } else {
            return this.UNSPECIFIC_GENDER;
        }
    }

    isNullOrUndefined(value: any) {
        if (value === null || value === undefined) {
            return true;
        } else {
            return false;
        }
    }
}
