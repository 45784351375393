import {Component, OnInit} from '@angular/core';
import {ImageService} from '../../shared/services/image.service';


@Component({
    selector: 'app-manager',
    templateUrl: './app_not_allowed.component.html'
})
export class AppNotAllowedComponent implements OnInit {

    robonIcon: string;

    isPreLoginUser: boolean;

    constructor(
        private imageService: ImageService) {
        this.robonIcon = imageService.getImage('ERROR-ROBOT');
    }

    ngOnInit() {

    }

    logout() {

    }

    goBack() {


    }
}
