import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuoteModule} from '../quote/quote.module';
import {SharedModule} from '../shared/shared.module';
import {MSTINeedHelpComponent} from './need-help/need-help.component';
import {FooterComponent} from './footer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NeedHelpService} from './need-help/services/need-help.service';
import {NeedHelpThankYouMessageComponent} from './need-help/thank-you-message/thank-you-message.component';
import {FooterRoutingModule} from './footer-routing.module';
import {NeedHelpDialogService} from './need-help/services/need-help-dialog.service';

@NgModule({
    declarations: [FooterComponent, MSTINeedHelpComponent, NeedHelpThankYouMessageComponent],
    imports: [
        CommonModule,
        QuoteModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        FooterRoutingModule
    ],
    entryComponents: [
        MSTINeedHelpComponent
    ],
    exports: [FooterComponent],
    providers: [NeedHelpService, NeedHelpDialogService]
})
export class FooterModule {
}
