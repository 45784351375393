import {Component, Injector} from '@angular/core';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';
import {ImageService} from '../../../../../services/image.service';

@Component({
    selector: 'app-radiobutton-yesno-dynamic-form-control',
    templateUrl: './radiobutton-yesno-dynamic-form-control.component.html',
    styleUrls: ['./radiobutton-yesno-dynamic.component.scss']
})
export class RadioButtonYesNoDynamicFormControlComponent extends BaseDynamicFormControl<string> {

    infoIcon: string;

    private imageService: ImageService;

    constructor(
        injector: Injector
    ) {
        super(injector);
        this.imageService = injector.get(ImageService);
    }

    doInit() {
        this.infoIcon = this.imageService.getImage('INFO-ICON');
    }

    updateFormControlValue(inputValue: any) {
        // TODO - Implement me
    }

    clearValue() {
        this.dynamicControlModel.value = null;
    }

}
