export class PersonalBelongingsSpecifiedModel {

    public contentRiskId: string;
    public quoteId: string;

    constructor(
        public id: string,
        public category: string,
        public categoryCode: string,
        public description: string,
        public value: number
    ) {
    }

}

