export class PremiumPersonalInfoModel {

    fullName: string;
    identityNumber: string;
    emailAddress: string;
    cellNumber: string;

    constructor(
        fullName: string,
        identityNumber: string,
        emailAddress: string,
        cellNumber: string
    ) {
        this.fullName = fullName;
        this.identityNumber = identityNumber;
        this.emailAddress = emailAddress;
        this.cellNumber = cellNumber;
    }

}
