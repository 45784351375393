export class AdminFeeModel {
  public brokerFeeCapture: boolean;
  public brokerFee: number;

  setAdminFeeModel(brokerFeeCapture: boolean, brokerFee: number) {

    this.brokerFeeCapture = brokerFeeCapture;
    this.brokerFee = brokerFee;
  }
}
