import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {TextboxDynamicFormControlComponent} from './textbox-dynamic-form-control.component';

export class TextboxDynamicControlModel extends BaseDynamicControlModel<string> {

    constructor(controlModel?: Partial<TextboxDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return TextboxDynamicFormControlComponent;
    }

}
