export class CifSecurityFeatureModel {

    securityFeatureResourceRef: string;
    securityFeatureCode: string;

    constructor(
        securityFeatureResourceRef: string,
        securityFeatureCode: string
    ) {
        this.securityFeatureResourceRef = securityFeatureResourceRef;
        this.securityFeatureCode = securityFeatureCode;
    }

}
