import {Injectable, Injector} from '@angular/core';
import {LookupService} from '../../../../shared/lookup/lookup.service';
import {VehicleModelModel} from '../model/vehicle-model.model';
import {UrlService} from '../../../../shared/services/url.service';
import {NumberLookupModel} from '../../../../shared/lookup/number-lookup.model';
import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {DefaultLookupModel} from '../../../../shared/lookup/default-lookup.model';
import {VehicleDescriptionModel} from '../model/vehicle-description.model';
import {VehicleDetailsModel} from '../model/vehicle-details.model';
import {VehicleDriverDetailsModel} from '../model/vehicle-driver-details.model';
import {CifVehicleModel} from '../model/cif/cif-vehicle.model';
import {CifRegularDriverModel} from '../model/cif/cif-regular-driver.model';
import {VehicleUrlService} from './vehicle-url.service';
import {VehicleRegularDriverUpdateModel} from '../model/cif/helper/vehicle-regular-driver-update.model';
import {Logger} from '../../../../shared/utilities/logger';
import {Level} from '../../../../shared/utilities/logger-level';
import {CifQuoteModel} from '../../../model/cif/cif-quote.model';
import {merge, Subject} from 'rxjs';
import {Vehicle} from '../../vehicle/model/cif/helper/vehicle.model';
import {BaseRiskService} from '../../base/service/base-risk.service';
import {SaveClientResponseModel} from '../../../../personal/model/SaveClientModels/save-client-response.model';
import {BaseCifRiskModel} from '../../base/model/base-cif-risk.model';
import {CifClientInfoModel} from '../../../model/cif/cif-client-info.model';
import {PifVehicleAddressModel} from '../model/pif/pif-vehicle-address.model';
import {PifRegularDriverRetrieveModel} from '../model/pif/pif-regular-driver-retrieve.model';

@Injectable()
export class VehicleService extends BaseRiskService {

  private static readonly VEHICLE_START_YEAR = 1950;
  private static readonly TAB_INDEX_VEHICLE_DESCRIPTION = 0;
  private static readonly TAB_INDEX_VEHICLE_DETAILS = 1;
  private static readonly TAB_INDEX_DRIVER_DETAILS = 2;

  public static readonly CODE_VEHICLE_CONDITION_NEW = '001';
  public static readonly CODE_VEHICLE_USED = '003';
  public static readonly DESCRIPTION_VEHICLE_CONDITION_NEW = 'Brand New';
  public static readonly DESCRIPTION_VEHICLE_USED = 'Used';
  public static readonly MALE_GENDER_TITLES = ['Mr'];
  public static readonly FEMALE_GENDER_TITLES = ['Miss', 'Ms', 'Mrs'];
  public static readonly UNSPECIFIC_GENDER = 'unspecific';
  public static readonly MALE_GENDER = 'MALE';
  public static readonly FEMALE_GENDER = 'FEMALE';

  constructor(injector: Injector, private lookupService: LookupService, private urlService: UrlService,
              private vehicleUrlService: VehicleUrlService
  ) {
    super(injector);
  }

  doDestroy(): void {
  }

  instantiateRiskModel(client: SaveClientResponseModel): BaseCifRiskModel {
    return new CifVehicleModel(client);
  }

  getAddRiskToQuoteURL(quoteId): string {
    return this.vehicleUrlService.getAddVehicleToQuoteURL(quoteId);
  }

  getDeleteRiskURL(quoteId: string, riskId: string): string {
    return this.vehicleUrlService.getDeleteVehicleURL(quoteId, riskId);
  }

  private initVehicleDescriptionModel(cardId: string) {
    if (!this.cardModels[cardId].vehicleDescriptionModel) {
      this.cardModels[cardId].vehicleDescriptionModel = new VehicleDescriptionModel();
    }
  }

  private initVehicleDetailsModel(cardId: string) {
    if (!this.cardModels[cardId].vehicleDetailsModel) {
      this.cardModels[cardId].vehicleDetailsModel = new VehicleDetailsModel();
    }
  }

  private initVehicleDriverDetailsModel(cardId: string) {
    if (!this.cardModels[cardId].vehicleDriverDetailsModel) {
      this.cardModels[cardId].vehicleDriverDetailsModel = new VehicleDriverDetailsModel();
    }
  }

  doFormDataPersist(data: any) {
    if (data.tabIndex === VehicleService.TAB_INDEX_VEHICLE_DESCRIPTION) {
      this.initVehicleDescriptionModel(data.cardId);
      this.cardModels[data.cardId].vehicleDescriptionModel.populateFromFormData(data.formData);

      this.cardModels[data.cardId].cifRiskModel.populateFromVehicleDescriptionModel(
        this.cardModels[data.cardId].vehicleDescriptionModel
      );

    } else if (data.tabIndex === VehicleService.TAB_INDEX_VEHICLE_DETAILS) {
      this.initVehicleDetailsModel(data.cardId);
      this.cardModels[data.cardId].vehicleDetailsModel.populateFromFormData(data.formData);

      this.cardModels[data.cardId].cifRiskModel.populateFromVehicleDetailsModel(
        this.cardModels[data.cardId].vehicleDetailsModel, this.getClientHomeAddressDetails()
      );

      this.saveVehicleDetails(data.cardId);

    } else if (data.tabIndex === VehicleService.TAB_INDEX_DRIVER_DETAILS) {
      this.initVehicleDriverDetailsModel(data.cardId);
      this.cardModels[data.cardId].vehicleDriverDetailsModel.populateFromFormData(data.formData);

      this.cardModels[data.cardId].cifRiskModel.populateFromVehicleDriverDetailsModel(
        this.cardModels[data.cardId].vehicleDriverDetailsModel
      );
      this.cardModels[data.cardId].cifRiskModel.regularDriver.clientResourceRef = this.cardModels[data.cardId].cifRiskModel.regularDriver.clientResourceRef;
      this.saveVehicleRegularDriverDetails(this.cardModels[data.cardId].cifRiskModel.regularDriver, data.cardId);

    }
  }

  private saveVehicleDetails(cardId: string) {
    const quoteId = this.quoteService.quoteId;
    const cifVehicleModel = this.cardModels[cardId].cifRiskModel;
    const riskId = cifVehicleModel.riskResourceRef;
    Logger.log(Level.LOG, 'cifVehicleModel1:', cifVehicleModel);
    /* const clientInfo = CifClientInfoModel.create({
       clientResourceRef: cifVehicleModel.clientResourceRef,
       clientName: `${cifVehicleModel._clientModel.personal.firstName} ${cifVehicleModel._clientModel.personal.lastName}`,
       clientNumber: cifVehicleModel.clientNumber
     });*/
    const clientInfo = CifClientInfoModel.create({
      clientResourceRef: this.userService.getUser().clientResourceRef,
      clientName: this.userService.getUser().firstName + ' ' + this.userService.getUser().lastName,
      clientNumber: this.userService.getUser().clientNumber
    });

    Logger.log(Level.LOG, 'clientInfo:', clientInfo);

    const quoteModel = CifQuoteModel.getMappedVehicleModel(clientInfo, cifVehicleModel);

    if (riskId) {
      this.updateVehicle(quoteModel, cardId, quoteId, riskId);
    } else {
      if (quoteId) {
        this.addVehicleToQuote(quoteModel, cardId, quoteId);
      } else {
        this.addVehicleAndQuote(quoteModel, cardId);
      }
    }
  }

  private saveVehicleRegularDriverDetails(cifRegularDriverModel: CifRegularDriverModel, cardId: string) {
    const quoteId = this.quoteService.quoteId;
    const riskId = this.cardModels[cardId].cifRiskModel.riskResourceRef;
    const vehicleRegularDriverUpdateModel = VehicleRegularDriverUpdateModel.getMappedModel(cifRegularDriverModel);

    if (quoteId) {
      this.updateVehicleRegularDriver(vehicleRegularDriverUpdateModel, quoteId, riskId);
    }
  }

  private addVehicleAndQuote(quoteModel: CifQuoteModel, cardId: string) {
    this.quoteService.saveQuote(quoteModel).then(response => {
      this.processRiskIdsInQuoteSaveResponse(response.riskReferences, cardId);
    });
  }

  private addVehicleToQuote(quoteModel: CifQuoteModel, cardId: string, quoteId: string) {
    if (quoteModel.vehicleRisks.length === 1) {
      this.saveRiskToQuote(quoteId, quoteModel.vehicleRisks[0]).then(response => {
        this.processRiskIdInSaveResponse(response, cardId);
      });
    }
  }

  processRiskIdInSaveResponse(riskId: string, cardId: string) {
    if (riskId) {
      this.cardModels[cardId].cifRiskModel.riskResourceRef = riskId;
    }
  }

  private updateVehicle(quoteModel: CifQuoteModel, cardId: string, quoteId: string, riskId: string) {
    if (quoteModel.vehicleRisks.length === 1) {
      this.saveVehicle(quoteId, quoteModel.vehicleRisks[0], riskId).then(response => {
        this.processRiskIdInSaveResponse(response, cardId);
      });
    }
  }

  public async saveVehicle(quoteId: string, vehicleModel: Vehicle, riskId: string): Promise<string> {
    this.loaderService.show();
    await this.mstiService.putWithAbAuth(this.vehicleUrlService.getUpdateVehicleURL(quoteId, riskId), vehicleModel)
      .toPromise().then((response: any) => {
        const cifVehicleModel: CifVehicleModel = Object.assign(
          new CifVehicleModel(this.personalService.getClient()), response
        );
        riskId = cifVehicleModel.riskResourceRef;
        Logger.log(Level.LOG, 'Save vehicle response:', cifVehicleModel);
      }, (error) => {
        Logger.log(Level.ERROR, 'Error while saving vehicle', error);
        this.mstiErrorService.handleError(error);
      }).then(() => {
        this.loaderService.hide();
      });

    return riskId;
  }

  private updateVehicleRegularDriver(vehicleRegularDriverUpdateModel: VehicleRegularDriverUpdateModel, quoteId: string, riskId: string) {
    if (vehicleRegularDriverUpdateModel) {
      this.saveVehicleRegularDriver(vehicleRegularDriverUpdateModel, quoteId, riskId).then(response => {
        Logger.log(Level.LOG, 'save vehicle regular driver response:', response);
      });
    }
  }

  public async saveVehicleRegularDriver(vehicleRegularDriverUpdateModel: VehicleRegularDriverUpdateModel, quoteId: string, riskId: string):
    Promise<boolean> {
    this.loaderService.show();
    let updateSucceeded = false;
    await this.mstiService.putWithAbAuth( this.vehicleUrlService.getUpdateVehicleRegularDriverURL(quoteId, riskId), vehicleRegularDriverUpdateModel
    ).toPromise().then((response: any) => {
      updateSucceeded = true;
      Logger.log(Level.LOG, 'Save vehicle regular driver details response:', response);
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while saving vehicle regular driver details', error);
      this.mstiErrorService.handleError(error);
    }).then(() => {
      this.loaderService.hide();
    });

    return updateSucceeded;
  }

  retrieveRiskDataFromCif(riskId: string, cardId: string): Promise<any> {
    this.loaderService.show();
    this.initCardModel(cardId);
    const vehicleCompleteSubject = new Subject<any>();
    this.mstiService.getWithAbAuth(
      this.vehicleUrlService.getRetrieveVehicleURL(this.quoteService.quoteId, riskId)
    ).subscribe(async (response) => {
      // Todo remove the below clientResourceRef assignment once vehicle V2 changes are done

      console.log('Ab11', response);
      this.cardModels[cardId].cifRiskModel = Object.assign(new CifVehicleModel(this.personalService.getClient()), response, {addresses: PifVehicleAddressModel.pifToCif(response.riskAddress)}
      );
      console.log('Ab12', this.cardModels[cardId].cifRiskModel);
      await this.populateVehicleDescriptionFromCifModel(cardId);
      await this.populateVehicleDetailsFromCifModel(cardId);
      this.loaderService.hide();
      vehicleCompleteSubject.complete();
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while retrieving vehicle details', error);
      this.loaderService.hide();
      vehicleCompleteSubject.error(error);
      this.mstiErrorService.handleError(error);
    });

    this.loaderService.show();

    const driverCompleteSubject = new Subject<any>();
    this.mstiService.getWithAbAuth(this.vehicleUrlService.getRetrieveVehicleDriverURL(this.quoteService.quoteId, riskId
    )).subscribe(async (response: PifRegularDriverRetrieveModel) => {
      this.cardModels[cardId].cifVehicleDriverModel = PifRegularDriverRetrieveModel.pifToCifRegularDriverModel(response);
      await this.populateVehicleDriverDetailsFromCifModel(cardId);
      this.loaderService.hide();
      driverCompleteSubject.complete();
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while retrieving vehicle driver details', error);
      this.loaderService.hide();
      driverCompleteSubject.error(error);
      this.mstiErrorService.handleError(error);
    });

    return merge(vehicleCompleteSubject, driverCompleteSubject).toPromise();
  }

  private async populateVehicleDescriptionFromCifModel(cardId: string) {
    this.initVehicleDescriptionModel(cardId);
    await this.cardModels[cardId].vehicleDescriptionModel.populateFromCifModel(
      this.cardModels[cardId].cifRiskModel,
      this.lookupService,
      this
    );
  }

  private async populateVehicleDetailsFromCifModel(cardId: string) {
    this.initVehicleDetailsModel(cardId);

    await this.cardModels[cardId].vehicleDetailsModel.populateFromCifModel(
      this.cardModels[cardId].cifRiskModel,
      this.lookupService
    );
  }

  private async populateVehicleDriverDetailsFromCifModel(cardId: string) {
    this.initVehicleDriverDetailsModel(cardId);

    await this.cardModels[cardId].vehicleDriverDetailsModel.populateVehicleDriverFromCifModel(
      this.cardModels[cardId].cifVehicleDriverModel,
      this.lookupService
    );
  }

  getExistingCardHeading(cardId: string): string {
    const vehicleDescriptionModel = this.cardModels[cardId].vehicleDescriptionModel;

    if (vehicleDescriptionModel) {
      // TODO - This logic is duplicated in the vehicle description component
      let cardHeading = vehicleDescriptionModel.vehicleRegistrationNumber ? vehicleDescriptionModel.vehicleRegistrationNumber : '';
      cardHeading += vehicleDescriptionModel.vehicleRegistrationNumber
      && ((vehicleDescriptionModel.vehicleYear && vehicleDescriptionModel.vehicleYear.getDescription) ||
        (vehicleDescriptionModel.vehicleMake && vehicleDescriptionModel.vehicleMake.getDescription)) ? ' - ' : '';
      cardHeading += vehicleDescriptionModel.vehicleYear && vehicleDescriptionModel.vehicleYear.getDescription ?
        vehicleDescriptionModel.vehicleYear.getDescription() : '';
      cardHeading += (vehicleDescriptionModel.vehicleYear && vehicleDescriptionModel.vehicleYear.getDescription) &&
      (vehicleDescriptionModel.vehicleMake && vehicleDescriptionModel.vehicleMake.getDescription) ? ' ' : '';
      cardHeading += vehicleDescriptionModel.vehicleMake && vehicleDescriptionModel.vehicleMake.getDescription ?
        vehicleDescriptionModel.vehicleMake.getDescription() : '';
      cardHeading += (cardHeading.length > 0) && (vehicleDescriptionModel.vehicleColour &&
        vehicleDescriptionModel.vehicleColour.getDescription) ? ' - ' : '';
      cardHeading += vehicleDescriptionModel.vehicleColour && vehicleDescriptionModel.vehicleColour.getDescription ?
        vehicleDescriptionModel.vehicleColour.getDescription() : '';

      return cardHeading;
    } else {
      return '';
    }
  }

  public getVehicleDescriptionModel(cardId: string): VehicleDescriptionModel {
    if (!this.cardModels[cardId]) {
      this.initCardModel(cardId);
    }

    if (!this.cardModels[cardId].vehicleDescriptionModel) {
      this.initVehicleDescriptionModel(cardId);
    }

    return this.cardModels[cardId].vehicleDescriptionModel;
  }

  public getVehicleDetailsModel(cardId: string): VehicleDetailsModel {
    if (!this.cardModels[cardId]) {
      this.initCardModel(cardId);
    }

    if (!this.cardModels[cardId].vehicleDetailsModel) {
      this.initVehicleDetailsModel(cardId);
    }

    return this.cardModels[cardId].vehicleDetailsModel;
  }

  public getVehicleDriverDetailsModel(cardId: string): VehicleDriverDetailsModel {
    if (!this.cardModels[cardId]) {
      this.initCardModel(cardId);
    }

    if (!this.cardModels[cardId].vehicleDriverDetailsModel) {
      this.initVehicleDriverDetailsModel(cardId);
    }

    return this.cardModels[cardId].vehicleDriverDetailsModel;
  }

  getYears(): NumberLookupModel[] {
    const yearValues = this.getYearValues();

    return yearValues.map((yearValue) => {
      return new NumberLookupModel(yearValue);
    });
  }

  private getYearValues(): number[] {
    const currentYear = (new Date()).getFullYear();

    return Array.from(Array(currentYear - VehicleService.VEHICLE_START_YEAR + 1), (_, x) => x + VehicleService.VEHICLE_START_YEAR)
      .reverse();
  }

  async getMakes(vehicleType: string, year: string) {
    const url = this.urlService.getUrl(UrlService.KEY_VEHICLE_MAKES) + '?vehicleType=' + vehicleType + '&year=' + year;
    let vehicleMakes: DefaultLookupModel[];

    await this.mstiService.get(url).toPromise().then(
      (response) => {
        const responseArray = <LookupModel[]>response;
        // Note: this is required in order to preserve the functions on the DefaultLookupModel objects
        vehicleMakes = responseArray.map((singleResponse) => {
          return Object.assign(new DefaultLookupModel(), singleResponse);
        });
      },
      (error) => {
        Logger.log(Level.ERROR, 'Unable to retrieve vehicle makes for vehicleType: ' + vehicleType + ' and year: ' + year, error);
        this.mstiErrorService.handleError(error);
      }
    );

    return vehicleMakes;
  }

  getMakeByCode(vehicleMakes: DefaultLookupModel[], vehicleMakeCode: string): DefaultLookupModel {
    return vehicleMakes.find(vehicleMake => {
      return vehicleMake.getCode() === vehicleMakeCode;
    });
  }

  async getModels(vehicleType: string, year: string, makeId: string) {
    const url = this.urlService.getUrl(UrlService.KEY_VEHICLE_MODELS) + '?vehicleType=' + vehicleType +
      '&year=' + year + '&makeId=' + makeId;
    let vehicleModels: VehicleModelModel[];

    await this.mstiService.get(url).toPromise().then(
      (response) => {
        const responseArray = <LookupModel[]>response;
        // Note: this is required in order to preserve the functions on the DefaultLookupModel objects
        vehicleModels = responseArray.map((singleResponse) => {
          return Object.assign(new VehicleModelModel(), singleResponse);
        });
      },
      (error) => {
        Logger.log(Level.ERROR, 'Unable to retrieve vehicle models for vehicleType: ' + vehicleType + ' and year: ' +
          year + ' and makeId: ' + makeId, error);
        this.mstiErrorService.handleError(error);
      }
    );

    return vehicleModels;
  }

  getModelByCode(vehicleModels: VehicleModelModel[], vehicleModelCode: string): VehicleModelModel {
    return vehicleModels.find(vehicleModel => {
      return vehicleModel.getCode() === vehicleModelCode;
    });
  }

  async getConditions() {
    return await this.lookupService.getLookup(LookupService.GROUP_VEHICLE_CONDITION);
  }

  async getColours() {
    return await this.lookupService.getLookup(LookupService.GROUP_VEHICLE_COLOUR);
  }

  async getTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_VEHICLE_TYPE);
  }

  async getPossessions() {
    return await this.lookupService.getLookup(LookupService.GROUP_VEHICLE_POSSESSION);
  }

  async getModifications() {
    return await this.lookupService.getLookup(LookupService.GROUP_VEHICLE_MODIFICATION);
  }

  async getSecurityDevices() {
    return await this.lookupService.getLookup(LookupService.GROUP_VEHICLE_SECURITY_DEVICE);
  }

  async getStorageTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_RISK_STORAGE_TYPE);
  }

  async getAreaTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_VEHICLE_AREA_TYPE);
  }

  async getTitles() {
    return await this.lookupService.getLookup(LookupService.GROUP_TITLE);
  }

  async getGenders() {
    return await this.lookupService.getLookup(LookupService.GROUP_GENDER);
  }

  async getMaritalStatus() {
    return await this.lookupService.getLookup(LookupService.GROUP_MARITAL_STATUS);
  }

  async getOccupations() {
    return await this.lookupService.getLookup(LookupService.GROUP_OCCUPATION);
  }

  async getRegularDriverRelatedInsurers() {
    return await this.lookupService.getLookup(LookupService.GROUP_REGULAR_DRIVER_RELATED_INSURER);
  }

  async getLicenseTypes() {
    return await this.lookupService.getLookup(LookupService.GROUP_LICENSE_TYPE);
  }

  async getLicenseLimitations() {
    return await this.lookupService.getLookup(LookupService.GROUP_LICENSE_LIMITATIONS);
  }

}
