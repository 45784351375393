import {Injectable} from '@angular/core';
import {MSTIConfiguration} from '../msti.config';

@Injectable()
export class BrokerUrlService {

  constructor() {
  }


  getBffUrl(): string {
    const url = MSTIConfiguration.constants.stiUrlPrefix + '/broker-bff/brokerClientsApi';
    return url;
  }


  getBrokerTokenUrl(): string {
    const url = MSTIConfiguration.constants.stiUrlPrefix + '/broker-bff/gblt';
    return url;
  }


  getBrokerRefUrl(brkRef: string): string {
    const url = this.getBffUrl() + '/' + brkRef;
    return url;
  }
}
