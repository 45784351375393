import {Component, Injector} from '@angular/core';
import {BaseRiskTabComponent} from '../../../base/component/base-risk-tab.component';
import {BaseRiskTabService} from '../../../base/service/base-risk-tab.service';
import {StructureDetailsService} from '../../service/structure-details.service';
import {Logger} from '../../../../../shared/utilities/logger';
import {Level} from '../../../../../shared/utilities/logger-level';
import {GoogleAnalyticsEventModel} from '../../../../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../../../../shared/google-analytics/model/google-analytics-action.model';

@Component({
    selector: 'app-structure-details',
    templateUrl: './structure-details.component.html',
    styleUrls: [],
    providers: [StructureDetailsService]
})

export class StructureDetailsComponent extends BaseRiskTabComponent {

    constructor(
        injector: Injector,
        private structureDetailsService: StructureDetailsService
    ) {
        super(injector);
    }

    getRiskTabService(): BaseRiskTabService {
        return this.structureDetailsService;
    }

    initAnalytics(): void {
        this.subscriptions.push(
            this.structureDetailsService.structureLightingHintClickedEvent.subscribe(() => {
                this.googleAnalyticsService.fireGoogleAnalytics(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_LIGHTNING_CONDUCTOR_ON_BUILDING
                );
            })
        );

        this.subscriptions.push(
            this.structureDetailsService.structureCommuneHintClickedEvent.subscribe(() => {
                this.googleAnalyticsService.fireGoogleAnalytics(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_COMMUNE_ON_BUILDING
                );
            })
        );

        this.subscriptions.push(
            this.structureDetailsService.structureApprovedHintClickedEvent.subscribe(() => {
                this.googleAnalyticsService.fireGoogleAnalytics(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_STRUCTURE_APPROVAL_ON_BUILDING
                );
            })
        );

        this.subscriptions.push(
            this.structureDetailsService.structureRiskAddressHintClickedEvent.subscribe(() => {
                this.googleAnalyticsService.fireGoogleAnalytics(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_LAPA_ON_BUILDING
                );
            })
        );

        this.subscriptions.push(
            this.structureDetailsService.structureHasThatchHintClickedEvent.subscribe(() => {
                this.googleAnalyticsService.fireGoogleAnalytics(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_THATCHED_ROOF_ON_BUILDING
                );
            })
        );

    }

    doComponentInit(): void {
        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('structureRoofMaterial').valueChanges.subscribe(
                selectedValue => {
                    this.structureDetailsService.isRoofMadeOfThatch(selectedValue);
                }
            ),
            this.cardTabContainerComponent.dynamicFormComponent.form.get('structureHasLapa').valueChanges.subscribe(
                selectedValue => {
                    this.structureDetailsService.isThereLapaAtRiskAddress(selectedValue);
                }
            ),
            this.cardTabContainerComponent.dynamicFormComponent.form.get('structureHasThatch').valueChanges.subscribe(
                selectedValue => {
                    this.structureDetailsService.doesLapaHaveThatchedRoof(selectedValue);
                }
            )
        );
    }

    propagatePostLoadUpdates(): void {
        // TODO - Implement me
        Logger.log(Level.LOG, 'propagatePostLoadUpdates');
    }

    getGoogleAnalyticsEventLabel(): string {
        return GoogleAnalyticsEventModel.EVENT_COMPLETE_STRUCTURE_DETAILS_ON_MY_BUILDING;
    }

}
