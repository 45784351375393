export class CifDisclosureQuestionsModel {
  insuranceHistory: CifInsuranceHistoryModel = new CifInsuranceHistoryModel();
  previousClaims: CifClaimHistoryModel[] = [];

}

export class CifInsuranceHistoryModel {
  public recordKey: string;
  public event: string;
  public currentlyInsured: boolean;
  public currentInsurerCode: string;
  public currentInsuredPeriodCode: string;
  public uninsuredPeriodCode: string;
  public uninterruptedCoverCode: string;
  public previousInsuranceCancelledCode: string;
}

export class CifClaimHistoryModel {
  public event: string;
  public sectionCode: string;
  public riskCode: string;
  public claimNumber: string;
  public dateOfClaim: string;
  public typeOfClaimCode: string;
  public claimAmount: string;
  public customeEntry: boolean;
  public previousClaimHistoryId: string;
}
