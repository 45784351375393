import {FilteredDropDownModel} from '../filtered-dropdown/filtered-drop-down.model';
import {DefaultLookupModel} from './default-lookup.model';

export class LookupFilteredDropDownModel extends FilteredDropDownModel<DefaultLookupModel> {

    constructor(values) {
        super();
        this.setValues(values);
    }

    doFilter(filterValue: string) {
        this.filteredValues = this.values.filter(option =>
            option.description.toLowerCase().startsWith(filterValue)
        );
    }

}
