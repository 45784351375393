import {Component, Injector} from '@angular/core';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';
import {TitleDynamicControlModel} from './title-dynamic-control.model';

@Component({
    selector: 'app-title-dynamic-form-control',
    templateUrl: './title-dynamic-form-control.component.html',
    styleUrls: ['./title-dynamic-control.component.scss']

})
export class TitleDynamicFormControlComponent extends BaseDynamicFormControl<string> {

    public static readonly STYLE_TITLE_NORMAL = 'dynamic-normal-title';
    public static readonly STYLE_TITLE_BOLD = 'bold-title';

    classes: string;
    textAlignClass: string;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    doInit() {
        // Title Styling
        if ((<TitleDynamicControlModel>this.dynamicControlModel).style === TitleDynamicFormControlComponent.STYLE_TITLE_NORMAL) {
            this.classes = 'dynamic-normal-title';
        }
        if ((<TitleDynamicControlModel>this.dynamicControlModel).style === TitleDynamicFormControlComponent.STYLE_TITLE_BOLD) {
            this.classes = 'dynamic-bold-title';
        }
        // Title position
        if ((<TitleDynamicControlModel>this.dynamicControlModel).pullRight === true) {
            this.textAlignClass = 'text-right'; // options : text-left, text-right , text-center
        }
        if ((<TitleDynamicControlModel>this.dynamicControlModel).center === true) {
            this.textAlignClass = 'text-center'; // options : text-left, text-right , text-center
        }

    }

    updateFormControlValue(inputValue: any) {
        // TODO - Implement me
    }

    clearValue() {
        // Not applicable for this control type
    }

}
