import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {Attribute, Directive, forwardRef} from '@angular/core';

@Directive({
    selector: '[validatePhoneNumber][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PhoneNumberValidator),
            multi: true
        }
    ]
})
export class PhoneNumberValidator implements Validator {

    constructor(@Attribute('validatePhoneNumber') public validatePhoneNumber: string) {
    }

    validate(control: AbstractControl): { [key: string]: any } {
        const phoneNumber = control.value;
        if (phoneNumber) {
            const pattern = /^\d{3}\d{3}\d{4}$/;
            if (!pattern.test(phoneNumber)) {
                return {
                    validateObj: {
                        valid: false,
                        msg: 'Invalid cell phone number'
                    }
                };
            }
        } else {
            return {
                validateObj: {
                    valid: false,
                    msg: 'This field is required'
                }
            };
        }
    }
}
