export class RadioGroupOption {

    constructor(
        public value: string,
        public label: string,
        public description: string,
        public checked: boolean
    ) { }

}
