import {Component, Injector} from '@angular/core';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';

@Component({
    selector: 'app-textbox-dynamic-form-control',
    templateUrl: './textbox-dynamic-form-control.component.html',
    styleUrls: ['./textbox-dynamic-control.component.scss']
})
export class TextboxDynamicFormControlComponent extends BaseDynamicFormControl<string> {

    type: string; // TODO - Confirm this field is used

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    doInit() { }

    updateFormControlValue(inputValue: any) {
        // TODO - Implement me
    }

    clearValue() {
        this.dynamicControlModel.value = null;
    }

}
