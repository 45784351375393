import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/index';
import {UrlService} from '../../../shared/services/url.service';
import {MSTIService} from '../../../shared/services/msti.service';
import {BrokerDetailsService} from '../../../shared/broker-common/broker-details.service';
import {PersonalService} from '../../../personal/service/personal.service';
import {NeedHelpPageTags} from '../need-help-page-tags';
import {GetAdviceModel} from '../models/get-advice.model';
import {GetClientIdentityModel} from '../models/GetClientIdentityModel';

@Injectable()
export class NeedHelpService {

  currentPageChange: Subject<boolean> = new Subject<boolean>();
  private currentPage: string;

  constructor(private urlService: UrlService, private mstiService: MSTIService,
              private userService: BrokerDetailsService, private personalService: PersonalService) {
  }

  public setCurrentPage(currentPage: string) {
    this.currentPage = currentPage;
    const page = NeedHelpPageTags.getStageCodeForPageLabel(currentPage);
    if (page === 'wildPage' || page === 'BO08') { // check to disable do you need help in certain pages
      this.currentPageChange.next(false);
    } else {
      this.currentPageChange.next(true);
    }
  }

  public getCurrentPage(): string {
    return this.currentPage;
  }

  public getCurrentPageStageCode(): string {
    return NeedHelpPageTags.getStageCodeForPageLabel(this.currentPage);
  }

  public getAdvice(getAdviceModel: GetAdviceModel) {
    const url = this.urlService.getAdviceUrl();
    return this.mstiService.postWithAbAuth(url, getAdviceModel);
  }


  public getAdviceData(message: string, callMeBack: Boolean): GetAdviceModel {
    // TODO Ab changes for broker
    const getAdviceModel: GetAdviceModel = new GetAdviceModel();
    const clientIdentityModel: GetClientIdentityModel = new GetClientIdentityModel();
    getAdviceModel.adviceReason = message;
    clientIdentityModel.identityType = 'RSA_ID';
    clientIdentityModel.identityValue = this.user.idNumber;
    getAdviceModel.clientIdentity = clientIdentityModel;
    if (callMeBack !== null) {
      this.currentPage = NeedHelpPageTags.PAGE_ERROR; // Set the page stage code as BO08 for call me back
    }
    getAdviceModel.adviceStageCode = this.getCurrentPageStageCode();
    getAdviceModel.contactForAdvice = callMeBack;
    return getAdviceModel;
  }

  public getAdviceDataForQuoteAccept(message: string, callMeBack: Boolean): GetAdviceModel {
    const getAdviceModel: GetAdviceModel = new GetAdviceModel();
    const clientIdentityModel: GetClientIdentityModel = new GetClientIdentityModel();
    getAdviceModel.adviceReason = message;
    clientIdentityModel.identityType = 'RSA_ID';
    clientIdentityModel.identityValue = this.user.idNumber;
    getAdviceModel.clientIdentity = clientIdentityModel;
    this.currentPage = NeedHelpPageTags.PAGE_LABEL_QUOTE_PREMIUM_PAGE_CLIENT_APPROVES; // Set the page stage code as BO08 for call me back
    getAdviceModel.adviceStageCode = this.getCurrentPageStageCode();
    getAdviceModel.contactForAdvice = callMeBack;
    return getAdviceModel;
  }

  get user() {
    return this.userService.getUser();
  }

  get personalInfo() {
    return this.personalService.getClientPersonalDetailModel();
  }

}
