import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AppNotAllowedComponent} from './components/app_not_allowed.component';


const appRoutes: Routes = [

    {path: 'app_not_allowed', component: AppNotAllowedComponent, data: {routeIdx: 'login'}},

  ]
;

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})

export class AppManagerRoutingModule {


}
