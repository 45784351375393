import {Component, EventEmitter, Output} from '@angular/core';
import {RiskOptionModel} from '../../../shared/risk/risk.option.model';
import {RiskPickService} from './risk-pick.service';
import {DigitalAnalyticsService} from '../../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsElementModel} from '../../../shared/digital-analytics/model/digital-analytics-element.model';
import {RiskOptionDataService} from '../../../shared/risk/risk-options-data.service';
import {GoogleAnalyticsService} from '../../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../../shared/google-analytics/model/google-analytics-action.model';

@Component({
    selector: 'app-risk-pick',
    templateUrl: './pick.component.html'
})
export class PickComponent {

    @Output() onRiskSelected = new EventEmitter();

    riskOptions: RiskOptionModel[] = [];

    showDefaultRiskClassAsCenter = true;
    whichRiskClassQuoteToShow = 1;

    constructor(
        private quotePickService: RiskPickService,
        private digitalAnalyticsService: DigitalAnalyticsService,
        private googleAnalyticsService: GoogleAnalyticsService,
    ) {
        this.riskOptions = this.quotePickService.getRiskOptions();
        this.determineStyle();
    }

    selectRiskOption(risk: RiskOptionModel) {
        risk.isSelected = !risk.isSelected;

        if (risk.isSelected === true) {
            switch (risk.quoteDescription) {
                case RiskOptionDataService.RISK_DESCRIPTION_VEHICLE: {
                    const attributes: Map<number, string> = new Map<number, string>();
                    this.digitalAnalyticsService.constructAndFireElementTag(
                        DigitalAnalyticsElementModel.ELEMENT_MARKETING_MY_CAR, attributes
                    );
                    this.googleAnalyticsService.fireGoogleAnalytics(
                        GoogleAnalyticsActionModel.ACTION_MY_CAR,
                        GoogleAnalyticsEventModel.EVENT_SELECT_COVER_FROM_MARKETING_PAGE
                    );
                    break;
                }
                case RiskOptionDataService.RISK_DESCRIPTION_CONTENTS: {
                    const attributes: Map<number, string> = new Map<number, string>();
                    this.digitalAnalyticsService.constructAndFireElementTag(
                        DigitalAnalyticsElementModel.ELEMENT_MARKETING_HOME_CONTENTS, attributes
                    );
                    this.googleAnalyticsService.fireGoogleAnalytics(
                        GoogleAnalyticsActionModel.ACTION_HOME_CONTENTS,
                        GoogleAnalyticsEventModel.EVENT_SELECT_COVER_FROM_MARKETING_PAGE
                    );
                    break;
                }
                case RiskOptionDataService.RISK_DESCRIPTION_BUILDING: {
                    const attributes: Map<number, string> = new Map<number, string>();
                    this.digitalAnalyticsService.constructAndFireElementTag(
                        DigitalAnalyticsElementModel.ELEMENT_MARKETING_BUILDING, attributes
                    );
                    this.googleAnalyticsService.fireGoogleAnalytics(
                        GoogleAnalyticsActionModel.ACTION_MY_BUILDING,
                        GoogleAnalyticsEventModel.EVENT_SELECT_COVER_FROM_MARKETING_PAGE
                    );
                    break;
                }
                default: {
                    break;
                }
            }
        }

        this.onRiskSelected.emit();
    }

    determineStyle() {
        this.showDefaultRiskClassAsCenter = true;

        if (this.riskOptions.length <= 2) {
            this.whichRiskClassQuoteToShow = 1;
        } else if (this.riskOptions.length === 5 || this.riskOptions.length === 8) {
            this.whichRiskClassQuoteToShow = 3;
        } else {
            this.showDefaultRiskClassAsCenter = false;
            this.whichRiskClassQuoteToShow = 2;
        }
    }

}
