import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ImageService} from '../../services/image.service';
import {LogoutService} from '../../services/logout.service';
import {NeedHelpService} from '../../../footer/need-help/services/need-help.service';
import {NeedHelpPageTags} from '../../../footer/need-help/need-help-page-tags';
import {BrokerDetailsService} from '../../broker-common/broker-details.service';
import {RoutingService} from '../../services/routing.service';
import {DigitalAnalyticsService} from '../../digital-analytics/digital-analytics.service';
import {DigitalAnalyticsPageModel} from '../../digital-analytics/model/digital-analytics-page.model';
import {GoogleAnalyticsService} from '../../google-analytics/google-analytics.service';
import {SmoothScroll} from '../../utilities/smooth-scroll';

@Component({
  selector: 'app-msti-error',
  templateUrl: './msti-error.component.html'
})
export class MstiErrorComponent implements OnInit {

  noSuburbIcon: any;
  public errorHeading: string;
  public errorDescription: string;
  public actionCode: string;
  public errorCode: string;
  public showReturnToPortfolio = true;

  constructor( private imageService: ImageService,  private logoutService: LogoutService,  private route: ActivatedRoute,
    private needHelpService: NeedHelpService, private userService: BrokerDetailsService,  private routingService: RoutingService,
    private digitalAnalyticsService: DigitalAnalyticsService,  private googleAnalyticsService: GoogleAnalyticsService) {
    this.route.queryParams.subscribe(params => {
      this.actionCode = params['actionCode'];
      this.errorCode = params['errorCode'];
      if (this.errorCode === 'Technical') {
        this.errorHeading = params['actionDescription'];
        this.errorDescription = params['errorMessage'];
      } else {
        this.errorHeading = params['actionDescription'];
      }
    });
  }

  ngOnInit() {
    SmoothScroll.smoothScrollToTop();
    this.initializeIcons(this.imageService);
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_TECHNICAL_ERROR);

    this.googleAnalyticsService.fireGoogleAnalytics(
      `Action Code: ${this.actionCode}
             Error Code: ${this.errorCode}
             Error Heading: ${this.errorHeading}
             Error Description: ${this.errorDescription}
            `,
      'ERROR'
    );
  }

  private initializeIcons(imageService: ImageService) {
    this.noSuburbIcon = imageService.getImage('ERROR-ROBOT');
    if (this.actionCode === '442') {
      this.showReturnToPortfolio = true;
    } else if (this.actionCode === '439') {
      this.showReturnToPortfolio = false;
    } else { // If any other action code is received
      this.showReturnToPortfolio = true;
      const adviceModel = this.needHelpService.getAdviceData('Error occurred, Unknown action code received', true);
      this.needHelpService.getAdvice(adviceModel).subscribe((response) => {

      });
    }

    if (this.errorCode === '5000001190') { // This is the code for suburb not found
      const attributes: Map<number, string> = new Map<number, string>();
      this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_SUBURB_NOT_FOUND_PAGE_ID, attributes);

      this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_PERSONAL_INFORMATION_SUBURB_NOT_FOUND);
      this.noSuburbIcon = imageService.getImage('NO-SUBURB');
    }
  }

  logout() { // Return to portfolio button click
    this.logoutService.backToPortfolio();
  }

  callMeBack(selection: boolean) { // call me back Yes/NO button click
    const adviceModel = this.needHelpService.getAdviceData('Error occurred', selection);
    this.needHelpService.getAdvice(adviceModel).subscribe((response) => {
      this.routingService.route('app-need-help-thankyou');
    });
  }

  goBack() {
    if (this.errorCode === 'Technical') {
      this.logoutService.backToPreLoginMarketing();
    } else {
      this.logoutService.backToMomCoZa();
    }

  }
}
