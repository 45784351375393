import {CifSecurityFeatureModel} from '../../../vehicle/model/cif/cif-security-feature.model';
import {PifRiskAddressModel} from '../../../../model/pif/pif-risk-address.model';

export class CifMovablesModel {

  public static readonly SMI_CODE_UNSPECIFIED = '159';

  riskResourceRef: string;
  riskDescription: string;
  sectionCode: string;
  sumInsured: string;
  sumInsuredDescription: string;
  riskAddress: PifRiskAddressModel; // CifRiskAddressModel;
  makeModelDescription: string;
  smiCode: string;
  smiDescription: string;
  securityFeatures: CifSecurityFeatureModel[];

}
