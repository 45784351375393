import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {AutocompleteDynamicFormControlComponent} from './autocomplete-dynamic-form-control.component';
import {Observable} from 'rxjs';
import {LookupModel} from '../../../../../lookup/lookup.model';

export class AutocompleteDynamicControlModel extends BaseDynamicControlModel<LookupModel> {

    availableOptionsObservable: Observable<LookupModel[]>;

    constructor(controlModel?: Partial<AutocompleteDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return AutocompleteDynamicFormControlComponent;
    }

}
