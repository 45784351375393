import {Injectable} from '@angular/core';
import {LookupService} from '../../shared/lookup/lookup.service';
import {SaveClientModel} from '../model/SaveClientModels/save-client.model';
import {MSTIService} from '../../shared/services/msti.service';
import {ClientUrlService} from './client-url.service';
import {SaveClientResponseModel} from '../model/SaveClientModels/save-client-response.model';
import {PersonalDetailModel} from '../model/personal-detail.model';

@Injectable()
export class PersonalService {

    private personalDetailModel: PersonalDetailModel;

    clientDetails: SaveClientResponseModel;

    constructor(
        private lookupService: LookupService,
        private mstiService: MSTIService,
        private clientUrlService: ClientUrlService,
    ) { }

    async getGenders() {
        return await this.lookupService.getLookup(LookupService.GROUP_GENDER);
    }

    async getMaritalStatuses() {
        return await this.lookupService.getLookup(LookupService.GROUP_MARITAL_STATUS);
    }

    async getOccupations() {
        return await this.lookupService.getLookup(LookupService.GROUP_OCCUPATION);
    }

    async getTitles() {
        return await this.lookupService.getLookup(LookupService.GROUP_TITLE);
    }

    saveClient(clientModel: SaveClientModel) {
        const url = this.clientUrlService.getSaveClientURL();
        return this.mstiService.postWithAbAuth(url, clientModel);
    }

    updateClient(clientModel: SaveClientResponseModel) {
        const url = this.clientUrlService.getUpdateClientURL(clientModel.clientResourceRef);
        return this.mstiService.putWithAbAuth(url, clientModel);
    }

    getClientDetails(clientResourceRef: string) {
        const url = this.clientUrlService.getClientDetailsURL(clientResourceRef);
        return this.mstiService.getWithAbAuth(url);
    }

    getClient(): SaveClientResponseModel {
        return this.clientDetails;
    }

    getClientPersonalDetailModel(): PersonalDetailModel {
        return this.personalDetailModel;
    }

    setClientPersonalDetailModel(personalDetailModel: PersonalDetailModel) {
        this.personalDetailModel = personalDetailModel;
    }

}
