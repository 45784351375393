import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PremiumComponent} from './component/premium.component';
import {PremiumService} from './service/premium.service';
import {QuoteSummaryComponent} from './component/quote-summary/quote-summary.component';
import {SharedModule} from '../shared/shared.module';
import {PremiumUrlService} from './service/premium-url.service';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        PremiumComponent,
        QuoteSummaryComponent
    ],
    providers: [
        PremiumService,
        PremiumUrlService
    ]
})
export class PremiumModule { }
