import {Injectable} from '@angular/core';
import {DigitalAnalyticsPageModel} from './model/digital-analytics-page.model';
import {DigitalAnalyticsConversionModel} from './model/digital-analytics-conversion.model';
import {DigitalAnalyticsElementModel} from './model/digital-analytics-element.model';
import {Logger} from '../utilities/logger';
import {Level} from '../utilities/logger-level';
import {MSTIConfiguration} from '../msti.config';
import {BrokerDetailsService} from '../broker-common/broker-details.service';

declare var cmCreatePageviewTag: any;
declare var cmCreateConversionEventTag: any;
declare var cmCreateElementTag: any;
declare var cmCreateRegistrationTag: any;

@Injectable()
export class DigitalAnalyticsService {

    constructor(
        private userService: BrokerDetailsService
    ) { }

    public constructAndFireElementTag(elementId: string, attributes: Map<number, string>) {
      /*  const elementModel: DigitalAnalyticsElementModel = new DigitalAnalyticsElementModel();
        elementModel.elementId = elementId;
        elementModel.elementCategory = DigitalAnalyticsElementModel.CATEGORY_MSTI;
        elementModel.attributeString = this.constructAttribute(attributes, 1);
        Logger.log(Level.LOG, 'Element Model', elementModel);
        this.fireElementTag(elementModel);*/
    }

    public fireElementTag(elementModel: DigitalAnalyticsElementModel) {
   /*     if (MSTIConfiguration.constants.enableAnalytics === true) {
            try {
                cmCreateElementTag(elementModel.elementId, elementModel.elementCategory, elementModel.attributeString);
            } catch (e) {
                Logger.log(Level.ERROR, 'Error firing element tag:', e);
            }
        }*/
    }

    public constructAndFirePageViewTag(pageId: string, attributes: Map<number, string>) {
    /*    const pageViewModel: DigitalAnalyticsPageModel = new DigitalAnalyticsPageModel();
        pageViewModel.pageID = pageId;
        pageViewModel.categoryID = DigitalAnalyticsPageModel.CATEGORY_MSTI;
        pageViewModel.attributeString = this.constructAttribute(attributes, 1);
        Logger.log(Level.LOG, 'Page View Model', pageViewModel);
        this.firePageViewTag(pageViewModel);*/
    }

    public firePageViewTag(pageModel: DigitalAnalyticsPageModel) {
    /*    if (MSTIConfiguration.constants.enableAnalytics === true) {
            try {
                cmCreatePageviewTag(pageModel.pageID, pageModel.categoryID, '', '', pageModel.attributeString);
            } catch (e) {
                Logger.log(Level.ERROR, 'Error firing page view tag:', e);
            }
        }*/
    }

    public constructAndFireConversionEventTag(eventId: string, actionType: string, attributes: Map<number, string>) {
       /* const conversionModel: DigitalAnalyticsConversionModel = new DigitalAnalyticsConversionModel();
        conversionModel.eventID = eventId;
        conversionModel.actionType = actionType;
        conversionModel.eventCategoryID = DigitalAnalyticsConversionModel.CATEGORY_MSTI;
        conversionModel.attributeString = this.constructAttribute(attributes, 8);
        Logger.log(Level.LOG, 'Page Conversion Model', conversionModel);
        this.fireConversionEventTag(conversionModel);*/
    }

    public fireConversionEventTag(conversionModel: DigitalAnalyticsConversionModel) {
    /*    if (MSTIConfiguration.constants.enableAnalytics === true) {
            try {
                cmCreateConversionEventTag(conversionModel.eventID, conversionModel.actionType,
                    conversionModel.eventCategoryID, '', conversionModel.attributeString
                );
            } catch (e) {
                Logger.log(Level.ERROR, 'Error firing conversion tag:', e);
            }
        }*/
    }

    public fireRegistrationEventTag(registrationId: string, email: string) {
     /*   if (MSTIConfiguration.constants.enableAnalytics === true) {
            try {
                cmCreateRegistrationTag(registrationId, email, '', '', '', '', '');
            } catch (e) {
                Logger.log(Level.ERROR, 'Error firing registration tag:', e);
            }
        }*/
    }

    private constructAttribute(attributes: Map<number, string>, maxLimit: number): string {
       let attr = '';

        attr += this.getIdentifier();

        // Note: the max limit is reduced by one because of the identifier added above
        for (let a = 1; a <= (maxLimit - 1); a++) {
            const attributeValue = attributes.get(a);
            if (attributeValue) {
                attr += ('-' + attributeValue + '-');
            } else {
                attr += '-_-';
            }
        }

        return attr;
    }

    private getIdentifier() {
        return '-' + this.userService.getUser().partyId + '-';
    }

}
