import {RegisteredIdTypeModel} from '../registered-id-type.model';

export class CifPartyInfoModel {
  public titleCode: string;
  public firstName: string;
  public lastName: string;
  public genderCode: string;
  public dob: string;
  public personIdentity: RegisteredIdTypeModel = new RegisteredIdTypeModel();
  public maritalStatusCode: string;
  public occupationCode: string;

}
