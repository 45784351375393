import {InactivityComponent} from './inactivity.component';
import {InactivityService} from './inactivity.service';
import {NgModule} from '@angular/core';

@NgModule({
    declarations: [InactivityComponent],
    imports: [

    ],
    providers: [InactivityService],
    exports: [InactivityComponent]
})
export class InactivityModule {
}
