export class DigitalAnalyticsPageModel {

    public static CATEGORY_MSTI = 'MSTI';

    public static MSTI_MARKETING_PAGE_ID = 'MSTI: P1. Marketing Page';
    public static MSTI_PERSONAL_INFO_PAGE_ID = 'MSTI: P2. Personal Information Page';
    public static MSTI_SUBURB_NOT_FOUND_PAGE_ID = 'MSTI: P3. Suburb not found';
    public static MSTI_ITC_CHECK_PAGE_ID = 'MSTI: P4. ITC check page';
    public static MSTI_CURRENTLY_INSURED_PAGE_ID = 'MSTI: P5. Currently Insured';
    public static MSTI_INSURED_WITH_PAGE_ID = 'MSTI: P6. Company Insured With';
    public static MSTI_PERIOD_WITHOUT_COVER_PAGE_ID = 'MSTI: P7. Period Without Cover';
    public static MSTI_YEARS_OF_UNINTERRUPTED_COVER_PAGE_ID = 'MSTI: P8. Years of Uninterrupted Cover';
    public static MSTI_PREVIOUS_CLAIMS_PAGE_ID = 'MSTI: P9. Have you claimed?';
    public static MSTI_PREVIOUS_CLAIM_DETAILS_PAGE_ID = 'MSTI: P10. Capture Claims Details';
    public static MSTI_INSURANCE_CANCELLATION_REFUSAL_PAGE_ID = 'MSTI: P11. Insurance Cancellation / Refusal';
    public static MSTI_CONFIRMATION_PAGE_ID = 'MSTI: P12. Confirmation Page';
    public static MSTI_COVER_DETAILS_PAGE_ID = 'MSTI: P13. Cover Details Page';
    public static MSTI_VEHICLE_DESCRIPTION_PAGE_ID = 'MSTI: P14. Vehicle Description ';
    public static MSTI_VEHICLE_DETAILS_PAGE_ID = 'MSTI: P15. Vehicle Details';
    public static MSTI_DRIVER_DETAILS_PAGE_ID = 'MSTI: P16. Driver Details';
    public static MSTI_PREMIUM_ERROR_PAGE_ID = 'MSTI: P17. Cannot Calculate Premium'; // Note: this isn't currently being used
    public static MSTI_PREMIUM_PAGE_ID = 'MSTI: P18. Premium page';
    public static MSTI_THANK_YOU_PAGE_ID = 'MSTI: P19. Thank you page';
    public static MSTI_HELP_ACKNOWLEDGEMENT_PAGE_ID = 'MSTI: P20. Do you need help: Acknowledgement page';

    public pageID: string;
    public categoryID: string;
    public attributeString: string;

}
