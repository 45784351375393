import {Component, Injector} from '@angular/core';
import {VehicleConfig} from '../vehicle.config';
import {VehicleDescriptionModel} from '../../vehicle/model/vehicle-description.model';
import {VehicleDetailsModel} from '../../vehicle/model/vehicle-details.model';
import {VehicleDriverDetailsModel} from '../../vehicle/model/vehicle-driver-details.model';
import {VehicleService} from '../service/vehicle.service';
import {BaseRiskComponent} from '../../base/component/base-risk.component';
import {BaseRiskService} from '../../base/service/base-risk.service';
import {RiskOptionService} from '../../../../shared/risk/risk-option.service';

@Component({
    selector: 'app-vehicle',
    templateUrl: './vehicle.component.html',
    styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent extends BaseRiskComponent {

    constructor(
        injector: Injector,
        public riskOptionService: RiskOptionService,
        public vehicleService: VehicleService
    ) {
        super(injector);
    }

    getRiskService(): BaseRiskService {
        return this.vehicleService;
    }

    getRiskConfig() {
        return VehicleConfig;
    }

    getComponentModelConfig(cardId: string) {
        return {
            'configTab1': this.getVehicleDescriptionModel(cardId),
            'configTab2': this.getVehicleDetailsModel(cardId),
            'configTab3': this.getVehicleDriverDetailsModel(cardId)
        };
    }

    private getVehicleDescriptionModel(cardId: string): VehicleDescriptionModel {
        return this.vehicleService.getVehicleDescriptionModel(cardId);
    }

    private getVehicleDetailsModel(cardId: string): VehicleDetailsModel {
        return this.vehicleService.getVehicleDetailsModel(cardId);
    }

    private getVehicleDriverDetailsModel(cardId: string): VehicleDriverDetailsModel {
        return this.vehicleService.getVehicleDriverDetailsModel(cardId);
    }

}
